import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, clone, set, get } from "lodash";
import clsx from "clsx";
import { useClassBasedOnRoute } from "../hooks";
import Content from "./common/Content";
import { setUserSettings } from "../utils";
import { setLoader, setSetting } from "../store/actions";

function getDeckBaseOnIChingText(ichingText) {
  if (ichingText === "Standard") {
    return ["FIC", "IChingCards"]
  } else if (ichingText === "RichardWilhelm") {
    return ["IChingCards", "FIC"]
  } else if (ichingText === "Zhouyi") {
    return ["Zhouyi", "IChingCards"]
  }
}

export default (props) => {
  const dispatch = useDispatch();
  const [type, setType] = useState("TEXT");
  // const [setting, setSetting] = useState({});
  const [auth, app] = useSelector((state) => [
    state.authReducer,
    state.appReducer,
  ]);

  const setting = get(app, "settings[0]", {});

  useClassBasedOnRoute(["SettingPage"]);
  const settingChanges = async (e) => {
    if (auth && auth.user && !app.isLoader) {
      dispatch(setLoader(true));
      const cloneSetting = clone(setting);
      set(cloneSetting, e.target.name, e.target.value);

      // change text toggle reset when update settings
      set(cloneSetting, "ChangeTextToggle", false);

      if (e.target.name === "PreferredText") {
        const [PrimaryDeck, SecondaryDeck] = getDeckBaseOnIChingText(e.target.value);
        set(cloneSetting, "PrimaryDeck", PrimaryDeck);
        set(cloneSetting, "SecondaryDeck", SecondaryDeck);
      }

      await setUserSettings(auth.user.uid, cloneSetting);
      dispatch(setSetting([cloneSetting]))
      dispatch(setLoader(false));
    }
  };

  const isChecked = (obj, name, value) => {
    return get(obj, name) === value;
  };

  return (
    <>
      <section id="banner">
        <div className="inner">
          <div className="Banner_Box">
            <div className="SettingTable">
              <div className="SetingTab">
                <button
                  className={clsx("tablinksLeft", { active: type === "TEXT" })}
                  onClick={() => setType("TEXT")}
                >
                  <span className="tabTitle">I Ching Text.</span>
                  <span className="tabtext">
                    Select your translation and commentary.{" "}
                  </span>
                </button>
                <button
                  className={clsx("tablinksRight", {
                    active: type === "METHOD",
                  })}
                  onClick={() => setType("METHOD")}
                >
                  <span className="tabTitle">I Ching Method.</span>
                  <span className="tabtext">
                    Select your consulting method.{" "}
                  </span>
                </button>
              </div>
              <div className="SetingContent">
                {type === "TEXT" && (
                  <div className="SetingContentFull">
                    <label
                      className={clsx("tabfull", {
                        tabfullSelected: isChecked(
                          setting,
                          "PreferredText",
                          "Standard"
                        ),
                      })}
                      forhtml="PreferredText-1"
                    >
                      <span className="tabtextleft">
                        FreeIChing. Simple Standard
                      </span>
                      <span className="tabcheckright">
                        <input
                          type="radio"
                          id="PreferredText-1"
                          name="PreferredText"
                          onChange={settingChanges}
                          value="Standard"
                          checked={isChecked(
                            setting,
                            "PreferredText",
                            "Standard"
                          )}
                        />
                        <span className="checkmark"></span>
                      </span>
                    </label>
                    <div className="Setting-BD-text">
                      A short and straightforward commentary on the I Ching.
                      Great for anyone, but especially if you are just starting
                      with the I Ching.
                      <br></br>
                      <i>
                        Note: secondary text Richard Wilhelm. Accessible by
                        Alt text’ in the menu.
                      </i>
                    </div>
                    <div className="SetingContentFull">
                      <label
                        className={clsx("tabfull", {
                          tabfullSelected: isChecked(
                            setting,
                            "PreferredText",
                            "RichardWilhelm"
                          ),
                        })}
                        forhtml="PreferredText-2"
                      >
                        <span className="tabtextleft">
                          Richard Wilhelm. Popular Traditional
                        </span>
                        <span className="tabcheckright">
                          <input
                            type="radio"
                            id="PreferredText-2"
                            name="PreferredText"
                            onChange={settingChanges}
                            value="RichardWilhelm"
                            checked={isChecked(
                              setting,
                              "PreferredText",
                              "RichardWilhelm"
                            )}
                          />
                          <span className="checkmark"></span>
                        </span>
                      </label>
                      <div className="Setting-BD-text">
                        The most popular I Ching translation and commentary in
                        the world. Richard Wilhelm wrote this commentary based
                        on the input from Lao Naixian around 1922. Close to the
                        traditional Chinese interpretation of the I Ching.
                        <br></br>
                        <i>
                          Note: secondary text FreeIChing. Accessible by Alt
                          text’ in the menu.
                        </i>
                      </div>
                    </div>
                    {/* <div className="SetingContentFull">
                  <label className={clsx("tabfull", { "tabfullSelected": isChecked(setting, "PreferredText", "Lise") })} forhtml="PreferredText-3">
                    <span className="tabtextleft">Lise. Shamanistic Deep</span>
                    <span className="tabcheckright">
                      <input type="radio" id="PreferredText-3" name="PreferredText" onChange={settingChanges} value="Lise" checked={isChecked(setting, "PreferredText", "Lise")} />
                      <span className="checkmark"></span>
                    </span>
                  </label>
                  <div className="Setting-BD-text">Lise’s translation and commentary on the original I Ching is based on old shamanistic interpretation and chinese etyomology. If you want to go deep. Dive with Lise.
                    <br></br><i>Note: secondary text: Richard Wilhelm. Accessible by ‘Change text’ in the menu.</i>
                  </div>
                </div> */}
                    <div className="SetingContentFull">
                      <label
                        className={clsx("tabfull", {
                          tabfullSelected: isChecked(
                            setting,
                            "PreferredText",
                            "Zhouyi"
                          ),
                        })}
                        forhtml="PreferredText-4"
                      >
                        <span className="tabtextleft">
                          Zhouyi. Original Text
                        </span>
                        <span className="tabcheckright">
                          <input
                            type="radio"
                            id="PreferredText-4"
                            name="PreferredText"
                            onChange={settingChanges}
                            value="Zhouyi"
                            checked={isChecked(
                              setting,
                              "PreferredText",
                              "Zhouyi"
                            )}
                          />
                          <span className="checkmark"></span>
                        </span>
                      </label>
                      <div className="Setting-BD-text">
                        Do not get distracted by commentary. See only the
                        orginal text from the core of the I Ching, the Zhouyi.
                        Uses the translation of Richard Wilhelm.
                        <br></br>
                        <i>
                          Note: secondary text: Richard Wilhelm. Accessible by
                          Alt text’ in the menu.
                        </i>
                      </div>
                    </div>
                  </div>
                )}
                {type === "METHOD" && (
                  <div className="SetingContentFullMethods">
                    <div className="SetingContentFull">
                      <label
                        className={clsx("tabfull", {
                          tabfullSelected: isChecked(
                            setting,
                            "PreferredMethod",
                            "Traditional"
                          ),
                        })}
                        forhtml="PreferredMethod-1"
                      >
                        <span className="tabtextleft">
                          OneLine Method. Simple
                        </span>
                        <span className="tabcheckright">
                          <input
                            type="radio"
                            id="PreferredMethod-1"
                            name="PreferredMethod"
                            onChange={settingChanges}
                            value="Traditional"
                            checked={isChecked(
                              setting,
                              "PreferredMethod",
                              "Traditional"
                            )}
                          />
                          <span className="checkmark"></span>
                        </span>
                      </label>
                      <div className="Setting-BD-text">
                        Great for if you are new to the I Ching. More scholars
                        see evidence that the I Ching was consulted in the past
                        through methods that only created one moving line. This
                        method creates precisely one moving line.
                        <br></br>
                        <i></i>
                      </div>
                    </div>
                    <div className="SetingContentFull">
                      <label
                        className={clsx("tabfull", {
                          tabfullSelected: isChecked(
                            setting,
                            "PreferredMethod",
                            "CoinStack"
                          ),
                        })}
                        forhtml="PreferredMethod-2"
                      >
                        <span className="tabtextleft">
                          Coin Method. Popular
                        </span>
                        <span className="tabcheckright">
                          <input
                            type="radio"
                            id="PreferredMethod-2"
                            name="PreferredMethod"
                            onChange={settingChanges}
                            value="CoinStack"
                            checked={isChecked(
                              setting,
                              "PreferredMethod",
                              "CoinStack"
                            )}
                          />
                          <span className="checkmark"></span>
                        </span>
                      </label>
                      <div className="Setting-BD-text">
                        The most popular way of consulting the I Ching. Using 3
                        coins to generate the hexagrams and moving lines. The
                        odds in this method create even chance on moving Yang or
                        Yin lines.
                        <br></br>
                        <i></i>
                      </div>
                    </div>
                    <div className="SetingContentFull">
                      <label
                        className={clsx("tabfull", {
                          tabfullSelected: isChecked(
                            setting,
                            "PreferredMethod",
                            "YarrowStack"
                          ),
                        })}
                        forhtml="PreferredMethod-3"
                      >
                        <span className="tabtextleft">
                          Yarrow Stalk Method. Traditional
                        </span>
                        <span className="tabcheckright">
                          <input
                            type="radio"
                            id="PreferredMethod-3"
                            name="PreferredMethod"
                            onChange={settingChanges}
                            value="YarrowStack"
                            checked={isChecked(
                              setting,
                              "PreferredMethod",
                              "YarrowStack"
                            )}
                          />
                          <span className="checkmark"></span>
                        </span>
                      </label>
                      <div className="Setting-BD-text">
                        This method uses the oldest known method of consulting
                        the I Ching. Great for in depth insight and questions.
                        The odds in this method creates more chance on a moving
                        Yang line compared to a moving Yin line.
                        <br></br>
                        <i></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Content />
    </>
  );
};
