import React from "react";
import Content from "../common/Content";

export default () => {
  return (
    <>
      <section id="banner" className="LegalNoticesSection">
        <div className="OnRichMain">
          <h3 className="HowTotitle">What is the I Ching?</h3>
        </div>
      </section>
      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortAboveTitle">
          What is the I Ching
          </div>
          <div className="ShortPhera">
          The I Ching is an oracle: it answers questions. It is the oldest Oracle 
          that is still in use today. The I Ching is about 3.000 years old and originated
          in China, but is now regularly consulted by people all around the world. 
          The I Ching has been able to stand the test of time which comes to show its real value.
          </div>
          <div className="ShortAboveTitle">
          What can the I Ching do for me?
          </div>
          <div className="ShortPhera">
          See the I Ching as a tool. A tool that helps you connect to your unconscious, intuition,
          or higher self. Ultimately to make wiser decisions and understand yourself and the world
          better. The tool, like any other tool, is something you need to learn to use. But not
          to worry, we created this site so that it becomes easy to build up your knowledge 
          along the way.
          </div>
          <div className="ShortAboveTitle">
          Why FreeIChing.com?
          </div>
          <div className="ShortPhera">
          We try to keep it practical. Make sure that you can apply ancient wisdom directly in your
          life. You can keep track of your readings in a journal or share your readings with friends.
          Last but not least, we care about your privacy and don't spam or bother you with advertisements. 
          Join us, it’s 100% free.
          </div>
        </div>
      </section>
      <Content />
    </>
  );
};
