import React from "react";
import Content from "../common/Content";

export default (props) => {
  return (
    <>
      <section id="" className="ShortSection">
        <div className="ShortMain">
          <h3 className="ShortBanner">The anatomy of a Hexagram</h3>
        </div>
      </section>
      <section id="" className="RealExampleBottom">
        <div className="ShortInner">
          <div className="ShortPhera">
            To understand a Hexagram's anatomy, let's go through a set of
            different I Ching building blocks. Let’s explore the three most important building blocks:
          </div>
         
          <div className="ShortPhera">
          <ul>
            <li>Yin and Yang lines</li>
            <li>The six positions</li>
            <li>The Trigrams</li>
          </ul>
          </div>
          <div className="ShortAboveTitle">
            ''Yin'' and ''Yang'' lines
          </div>
          <div className="ShortBelowPhera">
            A hexagram consists of 6 lines, the lines either have the quality of ''Yin, 
            when the line is broken'' or ''Yang, when the line is solid''. To get a better 
            understanding of the meaning of the concepts of ''Yin'' or ''Yang'', let's look at 
            what the I Ching has to say about the hexagrams that only have ''Yang'' lines 
            and ''Yin'' lines.{" "}
          </div>
          {/* <div className="ShortPhera">
          To get a better understanding of the meaning of the concepts of "Yin" or "Yang," 
          let's take a look at what the I Ching have to say about the hexagrams that only have 
          "Yang" lines and "Yin" lines.{" "}
          </div>
          <div className="ShortAboveTitle">The Creative</div> */}
          <div className="ShortPhera">
            The first Hexagram ''The Creative''' (or ''Heaven'') is purely made
            up out of ''Yang'' lines. The original I Ching commentary says about
            this hexagram:
          </div>
          <i className="ShortPhera">
            ''Great indeed is the sublimity of the Creative, to which all things
            owe their beginning and which permeates all heaven. (..). The way of
            the Creative works through change and transformation, so that each
            thing receives its true nature and destiny and comes into permanent
            accord with the Great Harmony.''
          </i>
          {/* <div className="ShortAboveTitle">The Receptive</div> */}
          <div className="ShortPhera">
            The second Hexagram ''The Receptive'' (or ''Earth'') is purely made
            up out of ''Yin'' lines. The commentary of the I Ching says about
            ''The Receptive'':
          </div>
          <i className="ShortPhera">
            ''..All beings owe their birth to it, because it receives the
            heavenly with devotion. The Receptive in its riches carries all
            things. Its nature is in harmony with the boundless. it embraces
            everything in its breadth and illumines everything in its
            greatness.''
          </i>
          {/* <div className="ShortAboveTitle">The interplay of Yang and Yin</div> */}
          <div className="ShortPhera">
            As we can see, the first Hexagram (with all Yang line) presents the
            initial source of creation. While the second Hexagram (with all Yin
            lines) shows the receptiveness to that initial source.{" "}
          </div>
          <div className="ShortPhera">
            How both ''Yang'' and ''Yin'' interplay is in more detailed
            described in the I Ching commentary:
          </div>
          <i className="ShortPhera">
            ''The way of the Creative brings about the male. The way of the
            Receptive brings about the female. The Creative knows the great
            beginnings. The Receptive completes the finished things. The
            Creative knows through the easy. The Receptive can do things through
            the simple. (..) By means of the easy and the simple we grasp the
            laws of the whole world. When the laws of the whole world are
            grasped, therein lies perfection.''
          </i>
          <div className="ShortPhera">
            In other words perfection can only be accomplished with the right
            interplay of both the Creative and the Receptive.
          </div>
          <div className="ShortAboveTitle">The six positions</div>
          <div className="ShortBelowPhera">
            A hexagram has six line positions, each position has a different
            meaning attributed to it. The most essential of the six line
            positions is the concept of progression and hierarchy. For instance , the first
            line (bottom line) signifies ''beginnings'' and the sixth line (top
            line) signifies ''endings''.{" "}
          </div>
          <div className="ShortBoxBg">
            <div className="ShortHexaLeft">
              <div className="ShortHexaText">24</div>
              <div className="ShortHexaImg">
                <img
                  alt=""
                  className="realexmImg"
                  src={`../../images/Hexa24.png`}
                />
              </div>
            </div>
            <div className="ShortHexaRight">
              <div className="ShortHexaText">23</div>
              <div className="ShortHexaImg">
                <img
                  alt=""
                  className="realexmImg"
                  src={`../../images/Hexa23.png`}
                />
              </div>
            </div>
          </div>
          <div className="ShortPhera">
            This is the reason that the hexagram 24 ''The Return'' with only one
            Yang line on the first line position signifies a new beginning.
            Compare this to hexagram 23 ''Splitting Apart''. With only the top
            line being a ''Yang'' line. This hexagram signifies an end stage.
          </div>
          <div className="ShortPhera">
            Progression in time is one attribute, but many ways of
            progressions can be found in the six line positions. For instance,
            Hexagram ''52'' describes in each line position a part of the body.
            The first line talks about "toes," the second line about "calves," 
            the third line about "hips," the fourth line "trunk," and the fifth line "jaws."
          </div>
          {/* <div className="ShortPheraLine">
            The first line talks about ''toes'', the second line about
            ''calves'', the thirth line about ''hips'', the fourth line
            ''trunk'' and the fifth line ''jaws''.{" "}
          </div> */}
          <div className="ShortAboveTitle">
          The Trigrams
          </div>
          <div className="ShortBelowPhera">
            Another important building block of a Hexagram are the Eight
            Trigrams. The Eight Trigrams are described by the I Ching:
          </div>
          <i className="ShortPhera">
            ''Heaven is strong. Earth is yielding. Thunder means movement. The
            Wind is penetrating. Water is dangerous. Fire means dependence. The
            Mountain means standstill. The Lake means pleasure''. And ''God
            comes forth in the sign of the Thunder. He brings all things to
            completion in the sign of the Wind. He causes creatures to perceive
            one another in the sign of Fire. He causes them to serve one another
            in the sign of Earth. He gives them joy in the sing of the Lake. He
            battles in the sign of the Heaven. He works hard in the sign of
            Water; he brings them to perfection in the sign of the Mountain.''
          </i>
          <div className="ShortPhera">
            Each Hexagram is buildup of a ''lower trigram'' and a ''upper
            trigram''.{" "}
          </div>
          <div className="ShortPheraLine">
            It is the combination of those two trigrams that give meaning to the hexagram. {" "}
          </div>
          <div className="TrigramSection">
            <div className="TrigramMain">
              <div className="TrigramLeft">
                <div className="TrigramLeftImg">
                  {" "}
                  <img
                    alt=""
                    className="TrigramImg"
                    src={`../../images/heaven.png`}
                  />
                </div>
                <div className="TrigramLeftText">Heaven </div>
              </div>
              <div className="TrigramRight">
                <div className="TrigramLeftImg">
                  {" "}
                  <img
                    alt=""
                    className="TrigramImg"
                    src={`../../images/wind.png`}
                  />
                </div>
                <div className="TrigramLeftText"> Wind</div>
              </div>
            </div>
            <div className="TrigramMain">
              <div className="TrigramLeft">
                <div className="TrigramLeftImg">
                  {" "}
                  <img
                    alt=""
                    className="TrigramImg"
                    src={`../../images/lake.png`}
                  />
                </div>
                <div className="TrigramLeftText">Lake </div>
              </div>
              <div className="TrigramRight">
                <div className="TrigramLeftImg">
                  {" "}
                  <img
                    alt=""
                    className="TrigramImg"
                    src={`../../images/water.png`}
                  />
                </div>
                <div className="TrigramLeftText"> Water</div>
              </div>
            </div>
            <div className="TrigramMain">
              <div className="TrigramLeft">
                <div className="TrigramLeftImg">
                  {" "}
                  <img
                    alt=""
                    className="TrigramImg"
                    src={`../../images/fire.png`}
                  />
                </div>
                <div className="TrigramLeftText">Fire </div>
              </div>
              <div className="TrigramRight">
                <div className="TrigramLeftImg">
                  {" "}
                  <img
                    alt=""
                    className="TrigramImg"
                    src={`../../images/mountain.png`}
                  />
                </div>
                <div className="TrigramLeftText"> Mountain</div>
              </div>
            </div>
            <div className="TrigramMain">
              <div className="TrigramLeft">
                <div className="TrigramLeftImg">
                  {" "}
                  <img
                    alt=""
                    className="TrigramImg"
                    src={`../../images/thunder.png`}
                  />
                </div>
                <div className="TrigramLeftText">Thunder </div>
              </div>
              <div className="TrigramRight">
                <div className="TrigramLeftImg">
                  {" "}
                  <img
                    alt=""
                    className="TrigramImg"
                    src={`../../images/earth.png`}
                  />
                </div>
                <div className="TrigramLeftText"> Earth</div>
              </div>
            </div>
          </div>
          <div className="ShortAboveTitle"></div>
          <div className="ShortBelowPhera">
            For example, hexagram 3 ''Difficulty in the Beginning'' is consituted of a
            trigram that has an attribute of ''beginnings'' (trigram thunder as
            the lower trigram) and a trigram that has an attribute of
            ''difficulties'' (trigram water as the upper trigram).
          </div>
        </div>
      </section>
      <Content />
    </>
  );
};
