import { merge, get, camelCase } from "lodash";
import { isCookieConsent } from "../../utils";

import {
  QUESTION_OPEN,
  QUESTION_CLOSE,
  APP_SETTING_SET,
  RESET_PASSWORD_TOGGLE,
  LOADER_TOGGLE,
  COOKIE_CONSENT,
  AUTH_SET_USER,
} from "../actions/actionTypes";

const INITIAL_STATE = {
  isModelOpen: false,
  isQuestionFormOpen: false,
  isLoginFormOpen: false,
  isOpenShare: false,
  isOpenMail: false,
  settings: [],
  isLoader: true,
  cookieConsent: isCookieConsent(),
  modal: {},
  defaultSetting: {
    PreferredMethod: "CoinStack",
    PreferredText: "Standard",
    PrimaryDeck: "FIC",
    SecondaryDeck: "IChingCards",
    ChangeTextToggle: false,
  },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case (action.type.match(/MODAL_OPEN$/) || {}).input:
      return {
        ...state, modal: { ...state.modal, [camelCase(`IS_${action.type}`)]: true }
      }
    case (action.type.match(/MODAL_CLOSE$/) || {}).input:
      return {
        ...state, modal: { ...state.modal, [camelCase(`IS_${action.type.slice(0, -12)}_MODAL_OPEN`)]: false }
      }
    case QUESTION_OPEN:
      return {
        ...state,
        isQuestionFormOpen:
          (state.isQuestionFormOpen && false) ||
          (!state.isQuestionFormOpen && true),
      };
    case QUESTION_CLOSE:
      return { ...state, isQuestionFormOpen: false };
    case APP_SETTING_SET:
      return {
        ...state,
        settings: action.payload,
        defaultSetting: merge(state.defaultSetting, get(action.payload, "0")),
      };
    case RESET_PASSWORD_TOGGLE:
      return { ...state, resetPasswordToggle: action.payload };
    case LOADER_TOGGLE:
      return { ...state, isLoader: action.payload };
    case COOKIE_CONSENT:
      return { ...state, cookieConsent: "true" }
    case AUTH_SET_USER:
      return { ...state, isLoginFormOpen: false }
    default:
      return state;
  }
}
