import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Grid, Paper, Box, Button, TextField } from "@material-ui/core";
import requireAuth from "./hoc/requireAuth";
import firebase from "../config/firebase";

const getCommunityBoards = (listener) => {
  firebase
    .firestore()
    .collection("Community_Board")
    .onSnapshot((collections) => {
      let data = [];
      collections.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      listener(data);
    });
};

const getCommunityBoardsComments = (doc, listener) => {
  firebase
    .firestore()
    .collection("Community_Board")
    .doc(doc)
    .collection("Comments")
    .orderBy("CreatedAt", "asc")
    .onSnapshot((collections) => {
      let data = [];
      collections.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      listener(data);
    });
};

const setCommunityBoardsComments = async (doc, comment) => {
  return await firebase
    .firestore()
    .collection("Community_Board")
    .doc(doc)
    .collection("Comments")
    .add(comment);
};

const Main = ({ auth }) => {
  const [communityBoards, setCommunityBoards] = React.useState([]);
  const [comments, setComments] = React.useState({});
  const [comment, setComment] = React.useState("");

  useEffect(() => {
    getCommunityBoards((data) => {
      setCommunityBoards(data);
    });
  }, []);

  const viewComments = (doc) => {
    setComment("");
    getCommunityBoardsComments(doc, (data) => {
      setComments({ [doc]: data });
    });
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const hanldeSendingComment = (doc) => {
    if (!comment) {
      return;
    }

    setCommunityBoardsComments(doc, {
      Comment: comment,
      Uid: auth.uid,
      CreatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
    });
    setComment("");
  };

  return (
    <Grid xs={12} item>
      {communityBoards.map((communityBoard) => (
        <React.Fragment key={`board-${communityBoard.id}`}>
          <Paper elevation={0}>
            <Box m={2} p={1}>
              <Grid xs={12} item>
                Remarks: {communityBoard.Remarks}
              </Grid>
              <Grid xs={12} item>
                Answer: {communityBoard.Answer}
              </Grid>
              {!comments[communityBoard.id] && (
                <Grid xs={12} item>
                  <Button onClick={() => viewComments(communityBoard.id)}>
                    Comments
                  </Button>
                </Grid>
              )}
              {comments[communityBoard.id] && (
                <>
                  <Grid>
                    <h3>Comments</h3>
                  </Grid>
                  {comments[communityBoard.id].map((comment) => (
                    <Grid xs={12} item key={`comment-${comment.id}`}>
                      <Paper elevation={0}>
                        <Grid xs={12} item>
                          Comment: {comment.Comment}
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                  <TextField
                    label="Comment"
                    multiline
                    value={comment}
                    onChange={handleCommentChange}
                  />
                  <Button
                    onClick={() => hanldeSendingComment(communityBoard.id)}
                  >
                    Send
                  </Button>
                </>
              )}
            </Box>
          </Paper>
        </React.Fragment>
      ))}
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.firebaseReducer.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  requireAuth
)(Main);
