import React from "react";
import ReactDOM from "react-dom";

const modalRoot = document.createElement("div");
modalRoot.setAttribute("id", "modal");
document.body.insertBefore(modalRoot, null);

export default function (props) {
  if (props.show) {
    return ReactDOM.createPortal(
      props.children,
      modalRoot
    );
  }
  return null;
}
