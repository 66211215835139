import React, { useState } from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useClassBasedOnRoute } from "../hooks";
import { getDate } from "../utils";
import CustomLink from "./common/CustomLink";
import { Field, reduxForm } from "redux-form";
import { formValidation, Input3, Input4 } from "./fields";
import { deleteUser, updatePassword } from "../services/firebase";
import Content from "./common/Content";
import { getAnonymouseUser, openLogin, setUser } from "../store/actions";
import moment from "moment";
import { AUTH_CLEAR_USER } from "../store/actions/actionTypes";
import { useHistory } from "react-router";

const sixLenVal = formValidation.minLength(6);

export default () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [template, setTemplate] = useState();
  const [success, setSuccess] = useState();

  useClassBasedOnRoute(["profile-ui"]);
  const [auth] = useSelector((state) => [state.authReducer, state.appReducer]);
  const isTemplate = (name) => {
    return name === template;
  };
  const toggleTemplate = (name) => {
    // isUndefined(name) ? localStorage.setItem('myCat', 'Tom');
    if (auth.isLogin) {
      const lastSignDate = get(auth, "user.metadata.lastSignInTime");
      if (moment(lastSignDate) > moment().subtract(2, "minutes")) {
        setTemplate(template === name ? undefined : name);
      } else {
        dispatch(openLogin())
      }
    } else {
      dispatch(openLogin())
    }

  };

  const handleResetPasswordSubmit = async (data) => {
    setTemplate(null);
    await updatePassword(auth.user, data["new-password"]);
    setSuccess("Password reset successfully!");
    setTimeout(() => {
      setSuccess("");
    }, 3000)
  };

  const handleDeleteAccountSubmit = async (data) => {
    setTemplate(null);
    deleteUser(auth.user);
    dispatch({
      type: AUTH_CLEAR_USER,
    });
    history.push("/")
    const newUser = await getAnonymouseUser();
    dispatch(setUser(newUser.user));
  };

  return (
    <>
      <section id="banner">
        <div className="inner">
          <div className="profile-wrapper">
            <div className="info-wrapper personal-info ">
              <div className="head-wrapper">
                <strong>Personal Information</strong>
                <CustomLink onClick={() => toggleTemplate("DELETE")}>
                  Delete
                </CustomLink>
              </div>
              <div className="inner-info">
                <p>{get(auth, "user.displayName")}</p>
                <p>Member since: {getDate(auth, "user.metadata.creationTime")}</p>
                <p>Last login: {getDate(auth, "user.metadata.lastSignInTime")}</p>
              </div>
            </div>
            <div className="info-wrapper login-info ">
              <div className="head-wrapper">
                <strong>Login</strong>
              </div>
              <div className="inner-info">
                <p>{get(auth, "user.email")}</p>
                <p className="change">
                  ****************{" "}
                  <CustomLink onClick={() => toggleTemplate("RESET")}>
                    Change
                  </CustomLink>
                </p>
              </div>
            </div>
            <div className="info-wrapper login-info ">
              <div className="head-wrapper">
                <strong>{success}</strong>
              </div>
            </div>
            {isTemplate("RESET") && (
              <ChangePasswordForm
                handleSubmitFrom={handleResetPasswordSubmit}
                initialValues={{}}
              />
            )}
            {isTemplate("DELETE") && (
              <DeleteAccountForm
                handleSubmitFrom={handleDeleteAccountSubmit}
                initialValues={{}}
              />
            )}
          </div>
        </div>
      </section>
      <Content />
    </>
  );
};

const ChangePasswordUI = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit} noValidate>
      {/* <Field
        name="current-password"
        component={Input3}
        label="Old password"
        autoComplete="current-password"
        validate={formValidation.required}
      /> */}
      <Field
        name="new-password"
        component={Input3}
        label="New password"
        autoComplete="new-password"
        validate={[formValidation.required, sixLenVal]}
      />
      <Field
        name="confirm-password"
        component={Input3}
        label="Confirm password"
        autoComplete="confirm-password"
        validate={[formValidation.required, sixLenVal, formValidation.matchPassword]}
      />
      <div className="info-wrapper text-right login-btn">
        <input type="submit" className="btn-primary Change-Password" value="Change Password" />
      </div>
    </form>
  );
};

const ChangePasswordForm = reduxForm({
  form: "changePassword",
  enableReinitialize: true,
  destroyOnUnmount: true,
  onSubmit: (data, dispatch, props) => {
    props.handleSubmitFrom(data);
  },
})(ChangePasswordUI);


const DeleteAccountUI = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="info-wrapper text-right">
        <div className="info-wrapper login-info ">
          <div className="head-wrapper">
            <span className="profile-account">Delete Account</span>
          </div>
          <div className="delete-form">
            <Field
              name="agree"
              component={Input4}
              type="checkbox"
              label="I am sure I want to delete my account and all my details."
              validate={formValidation.required}
            />
          </div>
        </div>
        <input type="submit" className="btn-primary DeleteBottom" value="Delete" />
      </div>
    </form>
  );
};

const DeleteAccountForm = reduxForm({
  form: "deleteAccount",
  enableReinitialize: true,
  destroyOnUnmount: true,
  onSubmit: (data, dispatch, props) => {
    console.log(data);
    props.handleSubmitFrom(data);
  },
})(DeleteAccountUI);
