import React from "react";
import { Link } from "react-router-dom";

export default () => (
  <footer id="footer">
    <div className="footer-main">
      <div className="Boxft1 m-l-0 box-5">
        <div className="ft-text">
        <Link to="/become-member">Become member?</Link>
          <br />
          <Link to="/moreoniching/onrichardwilhelm">Lao Naixuan</Link>
          <br />
          <Link to="/moreoniching/methodtouse">Oneline or Coins</Link>
        </div>
      </div>
      <div className="Boxft1 m-l-0 box-6">
        <div className="ft-text">
        <Link to="/feedback">Give feedback?</Link>
          <br />
          <Link to="/moreoniching/onrichardwilhelm">Richard Wilhelm</Link>
          <br />
          <Link to="/moreoniching/realexamplereading">Example Readings</Link>
        </div>
      </div>
      <div className="Boxft1 m-l-0 box-7">
        <div className="ft-text">
        <Link to="/whats-iching">What is I Ching?</Link>
          <br />
          <Link to="/moreoniching/scienceontheiching">I Ching and science</Link>
          <br />
          <Link to="/books">Book of Changes</Link>
        </div>
      </div>
      <div className="Boxft1 m-l-0 box-8">
        <div className="ft-text">
        <Link to="/iching-help">Can I Ching help?</Link>
          <br />
          <Link to="/moreoniching/ichingquantumphysics">I Ching and Pauli</Link>
          <br />
          <Link to="/support">Support our app</Link>
        </div>
      </div>
    </div>
    <div className="footer-border">
      <div className="ft-border"> </div>
    </div>

    <div className="footer-links">
      <ul className="footersocial">
        <li>
          <Link to="/contact">
            <img src="/images/facebook.png" alt="Facebook" />
          </Link>
        </li>
        {/* <li>
          <Link to="/">
            <img src="/images/linkedin.png" alt="Linkedin" />
          </Link>
        </li> */}
        <li>
          <Link to="/contact">
            <img src="/images/twitter.png" alt="Twitter" />
          </Link>
        </li>
      </ul>
      <div className="footer-nav">
        <Link className="nav-first" to="/legalnotices">
          Privacy
        </Link>
        <Link to="/legalnotices">Terms</Link>
        <Link to="/legalnotices">Disclaimer</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/feedback">Feedback</Link>
      </div>
    </div>
  </footer>
);
