
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import translationsObject from "../../src/constants/i18n";
import reducers from "./reducers";

const store = createStore(
  reducers,
  applyMiddleware(reduxThunk)
);

syncTranslationWithStore(store)
store.dispatch(loadTranslations(translationsObject));
store.dispatch(setLocale("en"));

export default store;
