import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { get } from "lodash";
import {
  closeQuestion,
  openLogin,
  openQuestion,
  openShareModal,
  openMailModal,
} from "../../store/actions/app";
import CustomLink from "../common/CustomLink";
import QuestionCreate from "./QuestionCreate";
import { signout } from "../../store/actions";
import { goToElement } from "../../utils";
import { saveUserAnswer, getCollection } from "../../utils/firebase";
import { map } from "lodash";
import { changeText, clearQuestion } from "../../store/actions/card";
import { cookieConsent } from "../../store/actions";

const handleMenuToggle = (e) => {
  e.preventDefault();
  document.querySelector("body").classList.toggle("is-menu-visible");
};

const handleCloseMenu = (e) => {
  e.preventDefault();
  document.querySelector("body").classList.remove("is-menu-visible");
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [auth, result, app] = useSelector((state) => [
    state.authReducer,
    state.questionReducer.question,
    state.appReducer,
  ]);
  // const [cookieConsent, setCookieConsent] = useState(isCookieConsent())
  //Save Form - Open when logged in
  const questionFormOpen = (e) => {
    if (auth.isLogin) {
      dispatch(openQuestion());
      goToElement(e, "#page-wrapper");
    } else {
      dispatch(openLogin());
    }
  };

  //My Reading - Open when logged in
  const myReadingsOpen = (e) => {
    if (auth.isLogin) {
      history.replace({
        pathname: "/myreadings",
      });
    } else {
      dispatch(openLogin());
    }
  };

  //My Settings
  const openSettings = (e) => {
    if (auth.isLogin) {
      history.replace({
        pathname: "/settings",
      });
    } else {
      dispatch(openLogin());
    }
  };

  //Hexagram
  const openHexagram = (e) => {
    //if (auth.isLogin) {
    history.replace({
      pathname: "/hexagram",
    });
    // } else {
    //dispatch(openLogin());
    //}
  };

  //More On IChing
  const openMoreOnIChing = (e) => {
    history.replace({
      pathname: "/moreoniching",
    });
  };

  //Save Form Handle to save data
  const questionCreateHandle = (data) => {
    dispatch(closeQuestion());
    const questionSave = {
      Annotation: map(result.data, "Number").join(":"),
      Date: new Date(),
      Deck: getCollection(),
      Method: result.method,
      MovingLines: result.random.movingLines,
      ReadingHexagram: result.random.sixDigit1 || "",
      // QuestionText : data.questionText || result.question,
      ...data,
    };
    saveUserAnswer(questionSave, auth.user.uid);
  };

  //Share Modal
  const handleOpenShareModal = () => {
    if (
      history.location.pathname === "/full-reading" ||
      history.location.pathname === "/reading" ||
      history.location.pathname === "/"
    ) {
      if (auth.isLogin) {
        dispatch(openShareModal());
      } else {
        dispatch(openLogin());
      }
    }
  };

  //Mail Modal
  const handleOpenMailModal = () => {
    if (
      history.location.pathname === "/full-reading" ||
      history.location.pathname === "/reading" ||
      history.location.pathname === "/"
    ) {
      if (auth.isLogin) {
        dispatch(openMailModal());
      } else {
        dispatch(openLogin());
      }
    }
  };

  const handleLogout = () => {
    dispatch(clearQuestion())
    dispatch(signout())
    history.replace({
      pathname: "/",
    });
  }

  const handleLogoClick = () => {
    dispatch(clearQuestion());
    document.body.classList.remove(
      "page-4",
      "white-nav",
      "card-animation",
      "card-animation-2"
    )
  }

  const hanldeAcceptAll = () => {
    dispatch(cookieConsent());
  }

  return (
    <>
      {!app.cookieConsent && <div className="cookie-consent">
        <div className="side-left">
          <p> We use cookies to improve your experience on our websites.</p>
          <a className="text-underline" href="/privacy-policy" target="_blank"  > Privacy Policy </a>
        </div>
        <div className="side-right cookie-btn">
          <CustomLink onClick={hanldeAcceptAll}>Accept All</CustomLink>
          <Link to="cookie-policy" target="_blank">Cookie Policy</Link>
        </div>
      </div>}
      <nav className="top-nav">
        <ul className="nav-white">
          {/* <li>
            <CustomLink to="/myreadings" onClick={myReadingsOpen}>My Readings</CustomLink>
          </li> */}
          <li>
            <CustomLink to="/" onClick={questionFormOpen}>
              Save
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/" onClick={handleOpenShareModal}>
              Share
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/" onClick={handleOpenMailModal}>
              Mail
            </CustomLink>
          </li>
          <li>
            <CustomLink onClick={() => dispatch(changeText())}>
              Alt Text
            </CustomLink>
          </li>
        </ul>
      </nav>
      {app.isQuestionFormOpen && result && (
        <QuestionCreate
          handleSumbit={questionCreateHandle}
          initialValues={{}}
        />
      )}
      <header id="header" className="alt MainLogo">
        <h1>
          <Link to="/" onClick={handleLogoClick}>
            <img src="/images/logo.png" alt="Logo" />
          </Link>
        </h1>
        <nav id="nav">
          <ul>
            {!auth.isLogin && (
              <li className="login-btn-top">
                <CustomLink to="/" onClick={() => dispatch(openLogin())}>
                  <span>Login</span>
                </CustomLink>
              </li>
            )}
            <li className="special">
              <Link
                to="/"
                onClick={handleMenuToggle}
                className="menuToggle"
              ></Link>{" "}
              {/* href="#menu" */}
            </li>
          </ul>
        </nav>
      </header>
      <div id="menu" onClick={handleCloseMenu}>
        <ul>
          <li style={{ padding: "14px 0" }}>
            {!auth.isLogin && (
              <>
                <CustomLink to="/" onClick={() => dispatch(openLogin())}>
                  Login
                </CustomLink>
                <Link className="login-icon-unauth" to="/">
                  <img src="/images/login.png" alt="Login" />
                </Link>
              </>
            )}
            {auth.isLogin && (
              <>
                <Link className="one-line-text user-name-wrap" to="/profile">
                  <span>{get(auth, "user.displayName") || "User"}</span>
                </Link>
                <Link className="login-icon" to="/profile">
                  <img
                    className="circle"
                    src={get(auth, "user.photoURL") || "/images/login.png"}
                    width="32"
                    alt={get("auth", "user.displayName")}
                  />
                </Link>
              </>
            )}
          </li>
          <li>
            <Link to="/" onClick={() => dispatch(clearQuestion())}>
              New question
            </Link>
          </li>
          <li className="border-0">
            <CustomLink to="/myreadings" onClick={myReadingsOpen}>
              My Readings
            </CustomLink>
          </li>
          <li className="border-0">
            <CustomLink to="/settings" onClick={openSettings}>
              Settings
            </CustomLink>
          </li>
          {/* <li className="border-0">
            <Link to="/">Community</Link>
          </li> */}
          <li className="border-0">
            {/* <Link to="/">More on I Ching</Link> */}
            <CustomLink to="/moreoniching" onClick={openMoreOnIChing}>
              More on I Ching
            </CustomLink>
          </li>
          <li className="border-0">
            <CustomLink to="/hexagram" onClick={openHexagram}>
              Hexagram List
            </CustomLink>
          </li>
          {/* <li className="border-0">
            <Link to="/">Shop</Link>
          </li> */}
          {auth.isLogin && (
            <li className="border-0">
              <CustomLink to="/" onClick={handleLogout}>
                Log Out
              </CustomLink>
            </li>
          )}
        </ul>
        <Link to="/" onClick={handleMenuToggle} className="close"></Link>
      </div>
    </>
  );
};
