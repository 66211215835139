import React from "react";
import { Link } from "react-router-dom";
import { get, has, map, omit, isEmpty } from "lodash";
import clsx from "clsx";
import { useClassBasedOnRoute } from "../hooks";
import Content from "./common/Content";
import { useDispatch, useSelector } from "react-redux";
import { getCardByMethod } from "../store/actions/card";
import { saveAnswer } from "../utils/firebase";
import { encode, decode, querParse, contentLimit } from "../utils";

export default function Show(props) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.questionReducer.question);
  const app = useSelector((state) => state.appReducer);
  const [isMoreLine, setIsMoreLine] = React.useState(false);

  useClassBasedOnRoute(["page-4", "white-nav"]);

  React.useEffect(() => {
    dispatch(
      getCardByMethod(decode(querParse(props.location.search, "query")))
    );
  }, []);

  React.useEffect(() => {
    if (data) {
      let bodyClass = document.body.classList;
      bodyClass.add("card-animation");
      if (has(props, "history.location.state.home")) {
        saveAnswer(data);
      }
      return () => {
        bodyClass.remove("card-animation");
      };
    }
  }, [data]);

  const handleMoreLine = (e) => {
    e.preventDefault();
    setIsMoreLine(!isMoreLine);
  };

  const lineCommentary = get(
    data,
    "data[0].Translation.iChingLines[0].lineCommentary",
    ""
  );

  return (
    <>
      <section id="banner">
        <div className="inner">
          <div className="banner-top-question">
            {/* {question && <>
              <span style={{ color: "#374C5E" }}>Question:</span> {question}
            </>} */}
          </div>
          <div className="banner-top-question-line">
            {get(data, "data[0].Translation.title")}
            {has(data, "random.movingLines[0]") && (
              <i>, Line {get(data, "random.movingLines[0]")}</i>
            )}
          </div>
          <div className="Banner_Box">
            <div className="BannerLeftImage">
              <div className="hexa-box-bg">
                <img
                  className="hexa-content"
                  src={`/images/cards/${get(data, "data[0].Number")}.png`}
                  alt=""
                />
                <div className="hexa-chine">
                  <img
                    src={`/images/chinese/whites/${get(
                      data,
                      "data[0].Number"
                    )}.svg`}
                    alt=""
                  />
                </div>
                <div className="hexa-top-title BanneAns">
                  {get(data, "data[0].Translation.title")}
                </div>
              </div>
            </div>
            <div className="BannerQuestionTop">
              {contentLimit(
                get(data, "data[0].Translation.iChingLines[0].lineIChing"),
                180
              )}
            </div>
            {!isEmpty(app.settings) &&
              app.settings[0].PreferredText !== "Zhouyi" && (
                <div className="BannerQuestionBottom">
                  {contentLimit(lineCommentary, 180)}
                </div>
              )}
            <div className="banner-bottom-btn">
              {/**get(data, "data[0].Translation.iChingLines", []).length > 1 */}
              {app.settings[0].PreferredMethod !== "Traditional" && (
                <div className="banner-box4-bottom btn_left left-mobile">
                  <div className="banner-box4-btn">
                    <Link
                      to="/"
                      className="banner-theme-btn"
                      onClick={handleMoreLine}
                    >
                      Next lines
                    </Link>
                    <i
                      className={clsx([
                        "fa",
                        "fa-angle-right",
                        { "arrow-rotate": isMoreLine },
                      ])}
                    ></i>
                  </div>
                </div>
              )}
              <div className="banner-box4-bottom btn_left left-mobile rightButton">
                <div className="banner-box4-btn">
                  <Link
                    to={{
                      pathname: "/full-reading",
                      state: {},
                      search: `?query=${encode(omit(data, ["data"]))}`,
                    }}
                    className="banner-theme-btn"
                  >
                    Full reading
                  </Link>
                  <i className="fa fa-angle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Link to="/" onClick={(e) => goToElement(e, "#info")} className="more scrolly">
          <img src="/images/down.png" alt="" />
        </Link> */}
      </section>
      {isMoreLine &&
        map(
          get(data, "data[0].Translation.iChingLines", []),
          (iChingLine, index) => (
            <section
              key={"iChingLine-" + index}
              className={clsx([
                "wrapper",
                "style1",
                "special",
                "section-6",
                { "section-7": index % 2 === 1 },
              ])}
            >
              <div className="SectionInner">
                <div className="InnerBox">
                  <div className="banner-top-question-line-grey">
                    {get(data, "data[0].Translation.title")}
                    {has(data, "random.movingLines[0]") && (
                      <i>, Line {get(iChingLine, "lineNumber")}</i>
                    )}
                  </div>
                  <div className="Banner_Box">
                    <div className="BannerLeftImage">
                      <div className="hexa-box-bg">
                        <img
                          className="hexa-content"
                          src={`/images/cards/${get(
                            data,
                            "data[0].Number"
                          )}.png`}
                          alt=""
                        />
                        <div className="hexa-chine">
                          <img
                            src={`/images/chinese/whites/${get(
                              data,
                              "data[0].Number"
                            )}.svg`}
                            alt=""
                          />
                        </div>
                        <div className="hexa-top-title BanneAns">
                          {get(data, "data[0].Translation.title")}
                        </div>
                      </div>
                    </div>
                    <div className="BannerQuestionTop">
                      {get(iChingLine, "lineIChing")}
                    </div>
                    <div className="BannerQuestionBottom-grey">
                      {get(iChingLine, "lineCommentaryTarotPro")}
                    </div>
                    <div className="banner-bottom-btn single-btn">
                      <div className="banner-box4-bottom btn_left grey-btn_color">
                        <div className="banner-box4-btn">
                          <Link
                            to={{
                              pathname: "/full-reading",
                              state: {},
                              search: `?query=${encode(omit(data, ["data"]))}`,
                            }}
                            className="banner-theme-btn"
                          >
                            Full reading
                          </Link>
                          <i className="fa fa-angle-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )
        )}
      {/* section-7 */}
      <section id="info" className="wrapper alt section-8 style2">
        <div className="row m-0 p-l-r-50">
          <div className="BoxBg2 m-l-0 box-1">
            <div className="box-content">
              <h4>Is iChing a religion?</h4>
              <p>
                No! I Ching is an ancient Chinese manual of divination based on
                eight symbolic trigrams and sixty-four hexagrams, interpreted in
                terms of the principles of yin and yang.{" "}
              </p>
            </div>
            <div className="box4-bottom">
              <div className="box4-btn">
                <Link to="/" className="theme-btn">
                  Read more
                </Link>
                <i className="fa fa-angle-right"></i>
              </div>
            </div>
          </div>
          <div className="BoxBg2 box-6">
            <div className="box-content">
              <h4>New features.</h4>
              <p>
                Hi all, we always working hard on adding new features. Keep
                updated.{" "}
              </p>
            </div>
            <div className="box4-bottom">
              <div className="box4-btn">
                <Link to="/" className="theme-btn">
                  Read more
                </Link>
                <i className="fa fa-angle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Content />
    </>
  );
}
