import store from "../store";
import firebase from "../config/firebase";
import { map, merge } from "lodash";
import { movingLinesFilter } from "./methodUtils";
const lang = "English";

const getData = (collections) => {
  let data = [];
  collections.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });
  return data;
};

const getCardTransData = async (card) => {
  let trans = await firebase
    .firestore()
    .collection("Decks")
    .doc(getCollection())
    .collection("Cards")
    .doc(card.id)
    .collection("Translations")
    .doc(lang)
    .get();
  card.Translation = trans.data();
  return card;
};

const handleIfDeckIsEmpty = (settings) => {
  if (settings.PreferredText === "Standard") {
    return settings.ChangeTextToggle ? "IChingCards" : "FIC";
  } else if (settings.PreferredText === "RichardWilhelm") {
    return settings.ChangeTextToggle ? "FIC" : "IChingCards";
  } else if (settings.PreferredText === "Zhouyi") {
    return settings.ChangeTextToggle ? "IChingCards" : "Zhouyi";
  }
};

export const getCollection = () => {
  const {
    appReducer: { defaultSetting },
  } = store.getState();
  return (
    (defaultSetting.ChangeTextToggle
      ? defaultSetting.SecondaryDeck
      : defaultSetting.PrimaryDeck) || handleIfDeckIsEmpty(defaultSetting)
  );
};

export const getCard = async (number, store) => {
  console.log(getCollection(), "sssss");
  const cards = await firebase
    .firestore()
    .collection("Decks")
    .doc(getCollection())
    .collection("Cards")
    .where("Number", "==", Number(number))
    .limit(1)
    .get();
  let cardsTransMap = cards.docs.map((doc) => {
    return getCardTransData(merge({ id: doc.id }, doc.data()));
  });
  return await Promise.all(cardsTransMap);
};

export const getTags = async (number, store) => {
  const tags = await firebase.firestore().collection("Tags").get();
  return tags.docs.map((doc) => {
    return doc.data().name;
  });
};

export const getTraditionalData = async (random) => {
  let cards = await getCard(random.cards[0]);
  movingLinesFilter(cards, random.movingLines);
  let card2 = await getCard(
    cards[0].Translation.iChingLines[0].relatedCardNumber
  );
  return [cards[0], card2[0]];
};

export const getCardsByCardNumber = async (cardsNumber) => {
  let data = await Promise.all(map(cardsNumber, (i) => getCard(i)));
  return [data[0][0], data[1][0]];
};

export const saveUserAnswer = async (data, authId) => {
  return await firebase
    .firestore()
    .collection("Users")
    .doc(authId)
    .collection("User_Answers")
    .add(data);
};

export const getUserAnswers = async (authId) => {
  return await getData(
    await firebase
      .firestore()
      .collection("Users")
      .doc(authId)
      .collection("User_Answers")
      .orderBy("Date", "desc")
      .get()
  );
};

export const deleteAnswer = async (authId, docId) => {
  return await firebase
    .firestore()
    .collection("Users")
    .doc(authId)
    .collection("User_Answers")
    .doc(docId)
    .delete();
};

export const saveAnswer = async (data) => {
  const obj = {
    Annotation: map(data.data, "Number").join(":"),
    Date: new Date(),
    Deck: getCollection(),
    Method: data.method,
    MovingLines: data.random.movingLines,
    ReadingHexagram: data.random.sixDigit1,
  };
  if (obj.ReadingHexagram === undefined) {
    delete obj.ReadingHexagram;
  }
  return await firebase.firestore().collection("All_Answers").add(obj);
};

export const getUserSettings = async (authId) => {
  await firebase.firestore().collection("Users").doc(authId).delete();
  return getData(
    await firebase
      .firestore()
      .collection("Users")
      .doc(authId)
      .collection("User_Setting")
      .get()
  );
};

export const setUserSettings = async (authId, { id, ...setting }) => {
  if (id) {
    await firebase
      .firestore()
      .collection("Users")
      .doc(authId)
      .collection("User_Setting")
      .doc(id)
      .set(setting);
  } else {
    await firebase
      .firestore()
      .collection("Users")
      .doc(authId)
      .collection("User_Setting")
      .add(setting);
  }
};

export const shareReading = async (obj) => {
  obj.createdAt = firebase.firestore.Timestamp.fromDate(new Date());
  return await firebase.firestore().collection("share_reading").add(obj);
};

export const mail = (obj) => {
  obj.createdAt = firebase.firestore.Timestamp.fromDate(new Date());
  return firebase.firestore().collection("mails").add(obj);
};

export function checkActionCode(code) {
  return firebase.auth().checkActionCode(code)
}
export function emailVerify(code) {
  return firebase.auth().applyActionCode(code)
}

export function verifyPasswordCode(code) {
  return firebase.auth().verifyPasswordResetCode(code)
}

export function confirmPasswordReset(code, newPassword) {
  return firebase.auth().confirmPasswordReset(code, newPassword)
}