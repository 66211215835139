import { AUTH_SERVER_ERROR, AUTH_SERVER_SUCCESS } from "./actionTypes";
import firebase from "../../config/firebase";
import { getLocaleData, removeLocalData, setLocalData } from "../../utils";
import { includes } from "lodash";
import { clearUser, setLoader, setUser } from ".";

const CREDENTIAL = "CREDENTIAL";
const DIFFERENT_CREDENTIAL = "auth/account-exists-with-different-credential";
const EMAIL_USED = "auth/email-already-in-use";

export function getUserResolve() {
  return async (dispatch) => {
    try {
      let detail;
      const [social, auth] = await Promise.all([
        getRedirectResult(),
        getUser()
      ]);
      if (social.user) {
        detail = social;
      } else if (auth.user) {
        detail = auth
      } else {
        detail = await getAnonymouseUser();
      }
      dispatch(setUser(detail.user));
    } catch (error) {
      console.log(error)
      dispatch(setLoader(false));
    }
  }
}

export function getUser() {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      resolve({ user });
    });
  });
}

async function accountLinkingIfCredExist(data) {
  if (getLocaleData(CREDENTIAL) && data.user) {
    try {
      const prevCred = getLocaleData(CREDENTIAL, true);
      const token = firebase.auth.AuthCredential.fromJSON(prevCred);
      let credential;
      if (prevCred.providerId === "facebook.com") {
        credential = firebase.auth.FacebookAuthProvider.credential(token);
      } else if (prevCred.providerId === "google.com") {
        credential = firebase.auth.GoogleAuthProvider.credential(token);
      } else {
        console.log("Invalid provider");
      }
      await data.user.linkWithCredential(credential);
      removeLocalData(CREDENTIAL)
    } catch (err) {
      console.log("Invalid credential", err)
      removeLocalData(CREDENTIAL)
    }
  }
}

async function emailVerify(data) {
  if (data.user && !data.user.emailVerified && data.additionalUserInfo.providerId === "facebook.com") {
    await data.user.updateProfile({ emailVerified: true })
  }
}

async function handleCredForLinking(error) {
  setLocalData(CREDENTIAL, error.credential)
  const providers = await firebase.auth().fetchSignInMethodsForEmail(error.email);
  let provider;
  if (includes(providers, "google.com")) {
    provider = new firebase.auth.GoogleAuthProvider();
  } else if (includes(provider, "facebook.com")) {
    provider = new firebase.auth.FacebookAuthProvider()
  } else {
    console.log("Invalid provider")
  }
  if (provider) {
    await firebase.auth().signInWithRedirect(provider);
  }
}

export async function getRedirectResult() {
  try {
    const data = await firebase.auth().getRedirectResult();
    await Promise.all([
      accountLinkingIfCredExist(data),
      emailVerify(data)
    ]);
    return data;
  } catch (error) {
    let errorCode = error.code;
    if (errorCode === DIFFERENT_CREDENTIAL || errorCode === EMAIL_USED) {
      await handleCredForLinking(error)
    } else {
      console.error(error);
    }
  }
}

export async function getAnonymouseUser() {
  return await firebase.auth().signInAnonymously();
}

const notification = (type, payload) => dispatch => {
  dispatch({ type, payload });
  setTimeout(() => {
    dispatch({ type });
  }, 4000);
};

// Signing up with Firebase
export const signup =
  ({ email, password, firstname, lastname }, cb) =>
    async (dispatch) => {
      try {
        const data = await firebase.auth().createUserWithEmailAndPassword(email, password);
        await Promise.all([
          data.user.updateProfile({
            displayName: `${firstname} ${lastname}`,
          }),
          data.user.sendEmailVerification(),
        ]);
        await firebase.auth().signOut();

        let msg = "We have sent an email with a confirmation email. Please verify your email.";
        dispatch(notification(AUTH_SERVER_SUCCESS, msg));
        // const detail = await getUser();

        // if (detail) {
        //   // dispatch(setUser(detail.user));
        //   // dispatch(closeLogin());
        // } else {
        //   // Signup failed
        //   let msg = "Something went wrong, we couldn't create your account. Please try again.";
        //   dispatch(notification(AUTH_SERVER_ERROR, msg));
        // }
        if (cb) cb(null);
      } catch (err) {
        // dispatch(apiCallError());
        console.log(err);
        if (err.code === EMAIL_USED) {
          let msg = "We couldn't create your account because this email address is already in use.";
          dispatch(notification(AUTH_SERVER_ERROR, msg));
        } else {
          // let msg = "Something went wrong, we couldn't create your account. Please try again.";
          dispatch(notification(AUTH_SERVER_ERROR, err.message));
        }
        if (cb) cb(true);
      }
    };

// Signing in with Firebase
export const signin = (email, password, cb) => async (dispatch) => {
  try {
    let auth = await firebase.auth().signInWithEmailAndPassword(email, password);
    console.log(auth);
    if (auth.user.emailVerified) {
      console.log("auth.user", auth.user);
      await dispatch(setUser(auth.user));
    } else {
      dispatch(notification(AUTH_SERVER_ERROR, "Please verify your email."));
      await firebase.auth().signOut();
    }
    if (cb) cb();
  } catch (err) {
    console.log(err);
    let msg = "Invalid login credentials";
    dispatch(notification(AUTH_SERVER_ERROR, msg));
    if (cb) cb();
  }
};

// Signing out with Firebase
export const signout = () => async (dispatch) => {
  try {
    // dispatch(beginApiCall());
    await firebase.auth().signOut();
    dispatch(clearUser());
    const newUser = await getAnonymouseUser();
    dispatch(setUser(newUser.user));
  } catch (err) {
    // "Error, we were not able to log you out. Please try again."
    // dispatch(apiCallError());
    let msg = "Error, we were not able to log you out. Please try again.";
    dispatch(notification(AUTH_SERVER_ERROR, msg));
  }
};

// Reset password with Firebase
export const resetPassword = (email, cb) => async (dispatch) => {
  try {
    // dispatch(beginApiCall());
    await firebase.auth().sendPasswordResetEmail(email);
    let msg = "Check your inbox. We've sent you a secured reset link by e-mail."
    dispatch(notification(AUTH_SERVER_ERROR, msg));
    if (cb) cb();
  } catch (err) {
    let msg = err.code === "auth/user-not-found"
      ? "We do not recognize this email account. Please try again."
      : "Oops, something went wrong we couldn't send you the e-mail. Try again and if the error persists, contact admin."
    // dispatch(apiCallError());
    dispatch(notification(AUTH_SERVER_ERROR, msg));
    if (cb) cb();
  }
};

export const signInWithGoogle = () => async (dispatch) => {
  const provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithRedirect(provider);
};

export const signInWithFacebook = () => async (dispatch) => {
  const provider = new firebase.auth.FacebookAuthProvider();
  firebase.auth().signInWithRedirect(provider);
};
