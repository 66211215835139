import { getUserSettings, setCookieConsent } from "../../utils";
import {
  QUESTION_OPEN,
  QUESTION_CLOSE,
  MY_READING_OPEN,
  MY_READING_CLOSE,
  RESET_PASSWORD_TOGGLE,
  LOADER_TOGGLE,
  COOKIE_CONSENT,
  AUTH_SET_USER,
  AUTH_CLEAR_USER,
  APP_SETTING_SET,
  MAIL_MODAL,
  SHARE_MODAL,
  LOGIN_MODAL,
} from "./actionTypes";


export function genericModal(type) {
  return { type };
}

export function openLogin() {
  return genericModal(LOGIN_MODAL.OPEN)
}

export function closeLogin() {
  return genericModal(LOGIN_MODAL.CLOSE)
}

export function setUser(user) {
  return async (dispatch) => {
    dispatch({
      type: AUTH_SET_USER,
      payload: user
    })
    if (!user.isAnonymous) {
      dispatch(setSetting(await getUserSettings(user.uid)))
    }
    dispatch(closeLogin())
    dispatch(setLoader(false));
  }
}

export function clearUser() {
  return {
    type: AUTH_CLEAR_USER
  }
}

export function setSetting(setting) {
  return {
    type: APP_SETTING_SET,
    payload: setting
  }
}
//SHARE
export function openShareModal() {
  return genericModal(SHARE_MODAL.OPEN);
}

export function closeShareModal() {
  return genericModal(SHARE_MODAL.CLOSE);
}

//MAIL
export function openMailModal() {
  return genericModal(MAIL_MODAL.OPEN);
}

export function closeMailModal() {
  return genericModal(MAIL_MODAL.CLOSE);
}

//Question
export function openQuestion() {
  return { type: QUESTION_OPEN };
}

export function closeQuestion() {
  return { type: QUESTION_CLOSE };
}

//My Reading
export function openMyReading() {
  return { type: MY_READING_OPEN };
}

export function closeMyReading() {
  return { type: MY_READING_CLOSE };
}

export function resetPasswordToggle(toggle) {
  return { type: RESET_PASSWORD_TOGGLE, payload: toggle };
}

export function setLoader(toggle) {
  return { type: LOADER_TOGGLE, payload: toggle };
}

export function cookieConsent() {
  setCookieConsent()
  return { type: COOKIE_CONSENT }
}
