import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get, has, isEmpty, map, omit } from "lodash";
import clsx from "clsx";
import { useClassBasedOnRoute, useQuery } from "../hooks";
import Content from "./common/Content";
import {
  getRandomData,
  goToElement,
  greetings,
  contentLimit,
  querParse,
  decode,
  encode,
  saveAnswer,
} from "../utils";
import { clearQuestion, getCardByMethod } from "../store/actions/card";
import IChingInfo from "./common/IChingInfo";
import Intro from "./common/Intro";

const CardSearchUI = (props) => {
  const [auth] = useSelector((state) => [state.authReducer]);
  return (
    <>
      {!auth.isLogin && (
        <>
          <p className="banner-1-content">
            Let 3000 year of wisdom help you to
            <br />
            answer questions about your love life,
            <br />
            relationships, investments or other
            <br />
            life questions. <br />
          </p>
        </>
      )}
      {auth.isLogin && (
        <>
          <p className="banner-1-content">
            {greetings()}, {get(auth, "user.displayName") || "User"} <br />{" "}
            Welcome back!
          </p>
        </>
      )}

      <h3 className="banner-1-title">What question do you want answered?</h3>
      <h4 className="BannerHit">
        Concentrate on your question and click the button
      </h4>
      {/* <span className="banner-1-input">
      <form onSubmit={handleFormSubmit}>
        <input type="text" placeholder="Type your question....." onChange={handleQuestionChange} />
        <button type="submit" ref={questionBtnRef} className="home_search">
          <i className="fa fa-angle-right"></i>
        </button>
      </form>
    </span> */}
      <ul className="actions special banner-box">
        <li>
          {auth.user && (
            <Link
              to="/"
              onClick={props.handleSearchIcon}
              className={clsx("BannerBox", "search", { hover: false })}
            >
              <img className="normal" src="/images/search.png" alt="" />
              <img className="hover" src="/images/search-hover.png" alt="" />
              {/* <img className="normal" src="/images/QuantumHome.png" alt="" />
          <img className="hover" src="/images/QuantumHome.png" alt="" /> */}
            </Link>
          )}
        </li>
      </ul>
    </>
  );
};

const CardBannerUI = (props) => {
  const [data] = useSelector((state) => [state.questionReducer.question]);

  const isTraditional = () => {
    return get(data, "method") === "Traditional";
  };
  const lineCommentary = isTraditional()
    ? get(data, "data[0].Translation.iChingLines[0].lineCommentary", "")
    : get(data, "data[0].Translation.iChingJudgementText", "");
  const filePath = isTraditional()
    ? `red-blue-line/${get(data, "data[0].Number")}-${get(
      data,
      "random.movingLines[0]"
    )}`
    : get(data, "data[0].Number");
  const blueText = isTraditional()
    ? get(data, "data[0].Translation.iChingLines[0].lineIChing")
    : get(data, "data[0].Translation.iChingJudgement");

  const isMovieLineEmpty =
    !isEmpty(get(data, "data[0].Translation.iChingLines", [])) &&
    !isTraditional();
  return (
    <>
      <div className="banner-top-question">
        {/* {question && <>
          <span style={{ color: "#374C5E" }}>Question:</span> {question}
        </>} */}
      </div>
      <div className="banner-top-question-line">
        {get(data, "data[0].Translation.title")}
        {has(data, "random.movingLines[0]") && (
          <i>
            {isTraditional() && (
              <>, Line {get(data, "random.movingLines[0]")}</>
            )}
          </i>
        )}
      </div>
      <div className="Banner_Box">
        <div className="BannerLeftImage">
          <div className="hexa-box-bg">
            <img
              className="hexa-content"
              src={`/images/cards/${filePath}.png`}
              alt=""
            />
            <div className="hexa-chine">
              <img
                src={`/images/chinese/whites/${get(
                  data,
                  "data[0].Number"
                )}.svg`}
                alt=""
              />
            </div>
            <div className="hexa-top-title BanneAns">
              {get(data, "data[0].Translation.title")}
            </div>
          </div>
        </div>
        <div className="BannerQuestionTop">{contentLimit(blueText, 200)}</div>
        <div className="BannerQuestionBottom">
          {contentLimit(lineCommentary, 200)}
        </div>
        <div className="btn-wrapper">
          <div className="banner-bottom-btn">
            {/**isTraditional get(data, "data[0].Translation.iChingLines", []).length > 1*/}
            {!isTraditional() && (
              <div
                className={clsx("banner-box4-bottom btn_left left-mobile", {
                  "mobile-hidden": !isMovieLineEmpty,
                })}
              >
                <div className="banner-box4-btn">
                  <Link
                    to="/"
                    className="banner-theme-btn"
                    onClick={props.handleMoreLine}
                  >
                    Moving lines
                  </Link>
                  <i
                    className={clsx([
                      "fa",
                      "fa-angle-right",
                      { "arrow-rotate": props.isMoreLine },
                    ])}
                  ></i>
                </div>
              </div>
            )}
            <div
              className={clsx(
                "banner-box4-bottom btn_left left-mobile rightButton",
                { "mobile-hidden": isMovieLineEmpty }
              )}
            >
              <div className="banner-box4-btn">
                <Link
                  to={{
                    pathname: "/full-reading",
                    state: {},
                    search: `?query=${encode(omit(data, ["data"]))}`,
                  }}
                  className="banner-theme-btn"
                >
                  Full reading
                </Link>
                <i className="fa fa-angle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MoreLineUI = (props) => {
  const [data] = useSelector((state) => [state.questionReducer.question]);

  return (
    <>
      {props.isMoreLine &&
        map(
          get(data, "data[0].Translation.iChingLines", []),
          (iChingLine, index) => (
            <section
              key={"iChingLine-" + index}
              className={clsx([
                "wrapper",
                "style1",
                "special",
                "section-6",
                { "section-7": index % 2 === 1 },
              ])}
            >
              <div className="SectionInner">
                <div className="InnerBox">
                  <div className="banner-top-question-line-grey">
                    {get(data, "data[0].Translation.title")}
                    {has(data, "random.movingLines[0]") && (
                      <i>, Line {get(iChingLine, "lineNumber")}</i>
                    )}
                  </div>
                  <div className="Banner_Box">
                    <div className="BannerLeftImage">
                      <div className="hexa-box-bg">
                        <img
                          className="hexa-content"
                          src={`/images/cards/red-blue-line/${get(
                            data,
                            "data[0].Number"
                          )}-${get(iChingLine, "lineNumber")}.png`}
                          alt=""
                        />
                        <div className="hexa-chine">
                          <img
                            src={`/images/chinese/whites/${get(
                              data,
                              "data[0].Number"
                            )}.svg`}
                            alt=""
                          />
                        </div>
                        <div className="hexa-top-title BanneAns">
                          {get(data, "data[0].Translation.title")}
                        </div>
                      </div>
                    </div>
                    <div className="BannerQuestionTop">
                      {contentLimit(get(iChingLine, "lineIChing"), 180)}
                    </div>
                    <div className="BannerQuestionBottom-grey">
                      {contentLimit(get(iChingLine, "lineCommentary"), 180)}
                    </div>
                    <div className="banner-bottom-btn single-btn">
                      <div className="banner-box4-bottom btn_left grey-btn_color">
                        <div className="banner-box4-btn">
                          <Link
                            to={{
                              pathname: "/full-reading",
                              state: {},
                              search: `?query=${encode(omit(data, ["data"]))}`,
                            }}
                            className="banner-theme-btn"
                          >
                            Full reading
                          </Link>
                          <i className="fa fa-angle-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )
        )}
    </>
  );
};

export default (props) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const [app, data] = useSelector((state) => [
    state.appReducer,
    state.questionReducer.question,
  ]);
  const [isMoreLine, setIsMoreLine] = React.useState(false);
  const [auth] = useSelector((state) => [state.authReducer]);
  const isAnon = get(auth, "user.isAnonymous");

  const handleSearchIcon = (e) => {
    e.preventDefault();
    const method =
      (app.settings && app.settings[0] && app.settings[0].PreferredMethod) ||
      "CoinStack";
    const data = getRandomData(method);
    props.history.push({
      pathname: "/",
      search: `?query=${encode(omit(data, ["data"]))}`,
    });
    saveAnswer(data);
    dispatch(getCardByMethod(data));
    e.target.closest("#banner").classList.add("play-1");
    const bodyClass = document.body.classList;
    bodyClass.remove("page-1");
    bodyClass.add("page-4", "card-animation-2");
  };

  const getCardBasedOnQuery = () => {
    if (query.get("query")) {
      document.getElementById("banner").classList.add("play-1");
      const bodyClass = document.body.classList;
      bodyClass.remove("page-1");
      bodyClass.add("page-4", "card-animation-2");
      dispatch(
        getCardByMethod(decode(querParse(props.location.search, "query")))
      );
    }
  };

  useClassBasedOnRoute(["page-1"]);

  useEffect(() => {
    if (data) {
      let bodyClass = document.body.classList;
      bodyClass.add("white-nav");
      setTimeout(() => {
        bodyClass.add("card-animation");
      }, 500);
    } else {
      document.body.classList.add("page-1");
      document.querySelector("#banner").classList.remove("play-1");
    }
  }, [data]);

  useEffect(() => {
    getCardBasedOnQuery();
    return () => {
      dispatch(clearQuestion());
      document.body.classList.remove(
        "page-4",
        "white-nav",
        "card-animation",
        "card-animation-2"
      );
    };
  }, []);

  const handleMoreLine = (e) => {
    e.preventDefault();
    setIsMoreLine(!isMoreLine);
  };

  return (
    <>
      {
        <section id="banner">
          <div className={clsx("inner", { leftCenter: !data })}>
            {!data && <CardSearchUI handleSearchIcon={handleSearchIcon} />}
            {data && (
              <CardBannerUI
                handleMoreLine={handleMoreLine}
                isMoreLine={isMoreLine}
              />
            )}
          </div>
          {
            <Link
              to="/"
              onClick={(e) => goToElement(e, "#info")}
              className="more scrolly"
            >
              <img src="/images/down.png" alt="Down" />
            </Link>
          }
        </section>
      }
      {data && <MoreLineUI isMoreLine={isMoreLine} />}
      {isAnon && <Intro />}
      {!isAnon && <IChingInfo />}
      <Content />
    </>
  );
};
