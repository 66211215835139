import React from "react";
import Content from "../common/Content";

export default (props) => {
  return (
    <>
      <section id="banner" className="ScienceSection">
        <div className="OnRichMain">
          <h3 className="HowTotitle">Science on the I Ching, does it work?</h3>
        </div>
      </section>

      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortPhera">
            What does science say about divination or the workings of the I Ching? 
            To answer this, let’s move to Dean Radin, former president of the 
            Parapsychological Association..{" "}
          </div>

          <div className="ShortAboveTitle">How we can sense the future</div>
          <div className="ShortBelowPhera">
            Dean Radin in The Conscious Universe describes an experiment in his
            laboratory at the University of Nevada. The experiment uses a
            psychophysical reflex experienced by humans when faced with new and
            unexpected stimuli. The participants were put in a comfortable chair
            with electrodes attached to their hands to monitor physiological
            responses. The participants during the experiment were randomly
            shown photos in two categories: calm and emotional. The calm
            category consisted of pleasant pictures of landscapes, cheerful
            people, etc. The emotional category consisted of arousing,
            disturbing or shocking images, including erotic photos and
            autopsies.
          </div>

          <div className="ShortPhera">
            The study found that the participants showed physiological signs of
            anticipation (i.e. sweat in hands) before showing any picture. This
            is not surprising. But the interesting part was that the
            physiological symptoms were stronger if the photo that would follow
            was in the 'emotional' category. The participants' reaction showed
            what Dean Radin would call the "presentiment effect". The essence
            was that the participants' physiologic response somehow sensed if an
            emotionally charged picture would show up the next seconds.{" "}
          </div>

          <div className="ShortAboveTitle">
            How our "consciousness" can control "matter."
          </div>
          <div className="ShortBelowPhera">
            Another fascinating study Dean Radin published in 2012 showed
            participants being able, with their consciousness, to influence the
            outcome of a "dotuble slit experiment" and influencing "the collaps
            of the wave function." Participants were asked to influence if an
            electron would behave like a ''wave'' or like a ''particle. The data
            showed that the participants were able to do just that and that the
            mind somehow was able to control an electron's behavior. What is
            also fascinating in the studies of Dean Radin is that participants,
            that master the art of meditation, showed a much more robust
            capability in controlling the outcomes of the experiments than
            participants without extensive experience in meditation techniques.{" "}
          </div>

          <div className="ShortAboveTitle">Priming the future</div>
          <div className="ShortBelowPhera">
            Another famous study on precognition and sensing the future comes
            from Daryl Bem, a psychologist and professor emeritus from the
            Cornell University. His study from 2011 had some very interesting
            outcomes. For instance, students were asked to recall words, out of
            a list of random words they had been shown before. After that, they
            had to (repeatedly) type the words from that same list. What showed
            was that the students better recalled the words that they later had
            to type. Showing an effect that future behavior somehow influences
            your current capabilities in the here and now.{" "}
          </div>

          <div className="ShortAboveTitle">Controversy remains</div>
          <div className="ShortBelowPhera">
            While on the one hand, all the evidence from research is mounting up
            to show that precognition, mind over matter, and other PSI phenomena
            are real. On the other hand, attacks on these studies (and the
            researchers) become fiercer, like also happened to (the work of)
            Daryl Bem. Larry Dossey wrote an excellent article on this topic,
            showing how part of the scientific community is outright closing
            their eyes for any facts. He quotes Anthony Gottlieb a scholar at
            the N.Y.U, who says: "But even if Daryl Bem's study (..) turns out
            to be gold-standard science and breaks none of the standard
            procedural rules, one can still be confident that its findings are
            incorrect." In other words, some people cannot be convinced by
            scientific methods, no matter how much evidence the data presents.
          </div>

          <div className="ShortPhera">
            But maybe all this resistance to the evidence is just a normal
            process for old ways of thinking to die out. Neal Grossman,
            professor emeritus of the University of Illinois at Chicago, puts it
            best:
          </div>

          <div className="ShortPhera">
            <em>
              " Materialism—the belief that consciousness is produced by or is
              the same thing as the physical brain—is one of those beliefs that
              have already been proved false by science. However, (..) it will
              take another generation before these facts are recognized by
              mainstream academia. Old paradigms never go gently into the night:
              they go screaming and kicking."
            </em>
          </div>

          <div className="ShortAboveTitle">
            What does all this scientific research say about the workings of the
            I Ching?
          </div>
          <div className="ShortBelowPhera">
            The different parapsychology studies suggest a new paradigm where
            consciousness, attention, and concentration play a fundamental role
            for understanding reality.
          </div>

          <div className="ShortPhera">
            Research shows that "sensing the future" looks more like an
            unconscious process, where the body "senses" without conscious
            awareness. Maybe the I Ching is a means to uncover what is
            unconsciously already known?
          </div>
          <div className="ShortPhera">
            The "mind over matter" experiments show that attention,
            concentration, and control over the mind (through meditation
            practice) are essential factors. It is interesting to see that
            meditation or "stillness" is a topic covered in the I Ching and seen
            as a critical quality. The fact that meditation and divination are
            both old techniques from old thinkers is maybe no coincidence.{" "}
          </div>
          <div className="ShortPhera">
            Furthermore, it is interesting to see that, like the deep thinkers
            who created the I Ching, research confirms that the future may be
            fluid. We can sense probable outcomes, sure, but we can always act
            to shape (or should we say reshape?) the future.
          </div>
          <div className="ShortPhera">
            More research is needed to find out how divination systems like the
            I Ching work. For now, it suffices to say that scientific research
            is more and more confirming foundational aspects of the workings of
            the I Ching.
          </div>

          <div className="ShortPhera">Links:</div>
          <div className="ShortAboveTitleLinks">
            Dean Radins' article of 2012 '' Consciousness and the double-slit
            interference pattern: Six experiments''.{" "}
            <a className="five" href="https://www.researchgate.net/publication/258707222_Consciousness_and_the_double-slit_interference_pattern_Six_experiments">
              https://www.researchgate.net/publication/258707222_Consciousness_and_the_double-slit_interference_pattern_Six_experiments
            </a>
          </div>

          <div className="ShortAboveTitleLinks">
            Dean Radins on youtube: New Experiments Show Consciousness Affects
            Matter. Link:{" "}
          </div>
          <div className="ShortBelowPhera">
            <a className="five" href="https://youtu.be/nRSBaq3vAeY">
              https://youtu.be/nRSBaq3vAeY
            </a>
          </div>

          <div className="ShortAboveTitleLinks">
            Daryl Dossey article ''Why scientist are afraid of Daryl Bem?'' can
            be found here:{" "}
          </div>
          <div className="ShortBelowPhera">
            <a className="five" href="https://www.alice.id.tue.nl/references/dossey-2011.pdf">
              https://www.alice.id.tue.nl/references/dossey-2011.pdf
            </a>
          </div>
        </div>
      </section>

      <Content />
    </>
  );
};
