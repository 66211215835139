import React from "react";
import Content from "../common/Content";

export default (props) => {
  return (
    <>
      <section id="banner" className="OtherIchingSection">
        <div className="OnRichMain">
          <h3 className="HowTotitle">Other I Ching resources</h3>
        </div>
      </section>

      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortPhera">
            There are great I Ching (or Yijing) resources. Let us list you our
            personal favorite I Ching sites:{" "}
          </div>
          {/* <div className="ShortBelowPhera">Our top 3</div> */}

          <div className="ShortAboveTitle">Clarity Online</div>
          <div className="ShortBelowPhera">
            <a className="five" href="https://www.clarity-online.co.uk/">
              www.clarity-online.co.uk
            </a>
          </div>
          <div className="ShortPhera">
            Hilary Barret, who published an I Ching translation and commentary,
            runs an I Ching community and gives courses on the I Ching. Her site
            is full of content and has a vibrant community. The best piece of
            the site is probably the 'Wiki Wings' section, where you can find
            example readings for every hexagram and line. It only costs one
            pound per month, the last time we checked. Highly recommended!
          </div>

          <div className="ShortAboveTitle">Yijing.nl</div>
          <div className="ShortBelowPhera">
            <a className="five" href="https://www.yijing.nl/">www.yijing.nl</a>
          </div>
          <div className="ShortPhera">
            Lotti Heyboer, a good friend of mine, is working on her site for
            over 20 years. She wants to uncover the Yijing (she doesn't like us
            to say I Ching) as understood by the Shamans that originated the
            Yijing Images and symbols. If you want to go deep, dive here. Or of
            course just use here fantastic interpretation and translation right
            here on <a href="https://FreeIChing.com">FreeIChing.com</a>.
          </div>

          <div className="ShortAboveTitle">Yijing Research Center</div>
          <div className="ShortBelowPhera">
            <a className="five" href="https://www.yjcn.nl/wp/">www.yjcn.nl/wp/</a>
          </div>
          <div className="ShortPhera">
            Harmen Mesker is fully dedicated to the study of the Yijing (also
            professionally), masters Chinese, and has excellent historical
            knowledge of the Yijing. He also is funny; at least if you like
            Dutch humor, watch his youtube video explaining the concept of
            moving lines in the I Ching, and you will understand what we mean.
            The Harmen site shows Letter on the I Ching. Those letters showed 
            us how Richard Wilhelm himself visualized the readings of the I Ching 
            and this in turn inspired the design of this website.
          </div>

          <div className="ShortAboveTitle">Other great sites:</div>
          <div className="ShortPhera">
            <a className="five" href="https://Biroco.com">Biroco.com</a>
          </div>
          <div className="ShortBelowPhera">
            Steven Marshall shares a lot of information on the Yijing, including
            links and his book{" "}
          </div>
          <div className="ShortBelowPhera">''Mandate of Heaven''.</div>

          <div className="ShortPhera">
            <a className="five" href="https://Hermetica.info">Hermetica.info</a>
          </div>
          <div className="ShortBelowPhera">
            Very good I Ching commentary and in-depth articles. A recommended
            site. Also, you can download a Yijing translation and commentary
            (pdf) for free.
          </div>
        </div>
      </section>

      <Content />
    </>
  );
};
