import { random } from "lodash";
import { getTraditionalData, getCardsByCardNumber } from "./firebase";
import {
  card1,
  card2,
  movingLines,
  coinStalkRef,
  yarrowStackRef,
  sixDigitNumberUpTo16,
  movingLinesFilter,
} from "./methodUtils";

const traditional = () => ({
  cards: [random(1, 64)],
  movingLines: [random(1, 6)],
});

const yarrowStack = () => {
  let sixDigit1 = yarrowStackRef(sixDigitNumberUpTo16());
  let hexagram1 = card1(sixDigit1);
  let hexagram2 = card2(sixDigit1);

  return {
    sixDigit1: sixDigit1,
    cards: [hexagram1, hexagram2],
    movingLines: movingLines(sixDigit1),
  };
};

const coinStack = () => {
  let sixDigit1 = coinStalkRef(sixDigitNumberUpTo16());
  let hexagram1 = card1(sixDigit1);
  let hexagram2 = card2(sixDigit1);

  return {
    sixDigit1: sixDigit1,
    cards: [hexagram1, hexagram2],
    movingLines: movingLines(sixDigit1),
  };
};

export const getMethodData = async (obj) => {
  switch (obj.method) {
    case "Traditional":
      obj.data = await getTraditionalData(obj.random);
      break;
    case "YarrowStack":
      obj.data = await getCardsByCardNumber(obj.random.cards);
      movingLinesFilter(obj.data, random.movingLines);
      break;
    case "CoinStack":
      obj.data = await getCardsByCardNumber(obj.random.cards);
      movingLinesFilter(obj.data, obj.random.movingLines);
      break;
    default:
      obj.data = await getTraditionalData(obj.random);
  }
  return obj;
};

export const getRandomData = (method) => {
  let obj = {};
  obj.method = method;
  console.log("Selected Method to get data", obj.method);
  switch (obj.method) {
    case "Traditional":
      obj.random = traditional();
      break;
    case "YarrowStack":
      obj.random = yarrowStack();
      break;
    case "CoinStack":
      obj.random = coinStack();
      break;
    default:
      obj.random = traditional();
      obj.method = "Traditional";
  }
  return obj;
};
