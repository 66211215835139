
import React from "react";
import ReactStars from "react-rating-stars-component";

export const star = ({ input, name, value = 0, label, meta: { touched, error }, ...rest }) => (<>
  <ReactStars
    {...input}
    count={5}
    value={value}
    //size={40}
    isHalf={false}
    emptyIcon={<img src="/images/star-unselect2.png" alt="" />}
    filledIcon={<img src="/images/star-select2.png" alt="" />}
  />
</>)
