import React from "react";
import Content from "../common/Content";

export default () => {
  return (
    <>
      <section id="banner" className="LegalNoticesSection">
        <div className="OnRichMain">
          <h3 className="HowTotitle">Yijing and Book of Changes</h3>
        </div>
      </section>
      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortAboveTitle">
            Yijing and Book of Changes
          </div>
          <div className="ShortPhera">
            The I Ching is also known by the name of Book of Changes and Yijing. 
            We choose to use the more well-known name ‘’I Ching’’, just because 
            it is more familiar and recognizable for people.
          </div>
        </div>
      </section>
      <Content />
    </>
  );
};
