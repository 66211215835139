import React from "react";
import Content from "../common/Content";

export default (props) => {
  return (
    <>
      <section id="banner" className="RealExampleSection">
        <div className="MoreOnMain">
          <h3 className="RealExampleBanner">Real example reading</h3>
        </div>
      </section>
      <section id="" className="RealExampleBottom">
        <div className="HowToInner">
          <h3 className="RealExampleTitle">Example Reading, Hexagram 42</h3>
          <div className="RealExampleAboveTitle">Background & Question</div>
          <div className="RealExamplePhera">
            A young man, friendly and sincere, was insecure in front of other
            people and large groups. He asked how to deal better with this
            insecurity and how to act in larger groups.
          </div>
          <div className="RealExampleBg">
            <div className="RealExpLeft">
              <h3 className="RealExampleboxTitle">
                I Ching responded: 42, 5th line moving.
              </h3>
              <div className="RealExampleboxtext">
                “If in truth you have a kind heart, ask not. Supreme good
                fortune. Truly, kindness as your virtue will be noticeable.'{" "}
              </div>
            </div>
            <div className="RealExpright">
              <img
                className="realexmImg"
                src={`../../images/realbox.png`}
                alt=""
              />
            </div>
          </div>
          <div className="RealExampleAboveTitle">
            Interpretation and Outcome
          </div>
          <div className="RealExamplePhera">
            The interpretation was straightforward. The young man had no reason to be insecure, 
            but more importantly, I Ching stated not to adjust or change himself and show his real self. 
            Not much later, he met a woman in his life while interacting in a large group.{" "}
          </div>
        </div>
      </section>
      <section id="" className="RealExampleGreyBottom">
        <div className="HowToInner">
          <h3 className="RealExampleTitle">Example Reading, Hexagram 63</h3>
          <div className="RealExampleAboveTitle">Background & Question</div>
          <div className="RealExamplePhera">
            Working as a mid-size company director, an adult man was thinking
            about a management buy-out with some other people at this company.
            The discussions were ongoing with the owners. He asked about the
            situation and what to do.
          </div>
          <div className="RealExampleBg">
            <div className="RealExpLeft">
              <h3 className="RealExampleboxTitle">
                I Ching responded: 63, 2nd line moving.
              </h3>
              <div className="RealExampleboxtext">
                ''The woman loses the curtain of her carriage. Do not run after
                it; On the seventh day, you will get it.''{" "}
              </div>
            </div>
            <div className="RealExpright">
              <img
                className="realexmImg"
                src={`../../images/realbox63Line.png`}
                alt=""
              />
            </div>
          </div>
          <div className="RealExampleAboveTitle">
            Interpretation and Outcome
          </div>
          <div className="RealExamplePhera">
            Richard Wilhelm comments on this line: 'That which is a man’s own
            cannot be permanently lost. It comes to him of its own accord. He
            need only be able to wait." So the interpretation was like, let's
            wait, and whatever comes will be all right. Not much later, the
            owners of the company declined to cooperate with a management
            buy-out. The company much later experienced quite some challenges
            due to the corona-virus. This hexagram 63 also generally is a
            warning: that the here and now is good but can turn into something
            problematic if not taking care of correctly. Maybe the
            interpretation could also have been a warning sign not to move
            forward with the management buy-out. Perhaps the proposed change
            would not have been for the best. Similarly, the relating hexagram
            (hexagram 5. Waiting) also warns of danger ahead and biding your
            time.
          </div>
        </div>
      </section>
      <Content />
    </>
  );
};
