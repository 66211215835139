import React from "react";

export const input = ({
  input,
  label,
  placeholder,
  type,
  meta: { touched, error },
  ...rest
}) => (
  <div className="input-group">
    <label className="label">{label}</label>
    <input
      {...rest}
      {...input}
      type={type || "text"}
      placeholder={placeholder || label}
    />
    {touched && error && <small>{error}</small>}
  </div>
);

export const input2 = ({
  input,
  label,
  placeholder,
  type,
  meta: { touched, error },
  ...rest
}) => (
  <div className="question-box">
    <span className="ques-text">{label}</span>
    <input
      className="quest-input"
      {...rest}
      {...input}
      type={type || "text"}
      placeholder={placeholder || label}
    />
  </div>
);

export const Input3 = ({
  input,
  label,
  placeholder,
  type,
  meta: { touched, error },
  ...rest
}) => (
  <div className="info-wrapper login-info ">
    <div className="head-wrapper">{label}</div>
    <div className="form-wrapper">
      <input
        className="form-control"
        type={type || "password"}
        {...rest}
        {...input}
      />
    </div>
    {touched && error && <small className="error">{error}</small>}
  </div>
);

export const Input4 = ({
  input,
  label,
  placeholder,
  type,
  checked,
  meta: { touched, error },
  ...rest
}) => (
  <>
    <label className="input-container">
      {label}
      <input type={type} checked={!!checked} {...rest} {...input} />
      <span className="checkmark"></span>
    </label>
    {touched && error && <small className="error">{error}</small>}
  </>
);
