export const formValidation = {
  required: (value) =>
    value &&
      ((typeof value === "string" && value.trim()) ||
        (typeof value !== "string" && true))
      ? undefined
      : "This field is required.",
  requiredAsArray: (value) =>
    value && value.length >= 1 ? undefined : "Required",
  alpha: (value) =>
    /^[a-zA-Z]+$/.test(value) ? undefined : "This field should be alphabetic only.",
  maxLength: (max) => (value) =>
    value && value.length > max
      ? `Must be ${max} characters or less`
      : undefined,
  minLength: (min) => (value) =>
    value && value.length < min
      ? `Password must be at least ${min} characters`
      : undefined,
  number: (value) =>
    value && isNaN(Number(value)) ? "Must be a number" : undefined,
  minValue: (min) => (value) =>
    value && value < min ? `Must be at least ${min}` : undefined,
  maxValue: (max) => (value) =>
    value && value > max ? `Must be number up to ${max}` : undefined,
  email: (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? "This is not a valid email address."
      : undefined,
  pattrenMatch: (pattren, message) => (value) =>
    value && !pattren.test(value) ? message : undefined,
  matchPassword: (value, values) => {
    return value === values["new-password"]
      ? undefined
      : "Password do not match.";
  },
};
