import React, { useEffect, useState } from "react";
import { useClassBasedOnRoute } from "../hooks";
import Content from "./common/Content";
import { get, isEmpty, map } from "lodash";
import { getCard } from "../utils/firebase";
import { Link } from "react-router-dom";
import clsx from "clsx";

export default (props) => {
  const [data, setData] = useState([]);

  useClassBasedOnRoute(["myReading"]);

  const getHexa = async () => {
    const data = await getCard(props.match.params.hex);
    setData(data);
  };
  // console.log("props", props.match.params.hex)
  useEffect(() => {
    getHexa();
  }, []);
  return (
    <>
      <section id="banner" className={clsx({"screen-flex": isEmpty(data) })}>
        {!isEmpty(data) && (
          <>
            <div className="inner Hexagram_Banner_Box">
              <div className="Banner_Box">
                <div className="ui-hexagram">
                  <div className="hexa-chine">
                    <img
                      src={`/images/chinese/whites/${get(
                        data,
                        "[0].Number"
                      )}.svg`}
                      alt=""
                    />
                  </div>
                  <div className="hexa-top-title BanneAns-hexagram">
                    {get(data, "[0].Number")}.{" "}
                    {get(data, "[0].Translation.title")} (
                    {get(data, "[0].InternalName")})
                  </div>
                  <img
                    className="hexa-content-hexagram"
                    src={`/images/cards/${get(data, "[0].Number")}.png`}
                    alt=""
                  />
                </div>
                {/* <div className="back-to-overview">
                  <Link to="/hexagram">Back to overview</Link>
                </div> */}
              </div>
            </div>
          </>
        )}
      </section>
      {map(data, (card, index) => (
        <React.Fragment key={`card-${index}`}>
          <section className="wrapper style1 special section-15 meaning">
            <div className="row m-0 p-l-r-50">
              {/* <div className="hexa-meaning">Meaning of the Hexagram</div> */}
              <div className="HexaMeaningLeft HexaLeftMobile">
                <div className="meaning-box">
                  <h4 className="HexaLanguage">
                    <img
                      src={`/images/chinese/blacks/${get(
                        data,
                        "[0].Number"
                      )}.svg`}
                      alt=""
                    />
                  </h4>
                  <h4 className="HexaMeanTitle">
                    {get(data, "[0].Number")}. {get(card, "Translation.title")}{" "}
                    ({get(card, "InternalName")})
                  </h4>
                  {index !== 0 && (
                    <p className="HexaMeancontent">
                      {get(card, "Translation.iChingJudgement")}
                    </p>
                  )}
                </div>
              </div>
              {
                <div className="HexaMeaningRight HexaRightMobile">
                  <div className="meaning-box-right">
                    <h4 className="HexaMeaningRightTitle">
                      {get(card, "Translation.commentDescription")}
                    </h4>
                    <p className="HexaMeaningRightContent">
                      {get(card, "Translation.iChingHexagramTitle")}
                    </p>
                  </div>
                </div>
              }
            </div>
          </section>
          {index === 0 && (
            <>
              <section className="wrapper style1 special section-16">
                <div className="row m-0 p-l-r-50">
                  <div className="HexaMeaningLeft HexaLeftMobile">
                    <div className="meaning-box">
                      <h4 className="HexaMeanTitle top-space">The Judgment</h4>
                      <p className="HexaMeancontent">
                        {get(card, "Translation.iChingJudgement")}
                      </p>
                    </div>
                  </div>
                  {
                    <div className="HexaMeaningRight HexaRightMobile">
                      <div className="meaning-box-right">
                        <h4 className="HexaMeaningRightTitle">
                          {get(card, "Translation.iChingJudgementDescription")}
                        </h4>
                        <p className="HexaMeaningRightContent">
                          {get(card, "Translation.iChingJudgementText")}
                        </p>
                      </div>
                    </div>
                  }
                </div>
              </section>
              <section className="wrapper special section-16 section-16-bg">
                <div className="row m-0 p-l-r-50">
                  <div className="HexaMeaningLeft HexaLeftMobile">
                    <div className="meaning-box">
                      <h4 className="HexaMeanTitle top-space">The Image</h4>
                      <p className="HexaMeancontent">
                        {get(card, "Translation.iChingImage")}
                      </p>
                    </div>
                  </div>
                  {
                    <div className="HexaMeaningRight HexaRightMobile">
                      <div className="meaning-box-right">
                        <h4 className="HexaMeaningRightTitle">
                          {get(card, "Translation.iChingImageDescription")}
                        </h4>
                        <p className="HexaMeaningRightContent">
                          {get(card, "Translation.iChingImageText")}
                        </p>
                      </div>
                    </div>
                  }
                </div>
              </section>
              {map(
                get(card, "Translation.iChingLines", []),
                (line, lineIndex) => (
                  <React.Fragment key={`iching-${index}-${lineIndex}`}>
                    <section className="wrapper special section-16 section-16-bg">
                      <div className="row m-0 p-l-r-50">
                        <div className="HexaMeaningLeft HexaLeftMobile">
                          <div className="meaning-box">
                            <h4 className="HexaMeanTitle top-space">
                              I Ching text line {get(line, "lineNumber")}:
                            </h4>
                            <img
                              className="hexa-left-img"
                              src={`/images/lines/${get(
                                line,
                                "lineNumber"
                              )}.png`}
                              alt="Iching Line"
                            />
                            <p className="LeftSpace">
                              {get(line, "lineIChing")}
                            </p>
                          </div>
                        </div>
                        {
                          <div className="HexaMeaningRight HexaRightMobile">
                            <div className="meaning-box-right">
                              <h4 className="HexaMeaningRightTitle">
                                {get(
                                  card,
                                  "Translation.iChingLinesDescription"
                                )}
                              </h4>
                              <p className="HexaMeaningRightContent">
                                {get(line, "lineCommentary")}
                              </p>
                            </div>
                          </div>
                        }
                      </div>
                    </section>
                  </React.Fragment>
                )
              )}
            </>
          )}
          <section className="wrapper style1 special">
            <div className="row m-0 p-l-r-50">
              {/* <div className="back-to-overview">
                <Link to="/hexagram" className="dark">
                  Back to overview
                </Link>
              </div> */}
            </div>
          </section>
        </React.Fragment>
      ))}
      <Content />
    </>
  );
};
