import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { resetPasswordToggle } from "../store/actions/app";
import { signup, signin, resetPassword } from "../store/actions/auth";
// import Spinner from "./common/Spinner";
import { LoginForm, RegisterForm, ResetForm } from "./auth";
import { reset as resetForm } from 'redux-form';


const Login = ({ signup, signin, resetPassword, isSignup }) => {
  const dispatch = useDispatch();
  const [reset, setReset] = useState(false);
  const [loader, setLoader] = useState(false);

  function formSubmitHandler(data) {
    if (isSignup) {
      setLoader(true);
      signup(data, (error) => {
        setLoader(false);
        if (!error) {
          dispatch(resetForm('registerForm'));
        }
      });
    } else {
      setLoader(true);
      if (reset) {
        resetPassword(data.email, () => { setLoader(false); });
      } else {
        signin(data.email, data.password, () => { setLoader(false); });
      }
    }
  }

  function resetActionHandler() {
    setReset(true);
    dispatch(resetPasswordToggle(true));
  }

  useEffect(() => {
    return () => {
      dispatch(resetPasswordToggle(false));
    };
  }, []);
  return (
    <div className="center">
      <div className="login">
        <h2>{reset ? "Reset password" : isSignup ? "Sign up" : "Sign in"}</h2>
        {!(isSignup || reset) && (
          <>
            <LoginForm handleSumbit={formSubmitHandler} loader={loader} />
            <button onClick={resetActionHandler} className="btn-link">
              Forgot password?
            </button>
          </>
        )}
        {isSignup && <RegisterForm handleSumbit={formSubmitHandler} loader={loader} />}
        {reset && <ResetForm handleSumbit={formSubmitHandler} loader={loader} />}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    // loading: state.apiStatusReducer.apiCallsInProgress > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signup: (email, password, cb) => dispatch(signup(email, password, cb)),
    signin: (email, password, callback) =>
      dispatch(signin(email, password, callback)),
    resetPassword: (email, cb) => dispatch(resetPassword(email, cb)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
