import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  closeLogin,
  signInWithGoogle,
  signInWithFacebook,
} from "../../store/actions";
import CustomLink from "./CustomLink";
import Login from "../AuthControl";
import clsx from "clsx";

export default function () {
  const dispatch = useDispatch();
  const [app] = useSelector((state) => [state.appReducer]);
  const [template, setTemplate] = useState("SignIn");
  const [isSignUp, setIsSignUp] = useState(false);

  const checkTemplate = (name) => {
    return template === name;
  };

  const alreadyAccount = () => {
    setTemplate("SignIn");
    setIsSignUp(false);
  };

  const createAccount = () => {
    if (isSignUp) {
      setTemplate("SignIn");
      setIsSignUp(false);
    } else {
      setTemplate("LocalSignin");
      setIsSignUp(true);
    }
  };

  return (
    <div className="overlay-popup">
      <div className="overlay-pop-box">
        <CustomLink
          to="/"
          className="CloseRight"
          onClick={() => dispatch(closeLogin())}
        >
          <img src="/images/closeright.png" alt="X" />
        </CustomLink>
        <div className="overlay-pop-left">
          <Link to="/" className="pop-img">
            <img src="/images/pop-logo.png" alt="" />
          </Link>
          {
            /*checkTemplate("LocalSignin") &&*/ <div className="iching-inks">
              <span>
                {isSignUp && `By signing up for Freeiching.com`}
                {!isSignUp && `By logging into Freeiching.com`}, you agree to our{" "}
                <Link
                  target="_blank"
                  style={{ color: "inherit" }}
                  className="external-link"
                  to="/terms"
                >
                  {" "}
                  Terms of use
                </Link>{" "}
                and{" "}
                <Link target="_blank" style={{ color: "inherit" }} to="/privacy-policy">
                  Privacy Policy
                </Link>
                .
              </span>
            </div>
          }
        </div>
        <div className="overlay-pop-middle">
          {checkTemplate("SignIn") && (
            <>
              <h1 className="pop-welcome">Welcome back</h1>
              <p className="pop-welcome-content">
                Sign in to access you personal items, save questions and
                answers. Ad notes, share and be part of the community.
              </p>
              <div
                className="social-box-1 pointer"
                onClick={() => dispatch(signInWithFacebook())}
              >
                <img
                  className="social-img"
                  src="/images/facebook1.png"
                  alt=""
                />
                <div className="social-content">Sign in with Facebook</div>
              </div>
              <div
                className="social-box-2 pointer"
                onClick={() => dispatch(signInWithGoogle())}
              >
                <img className="social-img" src="/images/Google1.png" alt="" />
                <div className="social-content">Sign in with Google</div>
              </div>
              <div
                className="social-box-3 pointer"
                onClick={() => setTemplate("LocalSignin")}
              >
                <img className="social-img" src="/images/Mail1.png" alt="" />
                <div className="social-content">Sign in with Email</div>
              </div>
            </>
          )}
          {checkTemplate("LocalSignin") && <Login isSignup={isSignUp} />}
          {/* {checkTemplate("Register") && <>
            <Register />
          </>} */}
        </div>
        {!app.resetPasswordToggle && (
          <div className="overlay-pop-right">
            <div className="no-account-right">
              <CustomLink
                className={clsx("NOAccount", {
                  "cursor-initial": checkTemplate("SignIn"),
                })}
                onClick={alreadyAccount}
              >
                {isSignUp ? "Already registered?" : "Want to sign up with Email?"}

              </CustomLink>
              <CustomLink className="CreateOne" onClick={createAccount}>
                {isSignUp ? "Login here" : "Create Account"}
              </CustomLink>
            </div>
          </div>
        )}
        {/* <div className="overlay-pop-right">
          <div className="no-account-right">
            {template !== 'SignIn' && <CustomLink className="NOAccount" onClick={alreadyAccount}>
              Already have an account?
            </CustomLink>}
            {template === 'SignIn' && <CustomLink className="CreateOne" onClick={createAccount}>
              Create Account
            </CustomLink>}
          </div>
        </div> */}
      </div>
    </div>
  );
}
