
import React from "react";
import { map } from "lodash"

export const radio = ({ input, name, label, placeholder, options = [], meta: { touched, error }, ...rest }) => (
  <>
    <div className="QuestionRightText">{label}</div>
    <div className="QuestionLEft">
      {map(options, (option, index) => (
        <label className="selection-wrapper" key={`${input.name}-${index}`}>
          <input {...input} type="radio" value={option} />
          <span className="label">{option}</span>
        </label>
      ))}
    </div>
    <div className="input-group" style={{ width: "100%", "textAlign": "left" }}>
      {touched && error && <small>{error}</small>}
    </div>
  </>
)
