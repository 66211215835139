import React from "react";
import { Link } from "react-router-dom";

export default (props) => (
  <section id="one" className="wrapper style1 special section-1">
    <div className="inner">
    <div className="content-center IntroImage">
      <header className="section1">
        <h2>What is the I Ching?</h2>
        <p>
        The I Ching is an oracle: it answers questions. It is the oldest Oracle that is still 
        in use today. The I Ching is about 3.000 years old and originated in China, but is now 
        regularly consulted by people all around the world. The I Ching has been able to stand 
        the test of time which comes to show its real value.
        </p>
        <h2 className="m-21">What can the I Ching do for me?</h2>
        <p>
        See the I Ching as a tool. A tool that helps you connect to your unconscious, intuition, 
        or higher self. Ultimately to make wiser decisions and understand yourself and the world 
        better. The tool, like any other tool, is something you need to learn to use. But not to 
        worry, we created this site so that it becomes easy to build up your knowledge along the way.
        </p>
        <h2 className="m-21">Why FreeIChing.com?</h2>
        <p>
        We try to keep it practical. Make sure that you can apply ancient wisdom directly in your 
        life. You can keep track of your readings in a journal or share your readings with friends. 
        Last but not least, we care about your privacy and don't spam or bother you with advertisements. 
        Join us, it’s 100% free.
        </p>
      </header>
      </div>
    </div>
  </section>
);
