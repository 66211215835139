import React, { useEffect } from "react";
import { Switch, withRouter } from "react-router-dom";
import MainLayout from "./layouts/main";
import Home from "./Home";
import Reading from "./Reading";
import FullReading from "./FullReading";
import MyReading from "./MyReading";
import Settings from "./settings";
import Hexagram from "./Hexagram";
import HexagramDetails from "./HexagramDetails";
import MoreOnIChing from "./MoreOnIChing";
import HowToConsult from "./moreOnIChingLinks/HowToConsult";
import MethodToUse from "./moreOnIChingLinks/MethodToUse";
import RealExampleReading from "./moreOnIChingLinks/RealExampleReading";
import ShortAnatomy from "./moreOnIChingLinks/ShortAnatomy";
import OnRichardWilhelm from "./moreOnIChingLinks/OnRichardWilhelm";
import OtherIChingResources from "./moreOnIChingLinks/OtherIChingResource";
import ScienceOnIChing from "./moreOnIChingLinks/ScienceOnIChing";
import IChingBinaryCode from "./moreOnIChingLinks/IChingBinaryCode";
import IChingQuantumPhysics from "./moreOnIChingLinks/IChingQuantumPhysics";
import IChingCuriousLink from "./moreOnIChingLinks/IChingCuriousLink";
import LegalNotice from "./footerLinks/LegalNotice";
import Contact from "./footerLinks/Contact";
import BecomeMember from "./footerLinks/BecomeMember";
import Support from "./footerLinks/Support";
import Feedback from "./footerLinks/Feedback";
import WhatsIChing from "./footerLinks/WhatsIChing";
import IChingHelp from "./footerLinks/IChingHelp";
import Books from "./footerLinks/Books";
import CookiePolicy from "./footerLinks/cookiePolicy";
import PrivacyPolicy from "./footerLinks/privacyPolicy";
import TermsAndConditions from "./footerLinks/termsAndCond";
import Profile from "./profile";
import CommunityBoard from "./CommunityBoard";
import RouterWrapper from "./hoc/RouterWrapper";
import { closeQuestion } from "../store/actions";
import { useDispatch } from "react-redux";

const routes = [{
  exact: true,
  path: "/",
  component: Home,
  layout: MainLayout
}, {
  exact: true,
  path: "/reading",
  component: Reading,
  layout: MainLayout
},
{
  exact: true,
  path: "/full-reading",
  component: FullReading,
  layout: MainLayout
},
{
  exact: true,
  path: "/community-board",
  component: CommunityBoard,
  layout: MainLayout
},
{
  exact: true,
  path: "/categories",
  component: CommunityBoard,
  layout: MainLayout
},
{
  exact: true,
  path: "/myreadings",
  component: MyReading,
  layout: MainLayout
},
{
  exact: true,
  path: "/settings",
  component: Settings,
  layout: MainLayout
},
{
  exact: true,
  path: "/hexagram",
  component: Hexagram,
  layout: MainLayout
},
{
  exact: true,
  path: "/hexagram/:hex",
  component: HexagramDetails,
  layout: MainLayout
},
{
  exact: true,
  path: "/moreoniching",
  component: MoreOnIChing,
  layout: MainLayout
},
{
  exact: true,
  path: "/moreoniching/howtoconsult",
  component: HowToConsult,
  layout: MainLayout
},
{
  exact: true,
  path: "/moreoniching/methodtouse",
  component: MethodToUse,
  layout: MainLayout
},
{
  exact: true,
  path: "/moreoniching/realexamplereading",
  component: RealExampleReading,
  layout: MainLayout
},
{
  exact: true,
  path: "/moreoniching/shortanatomy",
  component: ShortAnatomy,
  layout: MainLayout
},
{
  exact: true,
  path: "/moreoniching/onrichardwilhelm",
  component: OnRichardWilhelm,
  layout: MainLayout
},
{
  exact: true,
  path: "/moreoniching/otherichingresouces",
  component: OtherIChingResources,
  layout: MainLayout
},
{
  exact: true,
  path: "/moreoniching/scienceontheiching",
  component: ScienceOnIChing,
  layout: MainLayout
},
{
  exact: true,
  path: "/moreoniching/ichingbinarycode",
  component: IChingBinaryCode,
  layout: MainLayout
},
{
  exact: true,
  path: "/moreoniching/ichingquantumphysics",
  component: IChingQuantumPhysics,
  layout: MainLayout
},
{
  exact: true,
  path: "/moreoniching/ichingcuriouslinks",
  component: IChingCuriousLink,
  layout: MainLayout
},
{
  exact: true,
  path: "/legalnotices",
  component: LegalNotice,
  layout: MainLayout
},
{
  exact: true,
  path: "/contact",
  component: Contact,
  layout: MainLayout
},
{
  exact: true,
  path: "/become-member",
  component: BecomeMember,
  layout: MainLayout
},
{
  exact: true,
  path: "/whats-iching",
  component: WhatsIChing,
  layout: MainLayout
},
{
  exact: true,
  path: "/iching-help",
  component: IChingHelp,
  layout: MainLayout
},
{
  exact: true,
  path: "/books",
  component: Books,
  layout: MainLayout
},
{
  exact: true,
  path: "/feedback",
  component: Feedback,
  layout: MainLayout
},
{
  exact: true,
  path: "/support",
  component: Support,
  layout: MainLayout
},
{
  exact: true,
  path: "/cookie-policy",
  component: CookiePolicy,
  layout: MainLayout
},
{
  exact: true,
  path: "/privacy-policy",
  component: PrivacyPolicy,
  layout: MainLayout
},
{
  exact: true,
  path: "/terms",
  component: TermsAndConditions,
  layout: MainLayout
},
{
  exact: true,
  path: "/profile",
  component: Profile,
  layout: MainLayout
}]

const Routes = ({ history }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // history listion
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
      dispatch(closeQuestion());
    });
    return () => {
      unlisten();
    };
  })
  return (
    <Switch>
      {routes.map((route, key) => (
        <RouterWrapper key={`router-${key}`} {...route} />
      ))}
    </Switch>
  )
}

export default withRouter(Routes);
