import firebase from "../config/firebase";
import { map, merge } from "lodash";
import store from "../store";
import { movingLinesFilter } from "../utils/methodUtils";
const lang = "English";
const mappingCollectionData = (collections) => {
  return collections.map((doc) => merge({ id: doc.id }, doc.data()));
};

const getCardTransData = async (card) => {
  store.getState();
  let trans = await firebase
    .firestore()
    .collection("Decks")
    .doc("IChingCards")
    .collection("Cards")
    .doc(card.id)
    .collection("Translations")
    .doc(lang)
    .get();
  card.Translation = trans.data();
  return card;
};

export const getCard = async (number) => {
  const card = await firebase
    .firestore()
    .collection("Decks")
    .doc("IChingCards")
    .collection("Cards")
    .where("Number", "==", Number(number))
    .limit(1)
    .get();
  return Promise.all(
    card.docs.map((doc) => getCardTransData(merge({ id: doc.id }, doc.data())))
  );
};

export const getTraditionalData = async (random) => {
  const cards = await getCard(random.cards[0]);
  movingLinesFilter(cards, random.movingLines);
  const card2 = await getCard(
    cards[0].Translation.iChingLines[0].relatedCardNumber
  );
  return [cards[0], card2[0]];
};

export const getCardsByCardNumber = async (cardsNumber) => {
  let data = await Promise.all(map(cardsNumber, (i) => getCard(i)));
  return [data[0][0], data[1][0]];
};

export const saveUserAnswer = (data, authId) => {
  return firebase
    .firestore()
    .collection("Users")
    .doc(authId)
    .collection("User_Answers")
    .add(data);
};

export const getUserAnswers = async (authId) => {
  return mappingCollectionData(
    await firebase
      .firestore()
      .collection("Users")
      .doc(authId)
      .collection("User_Answers")
      .orderBy("Date", "desc")
      .get()
  );
};

export const deleteAnswer = (authId, docId) => {
  return firebase
    .firestore()
    .collection("Users")
    .doc(authId)
    .collection("User_Answers")
    .doc(docId)
    .delete();
};

export const saveAnswer = async (data, deck = "IChingCards") => {
  const obj = {
    Annotation: map(data.data, "Number").join(":"),
    Date: new Date(),
    Deck: deck,
    Method: data.method,
    MovingLines: data.random.movingLines,
    ReadingHexagram: data.random.sixDigit1,
  };
  if (obj.ReadingHexagram === undefined) {
    delete obj.ReadingHexagram;
  }
  return await firebase.firestore().collection("All_Answers").add(obj);
};

export const getUserSettings = async (authId) => {
  await firebase.firestore().collection("Users").doc(authId).delete();
  return mappingCollectionData(
    await firebase
      .firestore()
      .collection("Users")
      .doc(authId)
      .collection("User_Setting")
      .get()
  );
};

export const setUserSettings = async (authId, { id, ...setting }) => {
  if (id) {
    await firebase
      .firestore()
      .collection("Users")
      .doc(authId)
      .collection("User_Setting")
      .doc(id)
      .set(setting);
  } else {
    await firebase
      .firestore()
      .collection("Users")
      .doc(authId)
      .collection("User_Setting")
      .add(setting);
  }
};

export const shareReading = (obj) => {
  obj.createdAt = firebase.firestore.Timestamp.fromDate(new Date());
  return firebase.firestore().collection("share_reading").add(obj);
};

export const mail = (obj) => {
  obj.createdAt = firebase.firestore.Timestamp.fromDate(new Date());
  return firebase.firestore().collection("mails").add(obj);
};

export const updatePassword = async (user, password) => {
  return await firebase.auth().currentUser.updatePassword(password);
};

export const deleteUser = async (obj) => {
  return await firebase.auth().currentUser.delete();
};
