import { has } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { closeShareModal } from "../../store/actions";
import { decode, querParse, shareReading } from "../../utils";
import CustomLink from "./CustomLink";

export default function () {
  const dispatch = useDispatch();
  const history = useHistory();
  const [message, setMessage] = useState();
  const [app, auth] = useSelector((state) => [
    state.appReducer,
    state.authReducer,
  ]);
  const shareUrl = window.location.href;

  const title = "FreeIChing";
  if (!app.modal.isShareModalOpen) {
    return null;
  }
  const handleShare = (shareSite) => () => {
    const obj = decode(querParse(history.location.search, "query"));
    if (has(obj, "method") && has(obj, "random")) {
      obj.shareSite = shareSite;
      obj.url = window.location.href;
      obj.userId = auth.user.uid;
      shareReading(obj);
      dispatch(closeShareModal());
    } else {
      console.error("Invalid data");
    }
  };

  const CopyToClipboard = () => {
    try {
      navigator.clipboard.writeText(window.location.href)
    } catch (err) {
      window.clipboardData.setData("Text", window.location.href)
    } finally {
      setMessage("Copied")
      setTimeout(() => setMessage(), 3000)
    }
  };

  return (
    <div className="overlay-popup text-center Mopup-Screen-Main">
      <div className="overlay-pop-box share popup-main">
        <CustomLink
          to="/"
          className="CloseRight pop-close-right"
          onClick={() => dispatch(closeShareModal())}
        >
          <img src="/images/closeright.png" alt="X" />
        </CustomLink>
        <div className="overlay-pop-left">&nbsp;</div>
        <div className="overlay-pop-middle popup-main-middle">
          <div className="pop-centre-popup-share">Share a link</div>
          <FacebookShareButton
            url={shareUrl}
            onClick={handleShare("FACEBOOK")}
            quote={title}
            style={{ boxShadow: "none", margin: "0 9px 0 0" }}
          >
            <img src="/images/fb-popup.png" alt="" width="48" />
          </FacebookShareButton>
          <TwitterShareButton
            url={shareUrl}
            onClick={handleShare("TWITTER")}
            title={title}
            style={{ boxShadow: "none", margin: "0 0 0 9px" }}
          >
            <img src="/images/tw-popup.png" alt="" width="48" />
          </TwitterShareButton>
          <CustomLink className="share-clipboard" onClick={CopyToClipboard}>
            <img src="/images/clipboard.png" alt="" width="48" />
          </CustomLink>
        </div>
        <div className="text-center copied">
          {message}
        </div>
      </div>
    </div>
  );
}
