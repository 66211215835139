import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useResponsiveClasses } from "./../hooks";
import { getUserResolve } from "../store/actions";
import Loader from "../components/common/Loader";
import Routes from "./routes";

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector((store) => store.appReducer);
  useResponsiveClasses();

  useEffect(() => {
    dispatch(getUserResolve())
  }, []);

  return (
    <React.Fragment>
      {auth.isLoader && <Loader />}
      <Routes />
    </React.Fragment>
  );
};

export default App;
