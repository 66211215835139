import React from "react";
import Content from "../common/Content";

export default (props) => {
  return (
    <>
      <section id="banner" className="OnRichSection">
        <div className="OnRichMain">
          <h3 className="HowTotitle">On Richard Wilhelm</h3>
          <h3 className="HowTotitle">and Lao Naixuan</h3>
        </div>
      </section>

      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortPhera">
            On FreeIching.com, we provide the I Ching translation of Richard
            Wilhelm. But who is Richard Wilhelm? And why we use the translation
            of Richard Wilhelm?
          </div>

          <div className="ShortAboveTitle">
            Marco Polo of the inner Chinese world
          </div>
          <div className="ShortBelowPhera">
            Some describe Richard Wilhelm as the Marco Polo of the inner Chinese
            world. With a good reason. When he was 26 years old, in 1899, he was
            immersed in China only to return to Germany in 1924. During those 25
            years, he came to understand the Chinese language, philosophy, and
            worldview.
          </div>
          <div className="ShortBelowPhera">
            His translation holds a special place in all western of the I Ching.
            By far, it is the most read and used translation and commentary in
            the west, and it was the first translation (in a western language)
            by someone who understood the I Ching’s divination powers.{" "}
          </div>

          <div className="ShortAboveTitle">Lao Naixuan</div>
          <div className="ShortBelowPhera">
            He was taught about the I Ching by a Chinese sage. Lao Naixuan was
            born in 1843, who served as president of three universities in
            pre-communist China. He was a Chinese scholar and yoga teacher with
            deep roots in traditional philosophy.{" "}
          </div>
        </div>
      </section>

      <section className="OnRichBottomImg"></section>

      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortBelowPhera">
            Wilhelm referred to him as his honored teacher and described him as
            an eminent scholar of the old school, one of the last of his kind.
            Richard Wilhelm says in the forward of the I ching about Lao
            Nai-hsuan:
          </div>
          <div className="ShortBelowPhera">
            "I am indebted to him .. he first opened my mind to the wonders of
            the Book of Changes. Under his experienced guidance, I wandered
            entranced through this strange and yet familiar world.’’ {" "}
          </div>

          <div className="ShortAboveTitle">
            From Chinese to German to Chinese
          </div>
          <div className="ShortBelowPhera">
            To be sure that the translation that Richard Wilhelm wrote was
            correct, they translated the German translation back into Chinese.
            When Lao Naixuan found the meaning was indeed not lost in
            translation, they moved to get the translation printed.{" "}
          </div>
          <div className="ShortBelowPhera">
            Shortly after the completion of the translation, Lao Naixuan died in 1921. 
            After Richard Wilhelm left China in 1924,
            China went through sweeping changes. The second world war, the rise
            of the communist party, and the cultural revolution annihilated a
            millennia-old Chinese tradition accessible to Richard Wilhelm. That
            makes Richard Wilhelms' translation unique because it documents the
            I Ching from a lost tradition. In this way, Richard Wilhelms' book
            is one of its kind.
          </div>

          <div className="ShortAboveTitle">Carl Jung</div>
          <div className="ShortBelowPhera">
            When Richard Wilhelm returned to Germany, he became good friends
            with Carl Jung. The famous psychologist wrote the hugely influential
            introduction to the I Ching for the English edition in 1949.
          </div>
          <div className="ShortBelowPhera">
            Carl Jung was very indebted to Wilhelm and said about Richard
            Wilhelm: ‘’Indeed I feel myself so very much enriched by him’’ that it
            seems to me as if I had received more from him than from any other
            man" (ibid., para. 96).
          </div>

          <div className="ShortPhera">
            Richard Wilhelm died relatively young at the age of 56.
          </div>

          <div className="OnRichBg">
            <h3 className="OnRichQuote">
              <em>
                ''Intuition doesn't contradict Rationality, it only goes
                further''{" "}
              </em>
            </h3>
            <h3 className="OnRichQuoteName">(Richard Wilhelm)</h3>
          </div>
        </div>
      </section>

      <Content />
    </>
  );
};
