import React from "react";
import Content from "../common/Content";

export default () => {
  return (
    <>
      <section id="banner" className="LegalNoticesSection">
        <div className="OnRichMain">
          <h3 className="HowTotitle">Support our site</h3>
        </div>
      </section>
      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortPhera">
          Great that you want to support our site. 
          Let us know how you would like to support at freeiching.com@gmail.com. 
          </div>
        </div>
      </section>
      <Content />
    </>
  );
};
