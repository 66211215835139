import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { input, formValidation } from "../fields";

const LoginUI = (props) => {
  const { message } = useSelector((state) => state.authReducer);
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        name="email"
        type="email"
        className="form-control"
        component={input}
        label="E-mail"
        validate={[formValidation.required, formValidation.email]}
        placeholder="Your email address"
      />
      <Field
        name="password"
        type="password"
        className="form-control"
        component={input}
        label="Password"
        validate={formValidation.required}
      />
      {message && <div style={{ color: "#ca1b35" }}> {message} </div>}
      <div>
        {" "}
        <button type="submit" className={clsx({ "loader": props.loader })}> Sign in </button>
      </div>
    </form>
  );
};

export const LoginForm = reduxForm({
  form: "loginForm",
  enableReinitialize: true,
  destroyOnUnmount: true,
  onSubmit: (data, dispatch, props) => {
    props.handleSumbit(data);
  },
})(LoginUI);
