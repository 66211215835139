import React from "react";
import Content from "../common/Content";

export default (props) => {
  return (
    <>
      <section id="banner" className="QuantumPhysicsSection">
        <div className="OnRichMain">
          <h3 className="HowTotitle">
            I Ching and pioneers of Quantum Physics
          </h3>
        </div>
      </section>

      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortPhera">
            The I Ching and Yin and Yang’s concepts have influenced pioneers in
            Quantum Physics like noble price winners Wolfgang Pauli and Niels
            Bohr.
          </div>

          <div className="ShortAboveTitle">
            Wolfgang Pauli, Synchronicity and I Ching
          </div>
          <div className="ShortBelowPhera">
            Wolfgang Pauli, by Einstein in 1946 referred to as his "spiritual
            son," used to consult the I Ching, especially for interpreting his
            dreams. He was most likely introduced to the I Ching by Carl Jung, a
            famous psychologist, who he consulted when facing difficult personal
            times. Later, Carl Jung and Wolfgang Pauli's relationship became a
            close friendship. They discussed together Quantum Physics and the
            relationship between understanding the workings of the Psyche and
            overall reality. In the fascinating book of Arthur Miller called
            ''137'', on the relationship between Pauli and Jung, it notes that
            Pauli considered quantum mechanics incomplete and saw the need for a
            fusion with psychology. Pauli played a role in developing the
            concept of "Synchronicity," a term that Carl Jung phrased to explain
            a wide range of phenomena like consulting the I Ching and other
            ''meaningful coincidences'.
          </div>

          <section className="QuantumImg"></section>

          <div className="ShortAboveTitle">
            Niels Bohr, the principle of Complementarity and the I Ching
          </div>
          <div className="ShortBelowPhera">
            When Niels Bohr was awarded Denmark's highest honor in his homeland,
            he had to create a "coat of arms" (sort of Family Sign). He chose
            the Tai Chi symbol (with Yin and Yang) and the Latin motto contraria
            sunt complementa, "opposites are complementary." A nod to his
            "principle of complementarity," which states that objects (like
            photons) have specific pairs of complementary properties that cannot
            be measured or observed simultaneously. Bohr was sure that
            complementarity went beyond physics and was at the core of all life
            and reality (forming pairs like love and hate, day and night, good
            and evil, etc.).
          </div>
        </div>
      </section>

      <section className="QuantumPhysicsJung">
        <div className="QuantumPhysicsMain">
          <h3 className="HowTotitle">Carl Jung</h3>
        </div>
      </section>

      <Content />
    </>
  );
};
