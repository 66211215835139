import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { setLoader } from "../../store/actions";
import { getTags } from "../../utils";
import { star, radio, formValidation } from "../fields";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";

const CreateUI = (props) => {
  const { handleSubmit } = props;
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    dispatch(setLoader(true));
    getTags().then((data) => {
      setTags(data);
      dispatch(setLoader(false));
    });
  }, []);
  return (
    <section
      className={clsx(
        "wrapper style1 special QuestionBox section-15 question",
        { hidden: isEmpty(tags) }
      )}
    >
      <div className="row m-0 p-l-r-50">
        <div className="question-section">
          <div className="QuestionTab">
            <form onSubmit={handleSubmit} noValidate style={{ float: "left" }}>
              <div className="QuestionTabLeft">
                <Field
                  name="tag"
                  component={radio}
                  label="What is the topic of your reading? :"
                  validate={formValidation.required}
                  options={tags}
                />
              </div>
              <div className="QuestionTabRight">
                <div className="QuestionRight">
                  <div className="QuestionRightText">Importance</div>
                  <div className="QTStarBx">
                    <Field name="importance" component={star} />
                  </div>
                  <div className="QuestionBtText">Clarity</div>
                  <div className="QTStarBx">
                    <Field name="clarity" component={star} />
                  </div>
                </div>
              </div>
              <div className="QTSave">
                <div className="QTSaveRT">
                  <input type="submit" className="QtA" value="Save" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default reduxForm({
  form: "questionCreate",
  enableReinitialize: true,
  destroyOnUnmount: true,
  onSubmit: (data, dispatch, props) => {
    props.handleSumbit(data);
  },
})(CreateUI);
