import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { reducer as formReducer } from "redux-form";
import { firestoreReducer } from "redux-firestore";
import { i18nReducer } from "react-redux-i18n";
import authReducer from "./auth";
import apiStatusReducer from "./apiStatus";
import appReducer from "./app";
import questionReducer from "./question";

export default combineReducers({
  firebaseReducer,
  firestoreReducer,
  form: formReducer,
  i18n: i18nReducer,
  appReducer,
  authReducer,
  apiStatusReducer,
  questionReducer,
});
