import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { input, formValidation } from "../fields";
import { useQuery } from "../../hooks";
import { confirmPasswordReset, verifyPasswordCode } from "../../utils";
const charLenSix = formValidation.minLength(6);

const ResetPasswordUI = (props) => {
  const { message } = useSelector((state) => state.authReducer);
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        name="email"
        type="email"
        className="form-control"
        component={input}
        label="E-mail"
        disabled={true}
        validate={[formValidation.required, formValidation.email]}
        placeholder="Your email address"
      />
      <Field
        name="password"
        type="password"
        className="form-control"
        component={input}
        label="New Password"
        validate={[formValidation.required, charLenSix]}
      />
      {message && <div style={{ color: "#ca1b35" }}> {message} </div>}
      <div>
        {" "}
        <button type="submit" disabled={props.loader} className={clsx({ "loader": props.loader })}> Reset Password </button>
      </div>
    </form>
  );
};

const ResetPasswordForm = reduxForm({
  form: "ResetPasswordForm",
  enableReinitialize: true,
  destroyOnUnmount: true,
  onSubmit: (data, dispatch, props) => {
    props.handleSubmitForm(data);
  },
})(ResetPasswordUI);

export function ResetPassword() {
  const query = useQuery();
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  useEffect(() => {
    verifyPasswordCode(query.get("oobCode")).then((email) => {
      setFormState({ ...formState, email })
    }).catch(err => {
      setFormState({ ...formState, email: "harpreet.singh1486@gmail.com" })
      console.log("Reset Password Error >> ", err)
      setError("Reset Password link is expired.")
    })
  }, [])

  const resetPassword = (data) => {
    setLoading(true)
    confirmPasswordReset(query.get("oobCode"), data.password)
      .then(value => {
        setLoading(false);
        setSuccess("You can now sign in with your new password.")
      }).catch(err => {
        setLoading(false)
        setError(err.message)
      })
  }
  return (
    <div className="overlay-popup">
      <div className="overlay-pop-box">
        <Link to="/" className="CloseRight">
          <img src="/images/closeright.png" alt="X" />
        </Link>
        <div className="overlay-pop-left">
          <Link to="/" className="pop-img">
            <img src="/images/pop-logo.png" alt="" />
          </Link>
          {
            <div className="iching-inks">
              <span>
                {`By logging into Freeiching.com`}, you agree to our{" "}
                <Link
                  target="_blank"
                  style={{ color: "inherit" }}
                  className="external-link"
                  to="/terms"
                >
                  {" "}
                  Terms of use
                </Link>{" "}
                and{" "}
                <Link target="_blank" style={{ color: "inherit" }} to="/privacy-policy">
                  Privacy Policy
                </Link>
                .
              </span>
            </div>
          }
        </div>
        <div className="overlay-pop-middle login">
          <h2>Reset Password</h2>
          {!error && !success && <ResetPasswordForm initialValues={formState} handleSubmitForm={resetPassword} loader={loading} />}
          <div class="custom-error">{error}</div>
          <div class="success" style= {{color: "#374c5e", textAlign: "center"}}>{success}</div>
        </div>
      </div>
    </div>
  )
}