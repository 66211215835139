import React from "react";
import { Link } from "react-router-dom";
import IChingInfo from "../common/IChingInfo";
import { get } from "lodash";
import { useSelector } from "react-redux";

export default () => {
  const [auth] = useSelector((state) => [state.authReducer]);
  const isAnon = get(auth, "user.isAnonymous");

  return (
    <>
      <section className="wrapper alt section-9 style2">
        <div className="row m-0 p-l-r-50">
          <div className="BoxBg1 m-l-0 box-1 bg-box">
            <div className="box-content">
              <h4>Lao Naixuan (1843-1921)</h4>
              <p>
                ‘Lao Naixuan, he first opened my mind to the wonders of the Book
                of Changes.’ Richard Wilhelm
              </p>
            </div>
            <div className="box4-bottom">
              <div className="box4-btn box4-btn-img">
                <Link to="/moreoniching/onrichardwilhelm" className="theme-btn">
                  Read more
                </Link>
                <i className="fa fa-angle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper alt section-11 style2">
        <div className="row m-0 p-l-r-30">
          <div className="middle-box">
            <div className="MiddleLeft">
              <img
                className="HexagramImg"
                src="/images/IChingHexagram.png"
                alt=""
              />
            </div>
            <div className="MiddleRight">
              <h3 className="HexaTitle">I Ching Hexagrams</h3>
              <p className="HexaContent">
                I Ching is one of the oldest books of Ancient China. It contains
                64 hexagrams and texts on how to understand them in the case of
                divination. Find links to all of the hexagrams and their
                divinatory text.{" "}
                <Link to="/hexagram" className="HexaLink">
                  Read more...
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper alt section-10 style2">
        <div className="row m-0 p-l-r-30">
          <div className="gui-box">
            <div className="gui-box-center">
              <div className="GuiLeft">
                <h3 className="GuiTitle">I Ching and Binary Code</h3>
                <p className="GuiContent">
                  A binary code represents data using a two-symbol system. The
                  core elements of the I Ching binary code are known as 'Yin'
                  and 'Yang.’ Your computer uses another famous two-symbol
                  system, the binary number system, which doesn't use 'yin' and
                  'yang' but uses "0" and "1".{" "}
                  <Link to="/moreoniching/ichingbinarycode" className="GuiLink">
                    Read more...
                  </Link>{" "}
                </p>
              </div>
              <div className="GuiRight">
                <img
                  className="GuiImg"
                  src="/images/IChingBinaryCode.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {isAnon && <IChingInfo />}
      <section className="wrapper alt section-12 style2">
        <div className="row m-0 p-l-r-30">
          <div className="gui-box Gua">
            <div className="gui-box-center">
              <div className="GuiLeft">
                <img
                  className="GuiImg"
                  alt=""
                  src="/images/IChingKabbalah.png"
                />
              </div>
              <div className="GuiRight">
                <h3 className="GuiTitle">
                  I Ching & Kabbalah, a curious link.
                </h3>
                <p className="GuiContent">
                  The I Ching and the Kabbalah's (Jewish Mysticism) school of
                  thought come from different parts of the world and very
                  different cultural backgrounds. Still, you can notice some
                  fascinating similarities within those schools of thought.{" "}
                  <Link
                  to="/moreoniching/ichingcuriouslinks"
                  className="HexaLink"
                >
                  Read more...
                </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper alt section-13 style2">
        <div className="row m-0 p-l-r-30">
          <div className="gui-box tri FullBoxBg">
            <div className="HomeHexaBox">
              <div className="GuiLeft">
                <h3 className="GuiTitle">
                  The concept of ''Yin'' and ''Yang''
                </h3>
                <p className="GuiContent">
                  A hexagram consist of 6 lines, the lines either have the
                  quality of ''Yin, when the line is broken'' or ''Yang, when
                  the line is solid''. To get a better understandig of the
                  meaning of the concepts of ''Yin'' or ''Yang'', let's take a
                  look at what the I Ching have to say about the hexagrams that
                  only have ''Yang'' lines and ''Yin'' lines.
                </p>
                <div className="HomeBoxBtnMain">
                  <Link
                    to="/moreoniching/onrichardwilhelm"
                    className="HomeBoxBtn"
                  >
                    Read more
                  </Link>
                  <i className="fa fa-angle-right BoxBtnRightarrow"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="jungImgSection">
        <div className="jungMain">
          <h3 className="jungtitle">Carl Jung often consulted</h3>
          <h3 className="jungtitle">the I Ching himself</h3>
        </div>
      </section>
      {/* <section className="wrapper alt section-14 style2">
    <div className="row m-0 p-l-r-50">
      <div className="BoxBg1 m-l-0 box-1 bg-box btm-bg">
        <div className="box-content">
          <p>Order a cool iChing-shirt and be part of the iChing family.</p>
        </div>
        <div className="box4-bottom">
          <div className="box4-btn">
            <Link to="/" className="theme-btn">Take a look</Link>
            <i className="fa fa-angle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </section> */}
    </>
  );
};
