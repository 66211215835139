import React from "react";
import Content from "../common/Content";

export default (props) => {
  return (
    <>
      <section id="banner" className="HowToSection">
        <div className="MoreOnMain">
          <h3 className="HowTotitle">Carl Jung often consulted</h3>
          <h3 className="HowTotitle">the I Ching himself</h3>
        </div>
      </section>
      <section id="" className="MoreOnBottom">
        <div className="HowToInner">
          <h3 className="HowToTitle">How to consult the I Ching</h3>
          <div className="HowToText">
          The only thing you need to do to consult the I Ching is concentrate 
          on your question before you consult the I Ching. The more you are personally 
          connected and emotionally involved, the more precise the I Ching answer will be.
          </div>
          <div className="HowToTextPhera">
          The I Ching is not only consulted by asking questions but also just by 
          focusing on a wish or a preferred potential outcome. Feel free to experiment
          in the way you want to address the I Ching. But if you do it through a 
          question, make sure that the question is as straightforward as possible. 
          Some people prefer to write the question down, but that is not required.{" "}
          </div>
          <div className="HowToTextPhera">
          Make sure your mind and your inner self align themselves with the question. 
          The I Ching will always reflect your true inner self more than how your mind
          formulates the problem.{" "}
          </div>
          <div className="howToBg">
            <h3 className="HowToboxTitle">
              The I Ching does warn about asking the same question over and
              over, in hexagram 4. It says:{" "}
            </h3>
            <h3 className="HowToboxTitleLine">
              “At the first divination I give information.{" "}
            </h3>
            <h3 className="HowToboxTitleLine">
              If he asks two or three times, it is harassment.
            </h3>
            <h3 className="HowToboxTitleLine">
              If he harasses, I do not give any information.”
            </h3>
          </div>
          <div className="HowToTextPhera">
            In traditional China, the preferred used methods were consulting the
            I Ching by an elaborate process using Yarrow Stalks. The other way
            was by using three coins and throwing them six times. We operate at 
            FreeIChing a specific method (algorithm using random number generators) 
            to generate the answers based on those traditional methods.{" "}
          </div>
          <div className="HowToTextPhera">
          In the settings, you can define your preferred method. For instance, 
          you can use the coins method, the yarrow method, and a method that 
          generates only one moving line. 
          </div>
          <div className="HowToTextPhera">
          But how is it possible that this works? How can you get a meaningful answer
          while using a random process, either by throwing coins or starting an algorithm?
          The truth is, we do not understand precisely how it works, but the old thinkers
          of the past saw that it did. The I Ching says (about its working):
          </div>
          <i className="HowToTextPhera">
            "The Changes, what do they do? The Changes disclose things, whole
            affairs, and encompass all ways on earth -this and nothing else. For
            this reason, the old diviners of the past used them to penetrate all
            wills on earth. The purpose was to determine all fields of action on
            earth and settle all doubts on earth."
          </i>
          <div className="HowToTextPhera">
          Carl Jung poised the term 'synchronicity,' which is defined as the occurrence
          of meaningful coincidences. Events are connected by meaning. Carl Jung often
          consulted the I Ching himself, and 'synchronicity' describes perfectly the
          process at work when we consult the I Ching.
          </div>
        </div>
      </section>
      <Content />
    </>
  );
};
