import { clone, set } from "lodash";
import { getMethodData, setUserSettings, getUserSettings } from "../../utils";
import { APP_SETTING_SET } from "../../store/actions/actionTypes";

export const getCardByMethod = (obj, settings) => async (dispatch, store) => {
  await new Promise((resolve, reject) => {
    const clear = setInterval(() => {
      const { authReducer } = store();
      if (authReducer.user) {
        resolve();
        clearInterval(clear);
        console.log("ok");
      }
    }, 200);
  });

  dispatch(newQuery(obj));
  const mData = await getMethodData(obj, settings);
  dispatch(updateQuery({ card: mData.data }));
  dispatch({
    type: "QUESTION_SET_DATA",
    payload: mData,
  });
};

export const clearQuestion = () => async (dispatch) => {
  dispatch(clearQuery());
  document.body.classList.remove("white-nav");
  document.body.classList.remove("page-4");
  dispatch({
    type: "QUESTION_CLEAR",
  });
};

export const newQuery = (obj) => (dispatch) => {
  dispatch({
    type: "NEW_QUERY",
    payload: obj,
  });
};

export const updateQuery = (obj) => (dispatch) => {
  dispatch({
    type: "UPDATE_QUERY",
    payload: obj,
  });
};

export const clearQuery = () => (dispatch) => {
  dispatch({
    type: "CLEAR_QUERY",
  });
};

export const changeText = () => async (dispatch, store) => {
  const storeState = store();
  const {
    appReducer: { defaultSetting },
    questionReducer: { question },
  } = storeState;
  const { user } = storeState.authReducer;
  const cloneSetting = clone(defaultSetting);
  set(cloneSetting, "ChangeTextToggle", !cloneSetting.ChangeTextToggle);
  await setUserSettings(user.uid, cloneSetting);
  const updateSetting = await getUserSettings(user.uid);
  dispatch({
    type: APP_SETTING_SET,
    payload: updateSetting,
  });
  dispatch(getCardByMethod(question));
};
