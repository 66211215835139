import React from "react";
import Content from "../common/Content";

export default (props) => {
  return (
    <>
      <section id="" className="MethodSection">
        <div className="MethodMain">
          <h3 className="MethodBanner">
            What method to use? Coins, Yarrow or Oneline?
          </h3>
        </div>
      </section>
      <section id="" className="RealExampleBottom">
        <div className="HowToInner">
          <div className="MethodPhera">
          We use different methodologies to consult the I Ching.
          You can select your preferred method in the settings. {" "}
          </div>
          <div className="MethodAboveTitle">Yarrow and Coins methods</div>
          <div className="MethodBelowPhera">
          Two methods to consult the I Ching are well known: being the Coin and the Yarrow Stalk method. 
          There is some debate over which method is the oldest one, but the most likely older way of 
          divining is through the Yarrow stalk method. Still, many things cloud in mystery; 
          it is hard to say how anyone consulted the I Ching before 350 BCE. 
          There are no clear archeological findings or scriptures that shed light on this. Interestingly, 
          all the recordings of early divination in China (in the Zuo Zhuan / 300 BCE) have no more than 
          one changing line. That bears the strong likelihood that methods were used in the past that 
          generated not more than one moving line.{" "}
          </div>
          <div className="MethodAboveTitle">Oneline</div>
          <div className="MethodBelowPhera">
          For this reason, we have also implemented an ''OneLine'' method as an alternative method 
          to consult. Especially if you are new to the I Ching, we advise you to start with this method.{" "}
          </div>
        </div>
      </section>
      <section id="" className="MethodBottomBg">
        <div className="MoreInInner">
          <div className="MoreInLeft">
            <img
              className="realexmImg"
              src={`../../images/bookofiching.png`}
              alt=""
            />
          </div>
          <div className="MoreInRight">
            <div className="MoreInTitle">More information</div>
            <div className="MoreInText">
              For more information about the methods used to consult the I Ching
              (including the "not more than one line"), find the excellent
              introduction on the I Ching by Lars Bo Christensen in his book:
              'Book of Changes – The original Core of the I Ching."
            </div>
          </div>
        </div>
      </section>
      <Content />
    </>
  );
};
