export const HexaReference = {
  777777: "1",
  888888: "2",
  788878: "3",
  878887: "4",
  777878: "5",
  878777: "6",
  878888: "7",
  888878: "8",
  777877: "9",
  778777: "10",
  777888: "11",
  888777: "12",
  787777: "13",
  777787: "14",
  887888: "15",
  888788: "16",
  788778: "17",
  877887: "18",
  778888: "19",
  888877: "20",
  788787: "21",
  787887: "22",
  888887: "23",
  788888: "24",
  788777: "25",
  777887: "26",
  788887: "27",
  877778: "28",
  878878: "29",
  787787: "30",
  887778: "31",
  877788: "32",
  887777: "33",
  777788: "34",
  888787: "35",
  787888: "36",
  787877: "37",
  778787: "38",
  887878: "39",
  878788: "40",
  778887: "41",
  788877: "42",
  777778: "43",
  877777: "44",
  888778: "45",
  877888: "46",
  878778: "47",
  877878: "48",
  787778: "49",
  877787: "50",
  788788: "51",
  887887: "52",
  887877: "53",
  778788: "54",
  787788: "55",
  887787: "56",
  877877: "57",
  778778: "58",
  878877: "59",
  778878: "60",
  778877: "61",
  887788: "62",
  787878: "63",
  878787: "64",
};
