import React from "react";
import Content from "../common/Content";

export default (props) => {
  return (
    <>
      <section id="banner" className="CuriousSection">
        <div className="OnRichMain">
          <h3 className="HowTotitle">I Ching & Kabbalah, a curious link</h3>
        </div>
      </section>

      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortAboveTitle">
            What the Kabbalah, Jewish Mysticism, and the I Ching have in common?
          </div>
          <div className="ShortBelowPhera">
            The I Ching and the Kabbalah's (Jewish Mysticism) school of thought
            come from different parts of the world and very different cultural
            backgrounds. Still, you can notice some fascinating similarities
            within those schools of thought. The best explanation is to compare
            the Kabbalist Tree of Life diagram and the Supreme Ultimate Diagram
            (Taijitu Shuo).{" "}
          </div>

          <section className="KabbalahCuriousImg"></section>

          <div className="ShortAboveTitle">
            So what are some core similarities?
          </div>

          <div className="ShortAboveTitle">One Source</div>
          <div className="ShortBelowPhera">
            Both traditions speak of the infinite, the unknowable, the source of
            everything, and something you cannot describe, only referred to as
            'without limits.' In the images, you see it represented at the top
            of both visual diagrams. In Chinese thought, this is called the
            'Wuhi,' and in Kabbalistic tradition, this is called 'Kether.'{" "}
          </div>

          <div className="ShortAboveTitle">Opposites</div>
          <div className="ShortBelowPhera">
            Like the I Ching, the kabbalistic thought also works with polarity
            or opposites. In the I Ching, the concept of Yang is related to
            energy, action, and force. That is very similar to the idea of
            Chokmah in Kabbalistic tradition, which is associated with
            outflowing boundless energy. The concept of Yin in the I Ching is
            related to receptiveness. Without this principle, Yang cannot
            manifest itself. As such, it is very similar to the concept of Binah
            in the Kabbalistic Tradition. The description of Binah is 'the Womb
            that gives shape to the Spirit of God.' In the visual Diagrams of
            Taijitu Shuo and the Tree of Life, you can see these aspects
            directly under the upper part (the 'infinite' of the diagrams.){" "}
          </div>

          <div className="ShortAboveTitle">
            The (continuous) process of Creation
          </div>
          <div className="ShortBelowPhera">
            If you read the visual diagrams from top to down, you can recognize
            a process from the 'infinite' to actual manifestation in the world.
            In the Tree of Life, the Diagram below is called 'Malkhut,’ which
            relates to the physical world where we live. The low part of the
            Taijitu diagram also is referred to as 'the myriad of things.' Both
            Schools of Thought show an innate relationship between 'The
            infinite' and 'The Finite.'{" "}
          </div>

          <div className="ShortAboveTitle">I Ching school of thought?</div>
          <div className="ShortBelowPhera">
            The I Ching school of thought refers here to the Confucian and
            Daoist schools of thought that are strongly influenced by the I
            Ching. In this case, we used the Taijitu Shuo, a picture in the
            Neo-Confucian tradition. Confucian and Daoist traditions are both
            highly influenced by the I Ching and the original Zhouyi document.{" "}
          </div>
        </div>
      </section>

      <Content />
    </>
  );
};
