import React, { useEffect, useState } from "react";
import { get, range, map, split, max, includes } from "lodash";
import Content from "./common/Content";
import { useClassBasedOnRoute } from "../hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  HexaReference,
  getKeyByValue,
  decode,
  querParse,
  isChingMethod,
} from "../utils";
import { clearQuestion, getCardByMethod } from "../store/actions/card";

const cardEngNums = ["first", "second"];

export default function Show(props) {
  const dispatch = useDispatch();
  const [data, app] = useSelector((state) => [
    state.questionReducer.question,
    state.appReducer,
  ]);
  const [titleHeight, setTitleHeight] = useState(0);

  useClassBasedOnRoute(["page-5", "white-nav"]);
  useEffect(() => {
    function handleResize() {
      setTitleHeight(null);
      setTimeout(() => {
        const arr = [];
        const elements = document.querySelectorAll(
          ".HexaLeftImage .hex-1-title, .HexaRightImage .hex-1-title"
        );
        elements.forEach((element) => arr.push(element.offsetHeight));
        setTitleHeight(max(arr));
      }, 0);
    }
    dispatch(
      getCardByMethod(decode(querParse(props.location.search, "query")))
    );
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
      dispatch(clearQuestion());
    };
  }, []);

  useEffect(() => {
    if (data) {
      setTitleHeight(null);
      setTimeout(() => {
        const arr = [];
        const elements = document.querySelectorAll(
          ".HexaLeftImage .hex-1-title, .HexaRightImage .hex-1-title"
        );
        elements.forEach((element) => arr.push(element.offsetHeight));
        setTitleHeight(max(arr));
      }, 0);
    }
  }, [data]);

  return (
    <>
      <section id="banner">
        {data && (
          <div className="inner">
            <div className="banner-top-question">
              {data.question && (
                <>
                  <span style={{ color: "#374C5E" }}>Question:</span>{" "}
                  {data.question}
                </>
              )}
            </div>
            <div className="Banner_Box">
              <div className="BannerLeftImage">
                <div className="hexa-top-title">Reading Hexagram</div>
                <div className="hexa-box-bg">
                  <img
                    className="hexa-content"
                    src={`/images/cards/${get(data, "data[0].Number")}.png`}
                    alt=""
                  />
                  <div className="hexa-chine">
                    <img
                      src={`/images/chinese/whites/${get(
                        data,
                        "data[0].Number"
                      )}.svg`}
                      alt=""
                    />
                  </div>
                  <div className="hexa-top-title BanneAns">
                    {get(data, "data[0].Translation.title")}
                  </div>
                </div>
              </div>
              <div className="Hexa_center">
                <div className="hexa-middle-title">The changes</div>
                <div className="HexaLeftImage">
                  <span className="hex-1-title" style={{ height: titleHeight }}>
                    {get(data, "data[0].Translation.title")}
                  </span>
                  <img
                    src={`/images/cards/${get(data, "data[0].Number")}.png`}
                    alt=""
                  />
                  <span className="hex-1-bottom">
                    nr. {get(data, "data[0].Number")}
                  </span>
                </div>
                <div className="HexaRightImage">
                  <span className="hex-1-title" style={{ height: titleHeight }}>
                    {get(data, "data[1].Translation.title")}
                  </span>
                  <img
                    src={`/images/cards/${get(data, "data[1].Number")}.png`}
                    alt=""
                  />
                  <span className="hex-1-bottom">
                    nr. {get(data, "data[1].Number")}
                  </span>
                </div>
              </div>
              <div className="HexaRightText">
                <div
                  className="hexa-right-top-title"
                  style={{ height: titleHeight }}
                >
                  Moving Lines
                </div>
                <span className="hex-1-right banner-content-color-1">
                  {range(6, 0).map((i, index) => (
                    <React.Fragment key={`index-moving-line-${index}`}>
                      {!isChingMethod(app, "Traditional") && (
                        <>
                          {i}{" "}
                          {includes(data.random.movingLines, i) &&
                            (split(
                              getKeyByValue(
                                HexaReference,
                                String(data.random.cards[0])
                              ),
                              ""
                            )[5 - index] === "8"
                              ? "Moving yin"
                              : "Moving yang")}{" "}
                          <br />
                        </>
                      )}
                      {isChingMethod(app, "Traditional") && (
                        <>
                          {i}{" "}
                          {data.random.movingLines[0] === i &&
                            (split(
                              getKeyByValue(
                                HexaReference,
                                String(data.random.cards[0])
                              ),
                              ""
                            )[data.random.movingLines[0] - 1] === "8"
                              ? "Moving yin"
                              : "Moving yang")}{" "}
                          <br />
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </span>
              </div>
            </div>
          </div>
        )}
        {/* <Link to="/" onClick={(e) => goToElement(e, "#one")} className="more scrolly">
          <img src="/images/down.png" alt="" />
        </Link> */}
      </section>

      {map(get(data, "data", []), (card, index) => (
        <React.Fragment key={`card-${index}`}>
          <section className="wrapper style1 special section-15 meaning">
            <div className="row m-0 p-l-r-50">
              <div className="hexa-meaning">
                Meaning of the {cardEngNums[index]} Hexagram
              </div>
              <div className="HexaMeaningLeft HexaLeftMobile">
                <div className="meaning-box">
                  <h4 className="HexaLanguage">
                    <img
                      src={`/images/chinese/blacks/${get(
                        data,
                        "data[0].Number"
                      )}.svg`}
                      alt=""
                    />
                  </h4>
                  <h4 className="HexaMeanTitle">
                    {get(card, "Translation.title")} (
                    {get(card, "InternalName")})
                  </h4>
                  {index !== 0 && (
                    <p className="HexaMeancontent">
                      {get(card, "Translation.iChingJudgement")}
                    </p>
                  )}
                </div>
              </div>
              <div className="HexaMeaningRight HexaRightMobile">
                <div className="meaning-box-right">
                  <h4 className="HexaMeaningRightTitle">
                    {get(card, "Translation.commentDescription")}
                  </h4>
                  <p className="HexaMeaningRightContent">
                    {get(card, "Translation.iChingHexagramTitle")}
                  </p>
                </div>
              </div>
            </div>
          </section>
          {index === 0 && (
            <>
              <section className="wrapper style1 special section-16">
                <div className="row m-0 p-l-r-50">
                  <div className="HexaMeaningLeft HexaLeftMobile">
                    <div className="meaning-box">
                      <h4 className="HexaMeanTitle top-space">The Judgment</h4>
                      <p className="HexaMeancontent">
                        {get(card, "Translation.iChingJudgement")}
                      </p>
                    </div>
                  </div>
                  <div className="HexaMeaningRight HexaRightMobile">
                    <div className="meaning-box-right">
                      <h4 className="HexaMeaningRightTitle">
                        {get(card, "Translation.iChingJudgementDescription")}
                      </h4>
                      <p className="HexaMeaningRightContent">
                        {get(card, "Translation.iChingJudgementText")}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section className="wrapper special section-16 section-16-bg">
                <div className="row m-0 p-l-r-50">
                  <div className="HexaMeaningLeft HexaLeftMobile">
                    <div className="meaning-box">
                      <h4 className="HexaMeanTitle top-space">The Image</h4>
                      <p className="HexaMeancontent">
                        {get(card, "Translation.iChingImage")}
                      </p>
                    </div>
                  </div>
                  <div className="HexaMeaningRight HexaRightMobile">
                    <div className="meaning-box-right">
                      <h4 className="HexaMeaningRightTitle">
                        {get(card, "Translation.iChingImageDescription")}
                      </h4>
                      <p className="HexaMeaningRightContent">
                        {get(card, "Translation.iChingImageText")}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              {map(
                get(card, "Translation.iChingLines", []),
                (line, lineIndex) => (
                  <React.Fragment key={`iching-${index}-${lineIndex}`}>
                    <section className="wrapper special section-16 section-16-bg">
                      <div className="row m-0 p-l-r-50">
                        <div className="HexaMeaningLeft HexaLeftMobile">
                          <div className="meaning-box">
                            <h4 className="HexaMeanTitle top-space">
                              I Ching text line {get(line, "lineNumber")}:
                            </h4>
                            <img
                              className="hexa-left-img"
                              src={`/images/lines/${get(
                                line,
                                "lineNumber"
                              )}.png`}
                              alt="Iching Line"
                            />
                            <p className="LeftSpace">
                              {get(line, "lineIChing")}
                            </p>
                          </div>
                        </div>
                        <div className="HexaMeaningRight HexaRightMobile">
                          <div className="meaning-box-right">
                            <h4 className="HexaMeaningRightTitle">
                              {get(card, "Translation.iChingLinesDescription")}
                            </h4>
                            <p className="HexaMeaningRightContent">
                              {get(line, "lineCommentary")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </React.Fragment>
                )
              )}
            </>
          )}
        </React.Fragment>
      ))}
      <Content />
    </>
  );
}
