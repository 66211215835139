import React from "react";
import Content from "../common/Content";

export default (props) => {
  return (
    <>
      <section id="banner" className="BinaryCodeSection">
        <div className="OnRichMain">
          <h3 className="HowTotitle">I Ching and Binary Code</h3>
        </div>
      </section>

      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortPhera">
            A binary code represents data using a two-symbol system. The core
            elements of the I Ching binary code are known as 'Yin' and 'Yang.’{" "}
          </div>
          <div className="ShortBelowPhera">
            Your computer uses another famous two-symbol system, the binary
            number system, which doesn't use 'yin' and 'yang' but uses "0" and
            "1".{" "}
          </div>

          <section className="BinaryImg"></section>

          <div className="ShortAboveTitle">Gottfried Leibniz</div>
          <div className="ShortBelowPhera">
            The binary number system was invented by Gottfried Leibniz (a famous
            German mathematician and natural philosopher) in 1689. Leibniz
            himself was influenced by the I Ching and saw the parallels between
            his work and the I Ching.
          </div>
          <div className="ShortBelowPhera">
            His famous article that describes the binary number system refers to
            Fu Xi (a legendary figure who claimed to be the founder of Chinese
            Culture) and the I Ching. In that article, he refers to the I Ching
            saying this to be 'perhaps the most ancient monument of science in
            the world.' He also believed his new arithmetic (of ''0'' and ''1'')
            to enclose the I Ching mystery.{" "}
          </div>
          <div className="ShortBelowPhera">
            Interestingly, the I Ching's inner working is closely linked to our
            current digital revolution because our computers work with the
            binary number system Leibniz created, inspired by the I Ching.{" "}
          </div>

          <div className="ShortAboveTitle">
            Archetypical possibilities of human life
          </div>
          <div className="ShortBelowPhera">
            But as Damien Walter, in an article in the Guardian, points out
            nicely, the I Ching is more ambitious than being a tool for
            technological advancements. The I Ching describes our archetypical
            possibilities of human life. Damien points out that probably the I
            Ching's ancient writers might have understood the powers behind the
            binary code better than we do in our current time.{" "}
          </div>

          <div className="ShortAboveTitle">
            {" "}
            I Ching shows the essence of the changes
          </div>
          <div className="ShortBelowPhera">
            The binary code can create endless worlds full of change and ups and
            downs, but the I Ching shows the essence of the changes. As a
            divination manual, you will find the I Ching uncovers the
            archetypical patterns of change and how to act based on the
            (archetypical) situation.
          </div>

          <div className="ShortPhera">Links:</div>
          <div className="ShortBelowPhera">
            Find the article on the Binary Number System of Leibniz here:{" "}
            <a className="five" href="http://www.leibniz-translations.com/binary.htm.">
              http://www.leibniz-translations.com/binary.htm.
            </a>
          </div>

          <div className="ShortPhera">
            An interesting TedTalk about binary code you can find here:{" "}
            <a className="five" href="https://www.youtube.com/watch?v=wgbV6DLVezo&list=PLhIPqCMVWP9kJXZbzU25JmDwZlI93VmD1&index=1">
              https://www.youtube.com/watch?v=wgbV6DLVezo&list=PLhIPqCMVWP9kJXZbzU25JmDwZlI93VmD1&index=1
            </a>
          </div>

          <div className="ShortPhera">
            The inspiring article about the I Ching and Binary code (and science
            fiction) of Damien Walter in the Guardian you can find here: <br></br>
            <a className="five" href="https://www.theguardian.com/books/2014/mar/21/ancient-book-wisdom-i-ching-computer-binary-code">
              https://www.theguardian.com/books/2014/mar/21/ancient-book-wisdom-i-ching-computer-binary-code
            </a>
          </div>
        </div>
      </section>

      <Content />
    </>
  );
};
