import { get, omit } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { closeMailModal } from "../../store/actions";
import { encode, mail } from "../../utils";
import { formValidation } from "../fields";
import CustomLink from "./CustomLink";

const MailUI = (props) => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.appReducer);
  const { handleSubmit } = props;

  if (!app.modal.isMailModalOpen) {
    return null;
  }

  return (
    <div className="overlay-popup text-center Mopup-Screen-Main">
      <form onSubmit={handleSubmit} noValidate>
        <div className="overlay-pop-box share popup-main">
          <CustomLink
            to="/"
            className="CloseRight pop-close-right"
            onClick={() => dispatch(closeMailModal())}
          >
            <img src="/images/closeright.png" alt="X" />
          </CustomLink>
          <div className="overlay-pop-left">&nbsp;</div>
          <div className="overlay-pop-middle popup-main-middle">
            <div className="pop-centre-popup">
              Sent this reading to yourself
            </div>
            <div className="popup-comment-box">
              <Field
                name="message"
                component="textarea"
                rows="4"
                cols="50"
                validate={formValidation.required}
                className="comment-popup form-control"
                placeholder="Add some comments.."
              />
            </div>
            <div className="popup-comment-button">
              <button type="submit" className="pop-comment-button">
                Send
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const MailForm = reduxForm({
  form: "MailUI",
  enableReinitialize: true,
  destroyOnUnmount: true,
  onSubmit: (data, dispatch, props) => {
    props.handleSumbit(data);
  },
})(MailUI);

const AfterMailUI = () => {
  const dispatch = useDispatch();
  return (
    <div className="overlay-popup text-center Mopup-Screen-Main">
      <div className="overlay-pop-box share popup-main">
        <CustomLink
          to="/"
          className="CloseRight pop-close-right"
          onClick={() => dispatch(closeMailModal())}
        >
          <img src="/images/closeright.png" alt="X" />
        </CustomLink>
        <div className="overlay-pop-left">&nbsp;</div>
        <div className="overlay-pop-middle popup-main-middle">
          <div className="pop-centre-popup">
            Great! Your mail has been sent.
          </div>
          <div className="pop-centre-popup1" style={{ fontWeight: "normal" }}>
            You can also save your readings in "My IChing"
          </div>
          <img
            className="pop-image"
            src="/images/iching.png"
            alt="IChing"
            style={{ maxWidth: "200px", opacity: "0.2" }}
          />
          <div className="pop-centre-popup2" style={{ fontWeight: "normal" }}>
            Tip: try the different texts for more insight
          </div>
        </div>
      </div>
    </div>
  );
};

export default function () {
  const [view, setView] = useState("FORM");
  const auth = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  // const [question] = useSelector((state) => [state.questionReducer.question]);
  const shareUrl = window.location.href;
  // `${window.location.origin}/reading?query=${encode(
  //   omit(question, "data")
  // )}`;
  const data = { message: "" };

  const handleFormSubmit = (data) => {
    setView("AFTERUI");
    const obj = {
      to: auth.user.email,
      subject: "FreeIching Reading",
      message: data.message,
      url: shareUrl,
      name: get(auth, "user.displayName"),
      userId: get(auth, "user.uid")
    };
    mail(obj);
    setTimeout(() => {
      dispatch(closeMailModal());
    }, 5000);
  };

  if (view === "FORM") {
    return <MailForm handleSumbit={handleFormSubmit} initialValues={data} />;
  } else {
    return <AfterMailUI />;
  }
}
