import React from "react";
import { Link } from "react-router-dom";

export default ({ children, onClick, to, ...props }) => {
  const customClick = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <Link to={to || "/"} {...props} onClick={customClick}>
      {children}
    </Link>
  );
};
