import {
  random,
  inRange,
  range,
  filter,
  map,
  join,
  keys,
  includes,
} from "lodash";
import { HexaReference } from "./hexaReference";

const randomNumberUpTo16 = () => random(1, 16);

const yarrowReferenceList = (number) => {
  if (inRange(number, 1, 2)) {
    return 6;
  } else if (inRange(number, 2, 9)) {
    return 8;
  } else if (inRange(number, 9, 12)) {
    return 9;
  } else if (inRange(number, 12, 17)) {
    return 7;
  }
};

const coinsReferenceList = (number) => {
  if (inRange(number, 1, 3)) {
    return 6;
  } else if (inRange(number, 3, 10)) {
    return 8;
  } else if (inRange(number, 10, 12)) {
    return 9;
  } else if (inRange(number, 12, 17)) {
    return 7;
  }
};

export const sixDigitNumberUpTo16 = () => map(range(6), randomNumberUpTo16);

export const yarrowStackRef = (arr) => join(map(arr, yarrowReferenceList), "");

export const coinStalkRef = (arr) => join(map(arr, coinsReferenceList), "");

const cardRef = (number) => {
  let card = HexaReference[number];
  if (card) {
    return card;
  } else {
    console.error(number);
  }
};

export const card1 = (hexagram) =>
  cardRef(hexagram.replace(/9/g, "7").replace(/6/g, "8"));

export const card2 = (str) =>
  cardRef(str.replace(/6/g, "7").replace(/9/g, "8"));

export const movingLines = (arr) =>
  map(
    filter(keys(arr), (i) => arr[i] === "6" || arr[i] === "9"),
    (i) => +i + 1
  );

export const movingLineFilter = (obj, movingLines) => {
  if (!obj) {
    console.error("Unexceptionable Value");
    return null;
  }
  obj.Translation.iChingLines = obj.Translation.iChingLines.filter((item) =>
    includes(movingLines, item.lineNumber)
  );
  return obj;
};

export const movingLinesFilter = (obj, movingLines) =>
  obj.map((item) => movingLineFilter(item, movingLines));
