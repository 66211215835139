import React from "react";
import Content from "../common/Content";

export default () => {
  return (
    <>
      <section id="banner" className="LegalNoticesSection">
        <div className="OnRichMain">
          <h3 className="HowTotitle">Legal Notices</h3>
        </div>
      </section>
      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortPhera">
            We are no legal experts here at 'FreeIChing.com'. We created our
            legal notices with an online tool called 'websitepolicies.com'.
            Assuming that you are also not a legal expert, let us lay out the
            main philosophy at 'FreeIChing.com':
          </div>
          <div className="ShortPhera">
            1. We do not sell your personal information to anyone or bother you
            with any promotion or marketing emails.{" "}
          </div>
          <div className="ShortBelowPhera">
            2. You can always delete your account and all your personal
            information attached (which is limited to the information you can
            see yourself in the website/ application).
          </div>
          <div className="ShortBelowPhera">
            3. Although we believe I Ching readings are a great tool to evaluate
            a situation, it is not an exact science. Therefor use your common
            sense while assessing a situation and taking your next action. Never
            see an I Ching reading as a substitute for professional advice.
          </div>
          <div className="ShortBelowPhera">
            4. We love to bring a great website and app, but this is all just
            work of love, not something that provides any means of income (more
            the opposite actually…: -s). Therefore, we cannot warrant that
            everything will keep working, the service we provide is 'as is'.
          </div>
          <div className="ShortBelowPhera">
            5. Regarding Copywrite. We have created our own 'FreeIChing'
            interpretation of the I Ching, and all information /blogs/ articles,
            etc. are our own. Except for the work of Richard Wilhelm and his I
            Ching translation and commentary (which now seems to be in the
            public domain, if not, please let us know!) and the work of Lise Heyboer (yijing.nl), 
            who gave us kind permission to use her material as well.{" "}
          </div>
          <div className="ShortPhera">
            If you want to see our legal notices. Here they are:
          </div>
          <div className="ShortPhera"><a className="five" href="/terms">Terms of use</a></div>
          {/* <div className="ShortBelowPhera"><a className="five" href="/">Copywrite</a></div> */}
          <div className="ShortBelowPhera"><a className="five" href="/cookie-policy">Cookie Policy</a></div>
          <div className="ShortBelowPhera"><a className="five" href="/privacy-policy">Privacy Policy</a></div>
        </div>
      </section>
      <Content />
    </>
  );
};
