import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useQuery } from "../../hooks";
import { checkActionCode, emailVerify, mail } from "../../utils";

export function VerifyEmail() {
  const [loader, setLoader] = useState(true);
  const [isError, setIsError] = useState(false);
  const [auth] = useSelector((state) => [state.authReducer]);
  const query = useQuery();

  const verifyEmailProcess = () => {
    checkActionCode(query.get("oobCode"))
      .then(async (user) => {
        const obj = {
          to: user.data.email,
          subject: "FreeIching Welcome",
          type: "WELCOME"
        };
        try {
          const data = await Promise.all([
            mail(obj),
            emailVerify(query.get("oobCode"))
          ])
        } catch (err) {
          console.log("Log", err)
        }
        setLoader(false)
        setIsError(false);
        console.log("Verified")
      })
      .catch((err) => {
        setLoader(false)
        setIsError(true)
        console.log("Verify Error:", err)
      });
  }

  useEffect(() => {
    if (auth.user) {
      verifyEmailProcess()
    }
  }, [auth.user])

  return (
    <div className="overlay-popup">
      <div className="overlay-pop-box">
        <Link to="/" className="CloseRight">
          <img src="/images/closeright.png" alt="X" />
        </Link>
        <div className="overlay-pop-left">
          <Link to="/" className="pop-img">
            <img src="/images/pop-logo.png" alt="" />
          </Link>
          {
            <div className="iching-inks">
              <span>
                {`By logging into Freeiching.com`}, you agree to our{" "}
                <Link
                  target="_blank"
                  style={{ color: "inherit" }}
                  className="external-link"
                  to="/terms"
                >
                  {" "}
                  Terms of use
                </Link>{" "}
                and{" "}
                <Link target="_blank" style={{ color: "inherit" }} to="/privacy-policy">
                  Privacy Policy
                </Link>
                .
              </span>
            </div>
          }
        </div>
        <div className="overlay-pop-middle login">
          <h2 style={{ color: "#374c5e" }}>Welcome to Freeiching.com</h2>
          {loader && <div style={{ color: "#374c5e", fontWeight: "normal", textAlign: "center", paddingTop: "100px" }}>Loading..</div>}
          {!loader && !isError && <div className="pop-centre-popup1" style={{ fontWeight: "normal", textAlign: "center", paddingTop: "100px" }}>
            You verified your email. you can now sign in with your account.
          </div>}
          {!loader && isError && <div className={clsx("pop-centre-popup1", { error: isError })} style={{ fontWeight: "normal", textAlign: "center", paddingTop: "100px" }}>
            Email verification link is expired or has already been used.
          </div>}
        </div>
      </div>
    </div>
  )
}