import React from "react";
import Content from "../common/Content";

export default () => {
  return (
    <>
      <section id="banner" className="LegalNoticesSection">
        <div className="OnRichMain">
          <h3 className="HowTotitle">How can the I Ching help?</h3>
        </div>
      </section>
      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortPhera">
          See the I Ching as a tool. 
          A tool that helps you connect to your unconscious, intuition, or higher self. 
          Ultimately to make wiser decisions and understand yourself and the world better. 
          The tool, like any other tool, is something you need to learn to use. But not to worry, 
          we created this site so that it becomes easy to build up your knowledge along the way.
          </div>
        </div>
      </section>
      <Content />
    </>
  );
};
