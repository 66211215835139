import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import clsx from "clsx";
import { getUserAnswers, deleteAnswer } from "../utils";
import { useClassBasedOnRoute } from "../hooks";
import Content from "./common/Content";
import { Link } from "react-router-dom";
import { encode } from "../utils";

export default (props) => {
  const [anwers, setAnwers] = useState([]);
  const auth = useSelector((state) => state.authReducer);
  const [loader, setLoader] = useState(true);

  useClassBasedOnRoute(["myReading"]);

  const getAnwers = async () => {
    setAnwers(await getUserAnswers(auth.user.uid));
    setLoader(false);
  };

  useEffect(() => {
    if (auth && auth.user) {
      getAnwers();
    }
  }, [auth]);

  const handleDeleteAnswer = async (id) => {
    await deleteAnswer(auth.user.uid, id);
    getAnwers();
  };

  const getParams = (data) => {
    return {
      random: {
        sixDigit1: data.ReadingHexagram,
        movingLines: data.MovingLines,
        cards: data.Annotation.split(":"),
      },
      method: data.Method,
    };
  };

  return (
    <>
      <section id="banner">
        <h3 className="bannerTPTitle">
          All your readings on one page. Check them out.
        </h3>
        {anwers.map((answer, index) => (
          <div
            key={`answer-${index}`}
            className={clsx(`BannerReview`, { "ReviewBx-2": index % 2 === 1 })}
          >
            <div className="BannerReviewMain">
              <div className="BannerReviewTop">
                <div className="ReviewDate">
                  {moment.utc(answer.Date.seconds * 1000).format("DD-MM-YYYY")}
                </div>
                <div className="ReviewHex">
                  Hex1 {answer.Annotation.split(":")[0]}
                </div>
                <div className="ReviewStar">
                  <span className="StarBxText">Importance</span>
                  <span className="StarBx">
                    <ReactStars
                      count={5}
                      //size={40}
                      value={answer.importance}
                      edit={false}
                      isHalf={false}
                      emptyIcon={
                        <img src="/images/star-unselect2.png" alt="" />
                      }
                      filledIcon={<img src="/images/star-select2.png" alt="" />}
                    />
                  </span>
                </div>
                <div className="ReviewBtnDelete">
                  <button
                    className="RWDeleteBtn"
                    type="button"
                    onClick={() => handleDeleteAnswer(answer.id)}
                  >
                    Delete
                  </button>
                </div>
                <div className="ReviewDate-bottom">{answer.tag}</div>
                <div className="ReviewHex-bottom">
                  Hex2 {answer.Annotation.split(":")[1]}
                </div>
                <div className="ReviewStar-bottom">
                  <span className="StarBxText-bottom">Clarity</span>
                  <span className="StarBx-bottom">
                    <ReactStars
                      count={5}
                      //size={40}
                      value={answer.clarity}
                      edit={false}
                      isHalf={false}
                      emptyIcon={
                        <img src="/images/star-unselect2.png" alt="" />
                      }
                      filledIcon={<img src="/images/star-select2.png" alt="" />}
                    />
                  </span>
                </div>
                <div className="ReviewBtnDelete-bottom">
                  <div className="RwOpen-bottom">
                    <div className="banner-box4-btn-bottom">
                      <Link
                        to={{
                          pathname: "/full-reading",
                          state: {},
                          search: `?query=${encode(getParams(answer))}`,
                        }}
                        className="RwTxt"
                      >
                        Open <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="BannerReviewBottom"></div>
            </div>
          </div>
        ))}
        {!loader && anwers.length === 0 && <h4 className="bannerTPTitle">No Reading Found.</h4>}
      </section>
      <Content />
    </>
  );
};
