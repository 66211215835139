import React from "react";
import Content from "../common/Content";

export default () => {
  return (
    <>
      <section id="banner" className="LegalNoticesSection">
        <div className="OnRichMain">
          <h3 className="HowTotitle">Privacy policy</h3>
        </div>
      </section>
      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortPhera">
            We respect your privacy and are committed to protecting it through our 
            compliance with this privacy policy (“Policy”). This Policy describes 
            the types of information we may collect from you or that you may provide 
            (“Personal Information”) on the freeiching.com website (“Website” or “Service”) 
            and any of its related products and services (collectively, “Services”), and 
            our practices for collecting, using, maintaining, protecting, and disclosing 
            that Personal Information. It also describes the choices available to you 
            regarding our use of your Personal Information and how you can access and update it.
          </div>
          <div className="ShortPhera">
            This Policy is a legally binding agreement between you (“User”, “you” or “your”) 
            and 137.NL (the owner of freeiching.com) (“137.NL”, “we”, “us” or “our”). 
            If you are entering into this agreement on behalf of a business or other 
            legal entity, you represent that you have the authority to bind such entity 
            to this agreement, in which case the terms “User”, “you” or “your” shall refer 
            to such entity. If you do not have such authority, or if you do not agree with 
            the terms of this agreement, you must not accept this agreement and may not access 
            and use the Website and Services. By accessing and using the Website and Services, 
            you acknowledge that you have read, understood, and agree to be bound by the terms 
            of this Policy. This Policy does not apply to the practices of companies that we do 
            not own or control, or to individuals that we do not employ or manage.
          </div>
          <div className="ShortAboveTitle">
            Automatic collection of information
          </div>
          <div className="ShortPhera">
            Our top priority is customer data security and, as such, we exercise the no logs policy. 
            We may process only minimal user data, only as much as it is absolutely necessary to maintain 
            the Website and Services. Information collected automatically is used only to identify potential 
            cases of abuse and establish statistical information regarding the usage and traffic of the 
            Website and Services. This statistical information is not otherwise aggregated in such a 
            way that would identify any particular user of the system.
          </div>
          <div className="ShortAboveTitle">
            Collection of personal information
          </div>
          <div className="ShortPhera">
            You can access and use the Website and Services without telling us who you are or revealing 
            any information by which someone could identify you as a specific, identifiable individual. 
            If, however, you wish to use some of the features offered on the Website, you may be asked 
            to provide certain Personal Information (for example, your name and e-mail address).
          </div>
          <div className="ShortPhera">
          We receive and store any information you knowingly provide to us when you create an account, 
          publish content, make a purchase,  or fill any online forms on the Website. When required, 
          this information may include the following:
          </div>
          <div className="ShortBelowPhera">
          - Account details (such as user name, unique user ID, password, etc)
          </div>
          <div className="ShortBelowPhera">
          - Contact information (such as email address, phone number, etc)
          </div>
          <div className="ShortBelowPhera">
          - Basic personal information (such as name, country of residence, etc)
          </div>
          <div className="ShortBelowPhera">
          - Payment information (such as credit card details, bank details, etc)
          </div>
          <div className="ShortBelowPhera">
          - Any other materials you willingly submit to us (such as articles, images, feedback, etc)
          </div>
          <div className="ShortPhera">
          Some of the information we collect is directly from you via the Website and Services. 
          However, we may also collect Personal Information about you from other sources such as 
          public databases, social media platforms, third-party data providers, and our joint 
          marketing partners. Personal Information we collect from other sources may include 
          demographic information, such as age and gender, device information, such as IP addresses, 
          location, such as city and state, and online behavioral data, such as information about 
          your use of social media websites, page view information and search results and links.
          </div>
          <div className="ShortPhera">
          You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the features on the Website. Users who are uncertain about what information is mandatory are welcome to contact us.
          </div>
          <div className="ShortAboveTitle">
          Privacy of children
          </div>
          <div className="ShortPhera">
          We do not knowingly collect any Personal Information from children under the age of 13. 
          If you are under the age of 13, please do not submit any Personal Information through the 
          Website and Services. If you have reason to believe that a child under the age of 13 has 
          provided Personal Information to us through the Website and Services, please contact us 
          to request that we delete that child’s Personal Information from our Services.
          </div>
          <div className="ShortPhera">
          We encourage parents and legal guardians to monitor their children’s Internet usage 
          and to help enforce this Policy by instructing their children never to provide Personal 
          Information through the Website and Services without their permission. We also ask that 
          all parents and legal guardians overseeing the care of children take the necessary 
          precautions to ensure that their children are instructed to never give out Personal 
          Information when online without their permission.
          </div>
          <div className="ShortAboveTitle">
          Use and processing of collected information
          </div>
          <div className="ShortPhera">
          We act as a data controller and a data processor in terms of the GDPR when handling 
          Personal Information, unless we have entered into a data processing agreement with 
          you in which case you would be the data controller and we would be the data processor.
          </div>
          <div className="ShortPhera">
          Our role may also differ depending on the specific situation involving Personal Information. 
          We act in the capacity of a data controller when we ask you to submit your Personal Information 
          that is necessary to ensure your access and use of the Website and Services. In such instances, 
          we are a data controller because we determine the purposes and means of the processing of 
          Personal Information and we comply with data controllers’ obligations set forth in the GDPR.
          </div>
          <div className="ShortPhera">
          We act in the capacity of a data processor in situations when you submit Personal Information 
          through the Website and Services. We do not own, control, or make decisions about the submitted 
          Personal Information, and such Personal Information is processed only in accordance with your 
          instructions. In such instances, the User providing Personal Information acts as a data 
          controller in terms of the GDPR.
          </div>
          <div className="ShortPhera">
          In order to make the Website and Services available to you, or to meet a legal obligation, 
          we may need to collect and use certain Personal Information. If you do not provide the 
          information that we request, we may not be able to provide you with the requested products 
          or services. Any of the information we collect from you may be used for the following purposes:
          </div>
          <div className="ShortBelowPhera">
          - Create and manage user accounts
          </div>
          <div className="ShortBelowPhera">
          - Fulfill and manage orders
          </div>
          <div className="ShortBelowPhera">
          - Deliver products or services
          </div>
          <div className="ShortBelowPhera">
          - Improve products and services
          </div>
          <div className="ShortBelowPhera">
          - Send administrative information
          </div>
          <div className="ShortBelowPhera">
          - Send marketing and promotional communications
          </div>
          <div className="ShortBelowPhera">
          - Respond to inquiries and offer support
          </div>
          <div className="ShortBelowPhera">
          - Request user feedback
          </div>
          <div className="ShortBelowPhera">
          - Improve user experience
          </div>
          <div className="ShortBelowPhera">
          - Post customer testimonials
          </div>
          <div className="ShortBelowPhera">
          - Deliver targeted advertising
          </div>
          <div className="ShortBelowPhera">
          - Administer prize draws and competitions
          </div>
          <div className="ShortBelowPhera">
          - Enforce terms and conditions and policies
          </div>
          <div className="ShortBelowPhera">
          - Protect from abuse and malicious users
          </div>
          <div className="ShortBelowPhera">
          - Respond to legal requests and prevent harm
          </div>
          <div className="ShortBelowPhera">
          - Run and operate the Website and Services
          </div>
          <div className="ShortPhera">
          Processing your Personal Information depends on how you interact with the Website 
          and Services, where you are located in the world and if one of the following applies: 
          (i) you have given your consent for one or more specific purposes; this, however, 
          does not apply, whenever the processing of Personal Information is subject to California 
          Consumer Privacy Act or European data protection law; (ii) provision of information is 
          necessary for the performance of an agreement with you and/or for any pre-contractual 
          obligations thereof; (iii) processing is necessary for compliance with a legal obligation 
          to which you are subject; (iv) processing is related to a task that is carried out in the 
          public interest or in the exercise of official authority vested in us; (v) processing is 
          necessary for the purposes of the legitimate interests pursued by us or by a third party.
          </div>
          <div className="ShortPhera">
           Note that under some legislations we may be allowed to process information until you 
           object to such processing by opting out, without having to rely on consent or any other 
           of the legal bases. In any case, we will be happy to clarify the specific legal basis 
           that applies to the processing, and in particular whether the provision of Personal 
           Information is a statutory or contractual requirement, or a requirement necessary to 
           enter into a contract.
          </div>
          <div className="ShortAboveTitle">
          Payment processing
          </div>
          <div className="ShortPhera">
          In case of Services requiring payment, you may need to provide your credit card details 
          or other payment account information, which will be used solely for processing payments. 
          We use third-party payment processors (“Payment Processors”) to assist us in processing 
          your payment information securely.
          </div>
          <div className="ShortPhera">
          Payment Processors adhere to the latest security standards as managed by the PCI Security 
          Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express 
          and Discover.  Sensitive and private data exchange happens over a SSL secured communication 
          channel and is encrypted and protected with digital signatures, and the Website and Services 
          are also in compliance with strict vulnerability standards in order to create as secure of 
          an environment as possible for Users. We will share payment data with the Payment Processors 
          only to the extent necessary for the purposes of processing your payments, refunding such 
          payments, and dealing with complaints and queries related to such payments and refunds.
          </div>
          <div className="ShortPhera">
          Please note that the Payment Processors may collect some Personal Information from you, 
          which allows them to process your payments (e.g., your email address, address, credit 
          card details, and bank account number) and handle all the steps in the payment process 
          through their systems, including data collection and data processing. The Payment 
          Processors’ use of your Personal Information is governed by their respective privacy 
          policies which may or may not contain privacy protections as protective as this Policy. 
          We suggest that you review their respective privacy policies.
          </div>
          <div className="ShortAboveTitle">
          Managing information
          </div>
          <div className="ShortPhera">
          You are able to delete certain Personal Information we have about you. 
          The Personal Information you can delete may change as the Website and 
          Services change. When you delete Personal Information, however, we may 
          maintain a copy of the unrevised Personal Information in our records 
          for the duration necessary to comply with our obligations to our affiliates 
          and partners, and for the purposes described below. If you would like to delete 
          your Personal Information or permanently delete your account, you can do so on 
          the settings page of your account on the Website.
          </div>
          <div className="ShortAboveTitle">
          Disclosure of information
          </div>
          <div className="ShortPhera">
          Depending on the requested Services or as necessary to complete any transaction 
          or provide any Service you have requested, we may share your information with 
          our affiliates, contracted companies, and service providers (collectively, “Service Providers”) 
          we rely upon to assist in the operation of the Website and Services available to you and whose 
          privacy policies are consistent with ours or who agree to abide by our policies with 
          respect to Personal Information. We will not share any personally identifiable information 
          with third parties and will not share any information with unaffiliated third parties.
          </div>
          <div className="ShortPhera">
          Service Providers are not authorized to use or disclose your information except as 
          necessary to perform services on our behalf or comply with legal requirements. 
          Service Providers are given the information they need only in order to perform 
          their designated functions, and we do not authorize them to use or disclose any 
          of the provided information for their own marketing or other purposes.
          </div>
          <div className="ShortPhera">
          We may also disclose any Personal Information we collect, use or receive if 
          required or permitted by law, such as to comply with a subpoena or similar 
          legal process, and when we believe in good faith that disclosure is necessary 
          to protect our rights, protect your safety or the safety of others, investigate 
          fraud, or respond to a government request.
          </div>
          <div className="ShortAboveTitle">
          Retention of information
          </div>
          <div className="ShortPhera">
          We will retain and use your Personal Information for the period necessary to 
          enforce our agreements, resolve disputes, and unless a longer retention period 
          is required or permitted by law up to a maximum of 60 months.
          </div>
          <div className="ShortPhera">
          We may use any aggregated data derived from or incorporating your Personal 
          Information after you update or delete it, but not in a manner that would identify 
          you personally. Once the retention period expires, Personal Information shall be deleted. 
          Therefore, the right to access, the right to erasure, the right to rectification, and 
          the right to data portability cannot be enforced after the expiration of the retention period.
          </div>
          <div className="ShortAboveTitle">
          Transfer of information
          </div>
          <div className="ShortPhera">
          Depending on your location, data transfers may involve transferring and storing your 
          information in a country other than your own. However, this will not include countries 
          outside the European Union and European Economic Area. If any such transfer takes place, 
          you can find out more by checking the relevant sections of this Policy or inquire with us 
          using the information provided in the contact section.
          </div>
          <div className="ShortAboveTitle">
          Data protection rights under the GDPR
          </div>
          <div className="ShortPhera">
          If you are a resident of the European Economic Area (“EEA”), you have certain data 
          protection rights and we aim to take reasonable steps to allow you to correct, amend, 
          delete, or limit the use of your Personal Information. If you wish to be informed what 
          Personal Information we hold about you and if you want it to be removed from our systems, 
          please contact us. In certain circumstances, you have the following data protection rights:
          </div>
          <div className="ShortPhera">
          (i) You have the right to withdraw consent where you have previously given your consent 
          to the processing of your Personal Information. To the extent that the legal basis for 
          our processing of your Personal Information is consent, you have the right to withdraw 
          that consent at any time. Withdrawal will not affect the lawfulness of processing before 
          the withdrawal.
          </div>
          <div className="ShortPhera">
          (ii) You have the right to learn if your Personal Information is being processed by us, 
          obtain disclosure regarding certain aspects of the processing, and obtain a copy of your 
          Personal Information undergoing processing.
          </div>
          <div className="ShortPhera">
          (iii) You have the right to verify the accuracy of your information and ask for it to be
          updated or corrected. You also have the right to request us to complete the Personal 
          Information you believe is incomplete.
          </div>
          <div className="ShortPhera">
          (iv) You have the right to object to the processing of your information if the processing 
          is carried out on a legal basis other than consent. Where Personal Information is processed 
          for the public interest, in the exercise of an official authority vested in us, or for the 
          purposes of the legitimate interests pursued by us, you may object to such processing by 
          providing a ground related to your particular situation to justify the objection. 
          You must know that, however, should your Personal Information be processed for direct 
          marketing purposes, you can object to that processing at any time without providing 
          any justification. To learn whether we are processing Personal Information for direct 
          marketing purposes, you may refer to the relevant sections of this Policy.
          </div>
          <div className="ShortPhera">
          (v) You have the right, under certain circumstances, to restrict the processing of 
          your Personal Information. These circumstances include: the accuracy of your Personal 
          Information is contested by you and we must verify its accuracy; the processing is 
          unlawful, but you oppose the erasure of your Personal Information and request the 
          restriction of its use instead; we no longer need your Personal Information for the 
          purposes of processing, but you require it to establish, exercise or defend your legal 
          claims; you have objected to processing pending the verification of whether our legitimate 
          grounds override your legitimate grounds. Where processing has been restricted, such Personal 
          Information will be marked accordingly and, with the exception of storage, will be processed 
          only with your consent or for the establishment, to exercise or defense of legal claims, for 
          the protection of the rights of another natural, or legal person or for reasons of important 
          public interest.
          </div>
          <div className="ShortPhera">
          (vi) You have the right, under certain circumstances, to obtain the erasure of your Personal 
          Information from us. These circumstances include: the Personal Information is no longer 
          necessary in relation to the purposes for which it was collected or otherwise processed; 
          you withdraw consent to consent-based processing; you object to the processing under certain 
          rules of applicable data protection law; the processing is for direct marketing purposes; 
          and the personal data have been unlawfully processed. However, there are exclusions of the 
          right to erasure such as where processing is necessary: for exercising the right of freedom 
          of expression and information; for compliance with a legal obligation; or for the establishment, 
          to exercise or defense of legal claims.
          </div>
          <div className="ShortPhera">
          (vii) You have the right to receive your Personal Information that you have provided to 
          us in a structured, commonly used, and machine-readable format and, if technically feasible, 
          to have it transmitted to another controller without any hindrance from us, provided that such 
          transmission does not adversely affect the rights and freedoms of others.
          </div>
          <div className="ShortPhera">
          (viii) You have the right to complain to a data protection authority about our collection 
          and use of your Personal Information. If you are not satisfied with the outcome of your 
          complaint directly with us, you have the right to lodge a complaint with your local data 
          protection authority. For more information, please contact your local data protection 
          authority in the EEA. This provision is applicable provided that your Personal Information 
          is processed by automated means and that the processing is based on your consent, on a 
          contract which you are part of, or on pre-contractual obligations thereof.
          </div>
          <div className="ShortAboveTitle">
          California privacy rights
          </div>
          <div className="ShortPhera">
          Consumers residing in California are afforded certain additional rights with respect to 
          their Personal Information under the California Consumer Privacy Act (“CCPA”). If you are a 
          California resident, this section applies to you.
          </div>
          <div className="ShortPhera">
          In addition to the rights as explained in this Policy, California residents who provide 
          Personal Information as defined in the statute to obtain Services for personal, family, 
          or household use are entitled to request and obtain from us, once a calendar year, 
          information about the categories and specific pieces of Personal Information we have 
          collected and disclosed.
          </div>
          <div className="ShortPhera">
          Furthermore, California residents have the right to request deletion of their Personal 
          Information or opt-out of the sale of their Personal Information which may include selling, 
          disclosing, or transferring Personal Information to another business or a third party for 
          monetary or other valuable consideration. To do so, simply contact us. We will not discriminate 
          against you if you exercise your rights under the CCPA.
          </div>
          <div className="ShortAboveTitle">
          How to exercise your rights
          </div>
          <div className="ShortPhera">
          Any requests to exercise your rights can be directed to us through the contact details provided in this document. Please note that we may ask you to verify your identity before responding to such requests. Your request must provide sufficient information that allows us to verify that you are the person you are claiming to be or that you are the authorized representative of such person. If we receive your request from an authorized representative, we may request evidence that you have provided such an authorized representative with power of attorney or that the authorized representative otherwise has valid written authority to submit requests on your behalf.
          </div>
          <div className="ShortPhera">
          You must include sufficient details to allow us to properly understand the request and respond to it. We cannot respond to your request or provide you with Personal Information unless we first verify your identity or authority to make such a request and confirm that the Personal Information relates to you.
          </div>
          <div className="ShortAboveTitle">
          Cookies
          </div>
          <div className="ShortPhera">
          Our Website and Services use “cookies” to help personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. If you choose to decline cookies, you may not be able to fully experience the features of the Website and Services. You may learn more about cookies and how they work in this guide.
          </div>
          <div className="ShortPhera">
          We may use cookies to collect, store, and track information for security and personalization, to operate the Website and Services, and for statistical purposes. Please note that you have the ability to accept or decline cookies. Most web browsers automatically accept cookies by default, but you can modify your browser settings to decline cookies if you prefer.
          </div>
          <div className="ShortAboveTitle">
          Do Not Track signals
          </div>
          <div className="ShortPhera">
          Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time. How browsers communicate the Do Not Track signal is not yet uniform. As a result, the Website and Services are not yet set up to interpret or respond to Do Not Track signals communicated by your browser. Even so, as described in more detail throughout this Policy, we limit our use and collection of your Personal Information.
          </div>
          <div className="ShortAboveTitle">
          Advertisements
          </div>
          <div className="ShortPhera">
          We may display online advertisements and we may share aggregated and non-identifying information about our customers that we or our advertisers collect through your use of the Website and Services. We do not share personally identifiable information about individual customers with advertisers. In some instances, we may use this aggregated and non-identifying information to deliver tailored advertisements to the intended audience.
          </div>
          <div className="ShortAboveTitle">
          Social media features
          </div>
          <div className="ShortPhera">
          Our Website and Services may include social media features, such as the Facebook and Twitter buttons, Share This buttons, etc (collectively, “Social Media Features”). These Social Media Features may collect your IP address, what page you are visiting on our Website and Services, and may set a cookie to enable Social Media Features to function properly. Social Media Features are hosted either by their respective providers or directly on our Website and Services. Your interactions with these Social Media Features are governed by the privacy policy of their respective providers.
          </div>
          <div className="ShortAboveTitle">
          Email marketing
          </div>
          <div className="ShortPhera">
          We offer electronic newsletters to which you may voluntarily subscribe at any time. We are committed to keeping your e-mail address confidential and will not disclose your email address to any third parties except as allowed in the information use and processing section or for the purposes of utilizing a third-party provider to send such emails. We will maintain the information sent via e-mail in accordance with applicable laws and regulations.
          </div>
          <div className="ShortPhera">
          In compliance with the CAN-SPAM Act, all e-mails sent from us will clearly state who the e-mail is from and provide clear information on how to contact the sender. You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails or by contacting us. However, you will continue to receive essential transactional emails.
          </div>
          <div className="ShortAboveTitle">
          Links to other resources
          </div>
          <div className="ShortPhera">
          The Website and Services contain links to other resources that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Website and Services and to read the privacy statements of each and every resource that may collect Personal Information.         
          </div>
          <div className="ShortAboveTitle">
          Information security
          </div>
          <div className="ShortPhera">
          We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in our control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.
          </div>
          <div className="ShortPhera">
          Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and the Website and Services cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.
          </div>
          <div className="ShortPhera">
          As the security of Personal Information depends in part on the security of the device you use to communicate with us and the security you use to protect your credentials, please take appropriate measures to protect this information.
          </div>
          <div className="ShortAboveTitle">
          Data breach
          </div>
          <div className="ShortPhera">
          In the event we become aware that the security of the Website and Services has been compromised or Users’ Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the User as a result of the breach or if notice is otherwise required by law. When we do, we will post a notice on the Website.
          </div>
          <div className="ShortAboveTitle">
          Changes and amendments
          </div>
          <div className="ShortPhera">
          We reserve the right to modify this Policy or its terms related to the Website and Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.
          </div>
          <div className="ShortPhera">
          An updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Information in a manner materially different than what was stated at the time your Personal Information was collected.
          </div>
          <div className="ShortAboveTitle">
          Acceptance of this policy
          </div>
          <div className="ShortPhera">
          You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Website and Services and submitting your information you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Website and Services.
          </div>
          <div className="ShortAboveTitle">
          Contacting us
          </div>
          <div className="ShortPhera">
          If you have any questions, concerns, or complaints regarding this Policy, the information we hold about you, or if you wish to exercise your rights, we encourage you to contact us using the details below:
          </div>
          <div className="ShortPhera">
          freeiching.com@gmail.com
          </div>
          <div className="ShortPhera">
          We will attempt to resolve complaints and disputes and make every reasonable effort to honor your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by applicable data protection laws.
          </div>
          <div className="ShortPhera">
          This document was last updated on December 16, 2020
          </div>
        </div>
      </section>
      <Content />
    </>
  );
};
