import React from "react";
import { Link } from "react-router-dom";
import Content from "./common/Content";

export default () => {
  return (
    <>
      <section id="banner" className="MoreOnSection">
        <div className="MoreOnMain">
          <h3 className="moreontitle">More on I Ching</h3>
        </div>
      </section>
      <section id="" className="MoreOnBottom">
        <div className="MoreOnInner">
          <div className="MoreOnLinkBg">
            <h3 className="moreonlinkTitle">
              How to consult and learning how to use the I Ching.
            </h3>
            <ul className="moreonLinks">
              <li>
                <Link to="/moreoniching/howtoconsult">
                  How to consult the I Ching
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/moreoniching/realexamplereading">
                  Real example readings
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/moreoniching/methodtouse">
                  What method to use? Coins or Oneline?
                </Link>
              </li>
            </ul>
          </div>
          <div className="MoreOnLinkSecondBg">
            <h3 className="moreonlinkTitle">I Ching background</h3>
            <ul className="moreonLinks">
              <li>
                <Link to="/moreoniching/shortanatomy">
                  The anatomy of a Hexagram
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/moreoniching/onrichardwilhelm">
                  On Richard Wilhelm and Lao Naixuan
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/moreoniching/otherichingresouces">
                  Other I Ching resources
                </Link>
              </li>
            </ul>
          </div>
          <div className="MoreOnLinkThirdBg">
            <h3 className="moreonlinkTitle">
              I Ching and influence across science and (western) culture
            </h3>
            <ul className="moreonLinks">
              <li>
                <Link to="/moreoniching/scienceontheiching">
                  Science on the I Ching, does it work?
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/moreoniching/ichingbinarycode">
                  I Ching and Binary code
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/moreoniching/ichingquantumphysics">
                  I Ching and pioneers of quantum physics
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/moreoniching/ichingcuriouslinks">
                  I Ching & Kabbalah, a curious link
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Content />
    </>
  );
};
