const initialState = {
  question: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case "QUESTION_SET_DATA":
      return { ...state, question: { ...(state.question || {}), ...payload } };
    case "QUESTION_CLEAR":
      return { ...state, question: null };
    default:
      return state;
  }
}
