import React from "react";
import Content from "../common/Content";

export default () => {
  return (
    <>
      <section id="banner" className="LegalNoticesSection">
        <div className="OnRichMain">
          <h3 className="HowTotitle">Cookie Policy</h3>
        </div>
      </section>
      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortPhera">
            This cookie policy (“Policy”) describes what cookies are 
            and how and they're being used by the freeiching.com website
            (“Website” or “Service”) and any of its related products and 
            services (collectively, “Services”). This Policy is a legally 
            binding agreement between you (“User”, “you” or “your”) and 
            137.NL (the owner of freeiching.com) (“137.NL”, “we”, “us” 
            or “our”). If you are entering into this agreement on behalf 
            of a business or other legal entity, you represent that you 
            have the authority to bind such entity to this agreement, 
            in which case the terms “User”, “you” or “your” shall refer
            to such entity. If you do not have such authority, or if 
            you do not agree with the terms of this agreement, you 
            must not accept this agreement and may not access and use 
            the Website and Services. You should read this Policy so 
            you can understand the types of cookies we use, the information
            we collect using cookies and how that information is used. It also
            describes the choices available to you regarding accepting or 
            declining the use of cookies. For further information on how we 
            use, store and keep your personal data secure, see our privacy policy.
          </div>
          <div className="ShortAboveTitle">
            What are cookies?
          </div>
          <div className="ShortPhera">
            Cookies are small pieces of data stored in text files that are saved on 
            your computer or other devices when websites are loaded in a browser. 
            They are widely used to remember you and your preferences, either for 
            a single visit (through a "session cookie") or for multiple repeat visits 
            (using a "persistent cookie").
          </div>
          <div className="ShortPhera">
            Session cookies are temporary cookies that are used during the course of 
            your visit to the Website, and they expire when you close the web browser.
          </div>
          <div className="ShortPhera">
            Persistent cookies are used to remember your preferences within our Website 
            and remain on your desktop or mobile device even after you close your browser 
            or restart your computer. They ensure a consistent and efficient experience
            for you while visiting the Website and Services.
          </div>
          <div className="ShortPhera">
            Cookies may be set by the Website ("first-party cookies"), or by third parties, 
            such as those who serve content or provide advertising or analytics services on 
            the Website ("third party cookies"). These third parties can recognize you when
            you visit our website and also when you visit certain other websites. You may 
            learn more about cookies and how they work in this guide.
          </div>
          <div className="ShortAboveTitle">
            What type of cookies do we use?
          </div>
          <div className="ShortPhera">
            - Necessary cookies
          </div>
          <div className="ShortPhera">
            Necessary cookies allow us to offer you the best possible experience when accessing 
            and navigating through our Website and using its features. For example, these cookies
            let us recognize that you have created an account and have logged into that account
            to access the content.
          </div>
          <div className="ShortPhera">
            - Functionality cookies
          </div>
          <div className="ShortPhera">
            Functionality cookies let us operate the Website and Services in accordance with the
            choices you make. For example, we will recognize your username and remember how you 
            customized the Website and Services during future visits.
          </div>
          <div className="ShortPhera">
            - Analytical cookies
          </div>
          <div className="ShortPhera">
            These cookies enable us and third party services to collect aggregated data for statistical
            purposes on how our visitors use the Website. These cookies do not contain personal
            information such as names and email addresses and are used to help us improve your
            user experience of the Website.
          </div>
          <div className="ShortPhera">
            - Advertising cookies
          </div>
          <div className="ShortPhera">
            Advertising cookies allow us and third parties serve relevant ads to you more 
            effectively and help us collect aggregated audit data, research, and performance 
            reporting for advertisers. They also enable us to understand and improve the
            delivery of ads to you and know when certain ads have been shown to you.
          </div>
          <div className="ShortPhera">
            Your web browser may request advertisements directly from ad network servers,
            these networks can view, edit, or set their own cookies, just as if you had 
            requested a web page from their website.
          </div>
          <div className="ShortPhera">
            Although we do not use cookies to create a profile of your browsing behavior
            on third party websites, we do use aggregate data from third parties to show 
            you relevant, interest-based advertising. We do not provide any personal 
            information that we collect to advertisers.
          </div>
          <div className="ShortPhera">
            - Social media cookies
          </div>
          <div className="ShortPhera">
            Third party cookies from social media sites (such as Facebook, Twitter, etc) 
            let us track social network users when they visit or use the Website and Services,
            or share content, by using a tagging mechanism provided by those social networks.
          </div>
          <div className="ShortPhera">
            These cookies are also used for event tracking and remarketing purposes. Any data 
            collected with these tags will be used in accordance with our and social networks’
            privacy policies. We will not collect or share any personally identifiable information 
            from the user.
          </div>
          <div className="ShortAboveTitle">
            Do we use web beacons or tracking pixels?
          </div>
          <div className="ShortPhera">
            Our emails may contain a "web beacon" (or "tracking pixel") to tell us whether
            our emails are opened and verify any clicks through to links or advertisements 
            within the email.
          </div>
          <div className="ShortPhera">
            We may use this information for purposes including determining which of our emails 
            are more interesting to users and to query whether users who do not open our emails 
            wish to continue receiving them.
          </div>
          <div className="ShortPhera">
            The pixel will be deleted when you delete the email. If you do not wish the 
            pixel to be downloaded to your device, you should read the email in plain text
            view or with images disabled.
          </div>
          <div className="ShortAboveTitle">
            What are your cookie options?
          </div>
          <div className="ShortPhera">
            If you don’t like the idea of cookies or certain types of cookies, you can 
            change your browser’s settings to delete cookies that have already been set 
            and to not accept new cookies. To learn more about how to do this, visit internetcookies.com
          </div>
          <div className="ShortPhera">
            Please note, however, that if you delete cookies or do not accept them, you 
            might not be able to use all of the features the Website and Services offer.
          </div>
          <div className="ShortAboveTitle">
            Changes and amendments
          </div>
          <div className="ShortPhera">
            We reserve the right to modify this Policy or its terms related to the
            Website and Services at any time at our discretion. When we do, we will
            revise the updated date at the bottom of this page. We may also provide 
            notice to you in other ways at our discretion, such as through the contact
            information you have provided.
          </div>
          <div className="ShortPhera">
            An updated version of this Policy will be effective immediately upon the posting
            of the revised Policy unless otherwise specified. Your continued use of the Website
            and Services after the effective date of the revised Policy (or such other act
            specified at that time) will constitute your consent to those changes.
          </div>
          <div className="ShortAboveTitle">
            Acceptance of this policy
          </div>
          <div className="ShortPhera">
            You acknowledge that you have read this Policy and agree to all its terms 
            and conditions. By accessing and using the Website and Services you agree
            to be bound by this Policy. If you do not agree to abide by the terms of 
            this Policy, you are not authorized to access or use the Website and Services.
          </div>
          <div className="ShortAboveTitle">
            Contacting us
          </div>
          <div className="ShortPhera">
            If you have any questions, concerns, or complaints regarding this Policy
            or the use of cookies, we encourage you to contact us using the details below:
          </div>
          <div className="ShortPhera">
            freeiching.com@gmail.com
          </div>
          <div className="ShortPhera">
            This document was last updated on December 16, 2020
          </div>
        </div>
      </section>
      <Content />
    </>
  );
};
