import React from "react";
import Content from "../common/Content";

export default () => {
  return (
    <>
      <section id="banner" className="LegalNoticesSection">
        <div className="OnRichMain">
          <h3 className="HowTotitle">Why become a member?</h3>
        </div>
      </section>
      <section id="" className="OnRichBottom">
        <div className="HowToInner">
          <div className="ShortPhera">
          {/* Why become a member? <br></br> */}
          At Freeiching.com, we try to keep it practical. 
          Make sure that you can apply ancient wisdom directly in your life. 
          You can keep track of your readings in a journal or share your readings with friends. 
          Last but not least, we care about your privacy and don't spam or bother you with advertisements. 
          Join us, it’s 100% free.
          </div>
        </div>
      </section>
      <Content />
    </>
  );
};
