import { get, has, isEmpty } from "lodash";
import moment from "moment";
export * from "./method";
export * from "./firebase";
export * from "./hexaReference";
export * from "./methodUtils";

//Dashboard - Greeting Logic
export const greetings = () => {
  let date = new Date();
  let hour = date.getHours();
  if (hour < 12) {
    return "Good morning";
  } else if (hour < 17) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
};

export const goToElement = (e, name) => {
  e.preventDefault();
  let element = document.querySelector(name);
  if (element) {
    element.scrollIntoView({ block: "start", behavior: "smooth" });
  } else {
    console.log("Not found");
  }
};

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

// export const encode = (data) => encodeURIComponent(JSON.stringify(data));

// export const decode = (data) => JSON.parse(data);

export const querParse = (data, name) => new URLSearchParams(data).get(name);

export const isChingText = (data, name) =>
  !isEmpty(data.settings) && data.settings[0].PreferredText === name;
export const isChingMethod = (data, name) =>
  !isEmpty(data.settings) && data.settings[0].PreferredMethod === name;

//Check if Primary Deck is ----
export const isChingPrimaryDeck = (data, name) =>
  !isEmpty(data.settings) && data.settings[0].PrimaryDeck === name;

//Check if Secondary Deck is ----
export const isChingSecondaryDeck = (data, name) =>
  !isEmpty(data.settings) && data.settings[0].SecondaryDeck === name;

export const contentLimit = (content, length) => {
  return typeof content === "string" && content.length > length
    ? content.slice(0, length).concat("...")
    : content;
};

export const getDate = (data, key) => {
  return has(data, key) ? moment(get(data, key)).format("DD-MM-YYYY") : "";
};

export function isCookieConsent() {
  return window.localStorage.getItem("CookieConsentWrapper") === "true"
}

export function setCookieConsent() {
  return window.localStorage.setItem("CookieConsentWrapper", "true")
}

export function getLocaleData(key, parse) {
  const data = sessionStorage.getItem(key);
  if (parse) {
    return JSON.parse(data)
  }
  return data;
}

export function removeLocalData(key) {
  sessionStorage.removeItem(key);
}

export function setLocalData(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

const data = {
  "method": "CoinStack",
  "random": {
    "cards": ['64'],
    "movingLines": [4, 6, 7],
    "sixDigit1": "896888"
  }
}
const decodeShortItem = {
  "1": "a",
  "2": "b",
  "3": "c",
  "4": "d",
  "5": "e",
  "6": "f",
  "7": "g",
  "8": "h",
  "9": "i",
  "Y": "YarrowStack",
  "T": "Traditional",
  "C": "CoinStack"
}

export function encode(data) {
  const encodeMethod = data.method[0];
  const encodeCard = data.random.cards.join(":");

  const encodeMovingLines = data.random.movingLines.map(line => decodeShortItem[line]).join("");
  const encodeSixDigit = (data.random.sixDigit1 || "").split("").map(line => decodeShortItem[line]).join("")

  let msg = `${encodeMethod}-${encodeCard}-${encodeMovingLines}-${encodeSixDigit}`;

  return window.btoa(msg);
}

export function decode(data) {
  const decodeData = window.atob(data).split("-");
  const method = decodeShortItem[decodeData[0]];
  const cards = decodeData[1].split(":")

  const movingLines = decodeData[2].split("").map(line => Number(getKeyByValue(decodeShortItem, line)));
  const sixDigit1 = (decodeData[3] || "").split("").map(line => getKeyByValue(decodeShortItem, line)).join("");

  return {
    method,
    random: {
      cards,
      movingLines,
      sixDigit1,
    }
  }
}