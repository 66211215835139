import React from "react";
import { Link } from "react-router-dom";

export default (props) => (
  <section id="info" className="wrapper alt section-8 style2">
    <div className="row m-0 p-l-r-50">
      <div className="BoxBg2 m-l-0 box-1">
        <div className="box-content">
          <h4>How to consult the I Ching</h4>
          <p>
            To use the I Ching as a divination tool, the most important thing
            you need is your pure intention, concentration, and attention to
            your question when you consult the I Ching.{" "}
          </p>
          <p>
            You must be in some way personally connected to the question. The
            more you are emotionally involved with the problem, the more precise
            the I Ching answer will be.{" "}
          </p>
        </div>
        <div className="box4-bottom">
          <div className="box4-btn">
            <Link to="/moreoniching/howtoconsult" className="theme-btn">
              Read more
            </Link>
            <i className="fa fa-angle-right"></i>
          </div>
        </div>
      </div>
      <div className="BoxBg2 box-6">
        <div className="box-content">
          <h4>What method to use? Coins or Oneline?</h4>
          <p>
            We use two different methodologies to randomly create the reading
            answer (the Hexagrams and moving lines). It is up to you as a member
            to select the method of your liking. Some options might only be
            available after creating your own account.{" "}
          </p>
        </div>
        <div className="box4-bottom">
          <div className="box4-btn">
            <Link to="/moreoniching/methodtouse" className="theme-btn">
              Read more
            </Link>
            <i className="fa fa-angle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </section>
);
