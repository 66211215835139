import {
  AUTH_SET_USER,
  AUTH_CLEAR_USER,
  AUTH_SERVER_ERROR,
  AUTH_SERVER_SUCCESS,
} from "../actions/actionTypes";

// let user = window.sessionStorage.getItem(`firebase:authUser:${firebaseConfig.apiKey}:[DEFAULT]`);

const INITIAL_STATE = {
  message: "",
  isLogin: false,
  user: null,
};

export default function (state = INITIAL_STATE, action) {
  if (action.type === AUTH_SET_USER) {
    let user = action.payload;
    console.log("****", user)
    let isLogin = !!user && !user.isAnonymous;
    return { ...state, message: "", isLogin, user };
  } else if (action.type === AUTH_CLEAR_USER) {
    return { ...state, message: "", isLogin: false, user: undefined };
  } else if (action.type === AUTH_SERVER_ERROR) {
    return { ...state, message: action.payload };
  } else if (action.type === AUTH_SERVER_SUCCESS) {
    return { ...state, message: action.payload };
  } else {
    return state;
  }
}
