// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
// We only want to use Firebase Auth here
import "firebase/auth";
// import 'firebase/database';
import "firebase/firestore";
import "firebase/analytics";

// Your app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyB41mkVIC192CQwLcaDjmGQnSBzojLcHVk",
  authDomain: "tarotpro-cffe4.firebaseapp.com",
  databaseURL: "https://tarotpro-cffe4.firebaseio.com",
  projectId: "tarotpro-cffe4",
  storageBucket: "tarotpro-cffe4.appspot.com",
  messagingSenderId: "339415801887",
  appId: "1:339415801887:web:b62f2c6ec4745b38b4f52a",
  measurementId: "G-1GBX3JSMML",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();
firebase.firestore().settings({});
// Finally, export it to use it throughout your app
export default firebase;
