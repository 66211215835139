export function defineRequestType(base) {
  return ["OPEN", "CLOSE"].reduce((acc, type) => {
    acc[type] = `${base}_MODAL_${type}`;
    return acc;
  }, {});
}

// API CALLS
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const API_CALL_SUCCESS = "API_CALL_SUCCESS";

export const AUTH_SERVER_SUCCESS = "AUTH_SERVER_MESSAGE";
export const AUTH_SERVER_ERROR = "AUTH_SERVER_ERROR";

export const AUTH_SET_USER = "AUTH_SET_USER";
export const AUTH_CLEAR_USER = "AUTH_CLEAR_USER";

// modal
export const LOGIN_MODAL = defineRequestType("LOGIN");
export const SHARE_MODAL = defineRequestType("SHARE");
export const MAIL_MODAL = defineRequestType("MAIL");

// login
// export const LOGIN_OPEN = "LOGIN_OPEN";
// export const LOGIN_CLOSE = "LOGIN_CLOSE";

// //Share
// export const OPEN_SHARE_MODAL = "OPEN_SHARE_MODAL";
// export const CLOSE_SHARE_MODAL = "CLOSE_SHARE_MODAL";

// //Mail
// export const OPEN_MAIL_MODAL = "OPEN_MAIL_MODAL";
// export const CLOSE_MAIL_MODAL = "CLOSE_MAIL_MODAL";

// Settings
export const APP_SETTING_SET = "APP_SETTING_SET";
export const APP_SETTING_CLEAR = "APP_SETTING_CLEAR";

// Save From
export const QUESTION_OPEN = "QUESTION_OPEN";
export const QUESTION_CLOSE = "QUESTION_CLOSE";

//My Reading
export const MY_READING_OPEN = "MY_READING_OPEN";
export const MY_READING_CLOSE = "MY_READING_CLOSE";

//Hexagram
export const HEXAGRAM_OPEN = "HEXAGRAM_OPEN";
export const HEXAGRAM_CLOSE = "HEXAGRAM_CLOSE";

export const RESET_PASSWORD_TOGGLE = "RESET_PASSWORD_TOGGLE";
export const LOADER_TOGGLE = "LOADER_TOGGLE";
export const COOKIE_CONSENT = "COOKIE_CONSENT";
