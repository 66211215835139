import React, { useEffect } from "react";
import { useLocation } from "react-router";

export function useResponsiveClasses() {
  useEffect(() => {
    const handleResize = () => {
      let bodyClass = document.body.classList;
      if (window.innerWidth <= 980 && !bodyClass.contains("phone")) {
        bodyClass.add("phone");
      } else if (window.innerWidth > 980 && bodyClass.contains("phone")) {
        bodyClass.remove("phone");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
}

export function useClassBasedOnRoute(classNames) {
  useEffect(() => {
    let bodyClass = document.body.classList;
    for (const className of classNames) {
      bodyClass.add(className);
    }
    return () => {
      for (const className of classNames) {
        bodyClass.remove(className);
      }
    };
  }, []);
}

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
