import React, { useEffect } from "react";
import { useSelector } from 'react-redux'
import Header from "../common/Header";
import Footer from "../common/Footer";
import Login from "../common/Login"
import Share from "../common/share"
import Mail from "../common/mail"
import Modal from "../common/Modal";
import clsx from "clsx";
import { useQuery } from "../../hooks";
import { ResetPassword } from "../common/ResetPassword";
import { VerifyEmail } from "../common/VerifyEmail";

export default ({ children }) => {
  const app = useSelector((state) => state.appReducer);
  const query = useQuery();

  useEffect(() => {
    if (app.modal.isLoginModalOpen || app.modal.isShareModalOpen || app.modal.isMailModalOpen || query.get("mode")) {
      document.querySelector(".app-container").classList.add("hidden");
    } else {
      document.querySelector(".app-container").classList.remove("hidden");
    }
  }, [app.modal.isLoginModalOpen, app.modal.isShareModalOpen, app.modal.isMailModalOpen, query.get("mode")])

  return (<>
    <Modal show={app.modal.isLoginModalOpen}> <Login /> </Modal>
    <Modal show={app.modal.isShareModalOpen}> <Share /> </Modal>
    <Modal show={app.modal.isMailModalOpen}> <Mail /> </Modal>
    <Modal show={query.get("mode") === "resetPassword"}> <ResetPassword /> </Modal>
    <Modal show={query.get("mode") === "verifyEmail"}> <VerifyEmail /> </Modal>
    <div className={clsx("app-container", { "cookie-consent-wrapper": !app.cookieConsent })} >
      <Header />
      {children}
      <Footer />
    </div>
  </>);
}

